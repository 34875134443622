import React from 'react';
import { toast } from 'react-toastify';
import { useUpdateCheck } from 'react-update-notification';

const Notification = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 300000,
  });

  if (status === 'checking' || status === 'current') {
    return null;
  }

  return toast(
    <div>
      <button type="button" onClick={reloadPage} className="btn  btn-link">
        New update is available. Click here to update
      </button>
    </div>,
    {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
  );
};

export default Notification;
