import { makeStyles } from '@material-ui/core';
import React from 'react';
import Utils from '../../utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const UserNavbar = ({ User, role, isUserNavbar }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light py-0 border-bottom ${
        isUserNavbar !== true ? 'd-none' : 'd-block'
      }`}
      id="user-navbar"
    >
      <div>
        <div className="navbar-nav position-relative">
         {sessionStorage.getItem('me') != null && sessionStorage.getItem('me').indexOf("@") === -1? (
         <button
            className={`btn btn-link nav-link ${classes.pointer} ${
              window.location.pathname === '/sub' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/sub')}
          >
            Sub
          </button>
          ) : null}
          {role === 'COMPANY' || (User.agentType != null && User.agentType === 'COMPANY') ? (
            <button
              className={`btn btn-link nav-link ${classes.pointer} ${
                window.location.pathname === '/vendor' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/vendor')}
            >
              Vendor
            </button>
          ) : null}
          {role === 'COMPANY' || role === 'VENDOR' || (User.agentType != null && (User.agentType === 'COMPANY' || User.agentType === 'VENDOR'))? (
            <button
              className={`btn btn-link nav-link ${classes.pointer} ${
                window.location.pathname === '/senior' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/senior')}
            >
              Senior
            </button>
          ) : null}
          {role === 'COMPANY' || role === 'VENDOR' || role === 'SENIOR' || (User.agentType != null && (User.agentType === 'COMPANY' || User.agentType === 'VENDOR' || User.agentType === 'SENIOR')) ? (
            <button
              className={`btn btn-link nav-link ${classes.pointer} ${
                window.location.pathname === '/master' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/master')}
            >
              Master
            </button>
          ) : null}
          {role === 'COMPANY' || role === 'VENDOR' || role === 'SENIOR' ||role === 'MASTER' || 
          (User.agentType != null && (User.agentType === 'COMPANY' || User.agentType === 'VENDOR' || User.agentType === 'SENIOR' || User.agentType === 'MASTER')) ? (
            <button
              className={`btn btn-link nav-link ${classes.pointer} ${
                window.location.pathname === '/agent' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/agent')}
            >
              Agent
            </button>
          ) : null}
          <button
            className={`btn btn-link nav-link ${classes.pointer} ${
              window.location.pathname === '/member' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/member')}
          >
            Member
          </button>
        </div>
      </div>
    </nav>
  );
};

export default UserNavbar;
