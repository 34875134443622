

const AgentUtils = {

  getLevel: (username) => {
    if (username.indexOf("@") > -1) return 'SubAgent';
    if (username.length === 2) return 'Vendor';
    else if (username.length === 4) return 'Senior';
    else if (username.length === 6) return 'Master';
    else if (username.length === 8) return 'Agent';
    return 'Member';
  },

  getCurrentLevel: (username) => {
    let usr = username;
    if (username.indexOf("@") > -1) {
      usr = username.split("@")[1].trim();
    }
    if (usr === 'COMPANY') return 'Company';
    if (usr.length === 2) return 'Vendor';
    else if (usr.length === 4) return 'Senior';
    else if (usr.length === 6) return 'Master';
    return 'Agent';
  },

  getDownLevel: (username) => {
    let usr = username;
    if (username.indexOf("@") > -1) {
      usr = username.split("@")[1].trim();
    }
    if (usr === 'COMPANY') return 'Vendor';
    if (username.length === 2) return 'Senior';
    else if (username.length === 4) return 'Master';
    else if (username.length === 6) return 'Agent';
    return 'Member';
  },

  getUrlRole: ()=> {
    if (window.location.pathname === '/vendor') return 'Vendor';
    if (window.location.pathname === '/senior') return 'Senior';
    if (window.location.pathname === '/master') return 'Master';
    if (window.location.pathname === '/agent') return 'Agent';
    if (window.location.pathname === '/member') return 'Member';
    return '';
  },

  isCompanyOrCompanySub: (username) => {
    if (username === 'COMPANY') return true;
    if (username.indexOf("@COMPANY") > -1) return true;
    return false;
  },

  isVendorOrCompanyOrSubOf: (username) => {
    let usr = username;
    if (username.indexOf("@") > -1) {
      usr = username.split("@")[1].trim();
    }
    if (usr === 'COMPANY') return true;
    if (usr.length === 2) {
      return true;
    }
    return false;
  }

}

export default AgentUtils;
