import axiosInstance from '../../config';
import React, { useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  border: {
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  borderInactive: {
    backgroundColor: '#e1e1e1',
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const RecalculateList = ({ User }) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  const [totalBalAdjustment, setTotalBalAdjustment] = useState(0);
  const [totalRepAdjustment, setTotalRepAdjustment] = useState(0);
  const history = useHistory();

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/balReport/adjust/list')
      .then((res) => {
        setReports(res.data.content);
        let sumBal = 0, sumRep = 0;
        for (let i = 0; i < res.data.content.length; i++) {
          if (res.data.content[i].endBalance != null && res.data.content[i].beginningBalance) sumBal += res.data.content[i].endBalance - res.data.content[i].beginningBalance;
          if (res.data.content[i].endReportBalance != null && res.data.content[i].beginningReportBalance) sumRep += res.data.content[i].endReportBalance - res.data.content[i].beginningReportBalance;
        }
        setTotalBalAdjustment(sumBal);
        setTotalRepAdjustment(sumRep);
        console.log('sumBal = '+sumBal);
        console.log('sumRep = '+sumRep);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    handleChange(null);
  }, []);

  return (
    <>
      <Navbar User={User} />
        {reports.length > 0 ? (
          <div className="table-responsive-sm mx-2">
            <table className="table table-sm table-hover table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th scope="col" colSpan={2}></th>
                  <th scope="col" colSpan={3}>Player Balance</th>
                  <th scope="col" colSpan={3}>Bal Report Balance</th>
                  <th scope="col" colSpan={2}></th>
                </tr>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">Username</th>
                  <th scope="col">Previous Balance</th>
                  <th scope="col">Adjusted Balance</th>
                  <th scope="col">Adjustment</th>
                  <th scope="col">Previous Report Balance</th>
                  <th scope="col">Adjusted Report Balance</th>
                  <th scope="col">Adjustment</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Created By</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((info, index) => (
                  <tr key={index}>
                    <td className="text-dark text-left">{index + 1}</td>
                    <td className="text-dark text-left">{info.username}</td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.beginningBalance ? info.beginningBalance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={info.beginningBalance < 0? 'text-danger':''}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.endBalance ? info.endBalance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={info.endBalance < 0? 'text-danger':''}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.endBalance && info.beginningBalance ? info.endBalance - info.beginningBalance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={info.endBalance - info.beginningBalance < 0? 'text-danger':''}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.beginningReportBalance ? info.beginningReportBalance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={info.beginningReportBalance < 0? 'text-danger':''}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.endReportBalance ? info.endReportBalance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={info.endReportBalance < 0? 'text-danger':''}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.endReportBalance && info.beginningReportBalance ? info.endReportBalance - info.beginningReportBalance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={info.endReportBalance - info.beginningReportBalance < 0? 'text-danger':''}
                      />
                    </td>
                    <td className="text-dark text-center">{moment(info.createdAt).format('DD/MM/yyyy HH:mm:ss')}</td>
                    <td className="text-dark text-center">{info.createdBy}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="font-weight-bold">
                  <td className="text-dark text-center" colSpan="2">
                    Total
                  </td>
                  <td className="text-right" colSpan={3}>
                    <CurrencyFormat
                      value={totalBalAdjustment}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      className={totalBalAdjustment && totalBalAdjustment < 0 ? 'text-danger':null}
                    />
                  </td>
                  <td className="text-right" colSpan={3}>
                    <CurrencyFormat
                      value={totalRepAdjustment}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      className={totalRepAdjustment && totalRepAdjustment < 0 ? 'text-danger':null}
                    />
                  </td>
                  <td className="text-dark text-center" colSpan="2"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : null}
    </>
  );
};

export default RecalculateList;
