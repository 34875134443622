import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const ProfitLoss = ({ User }) => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [level, setLevel] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [bets, setBets] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [searchUsername, setSearchUsername] = useState(null);
  const [searchClick, setSearchClick] = useState(null);
  const [sumWager, setSumWager] = useState(0);
  const [sumTurnover, setSumTurnover] = useState(0);
  const [sumValid, setSumValid] = useState(0);
  const [sumStakeAgent, setSumStakeAgent] = useState(0);
  const [sumStakeMaster, setSumStakeMaster] = useState(0);
  const [sumStakeSenior, setSumStakeSenior] = useState(0);
  const [sumStakeVendor, setSumStakeVendor] = useState(0);
  const [sumStakeCompany, setSumStakeCompany] = useState(0);
  const [sumWlAgent, setSumWlAgent] = useState(0);
  const [sumWlMaster, setSumWlMaster] = useState(0);
  const [sumWlSenior, setSumWlSenior] = useState(0);
  const [sumWlVendor, setSumWlVendor] = useState(0);
  const [sumWlCompany, setSumWlCompany] = useState(0);
  const [sumCommAgent, setSumCommAgent] = useState(0);
  const [sumCommMaster, setSumCommMaster] = useState(0);
  const [sumCommSenior, setSumCommSenior] = useState(0);
  const [sumCommVendor, setSumCommVendor] = useState(0);
  const [sumCommCompany, setSumCommCompany] = useState(0);
  const [sumPlayerWin, setSumPlayerWin] = useState(0);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const history = useHistory();
  const handleSearch = () => {
    axiosInstance
      .get('agent/generalReport', {
        params: {
          username: username ? username : null,
          searchUsername: searchUsername ? searchUsername : null,
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
        },
      })
      .then((res) => {
        setReports(res.data);
        calculateSum(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const calculateSum = (report) => {
    let wager = 0;
    let turnover = 0;
    let valid = 0;
    let stakeAgent = 0,
      stakeMaster = 0,
      stakeSenior = 0,
      stakeVendor = 0,
      stakeCompany = 0;
    let wlAgent = 0,
      wlMaster = 0,
      wlSenior = 0,
      wlVendor = 0,
      wlCompany = 0,
      wlPlayer = 0;
    let commAgent = 0,
      commMaster = 0,
      commSenior = 0,
      commVendor = 0,
      commCompany = 0;

    for (var i = 0; i < report.length; i++) {
      wager += report[i].wager;
      turnover += report[i].amount;
      valid += report[i].validAmount;
      if (report[i].playerWin) wlPlayer += report[i].playerWin;
      if (report[i].agentContra) stakeAgent += report[i].agentContra;
      if (report[i].masterContra) stakeMaster += report[i].masterContra;
      if (report[i].seniorContra) stakeSenior += report[i].seniorContra;
      if (report[i].vendorContra) stakeVendor += report[i].vendorContra;
      if (report[i].companyContra) stakeCompany += report[i].companyContra;
      if (report[i].agentWinning) wlAgent += report[i].agentWinning;
      if (report[i].masterWinning) wlMaster += report[i].masterWinning;
      if (report[i].seniorWinning) wlSenior += report[i].seniorWinning;
      if (report[i].vendorWinning) wlVendor += report[i].vendorWinning;
      if (report[i].companyWinning) wlCompany += report[i].companyWinning;
      if (report[i].agentCommission) commAgent += report[i].agentCommission;
      if (report[i].masterCommission) commMaster += report[i].masterCommission;
      if (report[i].seniorCommission) commSenior += report[i].seniorCommission;
      if (report[i].vendorCommission) commVendor += report[i].vendorCommission;
      if (report[i].companyCommission)
        commCompany += report[i].companyCommission;
    }
    setSumWager(wager);
    setSumTurnover(turnover);
    setSumValid(valid);
    setSumStakeAgent(stakeAgent);
    setSumStakeMaster(stakeMaster);
    setSumStakeSenior(stakeSenior);
    setSumStakeVendor(stakeVendor);
    setSumStakeCompany(stakeCompany);
    setSumWlAgent(wlAgent);
    setSumWlMaster(wlMaster);
    setSumWlSenior(wlSenior);
    setSumWlVendor(wlVendor);
    setSumWlCompany(wlCompany);
    setSumCommAgent(commAgent);
    setSumCommMaster(commMaster);
    setSumCommSenior(commSenior);
    setSumCommVendor(commVendor);
    setSumCommCompany(commCompany);
    setSumPlayerWin(wlPlayer);
  };

  const calculateBet = (report) => {
    let wager = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning += report[i].winning;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning);
  };

  const clearForm = () => {
    document.getElementById('member-login').value = '';
    setSearchUsername(null);
  };

  const backToParent = () => {
    clearForm();
    if (
      username != null &&
      username.length > 0 &&
      username !== sessionStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (username == null || username.length <= 8) {
      axiosInstance
        .get('agent/generalReport', {
          params: {
            username: username ? username : null,
            searchUsername: searchUsername ? searchUsername : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          },
        })
        .then((res) => {
          setReports(res.data);
          calculateSum(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else {
      if (value == null) value = 1;
      axiosInstance
        .get('agent/generalReport/playerDetails', {
          params: {
            username: username ? username : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            pageNo: isNaN(value) ? null : value - 1,

            pageSize: 10,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setDate(today.getDate() - minus * 7);
        today2.setDate(today.getDate() + 6);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  useEffect(() => {
    setSearchClick(false);
    if (username == null) {
      setUsername(sessionStorage.getItem('me'));
      setLevel(sessionStorage.getItem('role'));
    }
    axiosInstance.get('public/today').then((res) => {
      if (startDate == null)
        setStartDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      if (endDate == null)
        setEndDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
    handleChange(null);
  }, [username, startDate, endDate]);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
          Login ID{' '}
          <input
            type="text"
            id="member-login"
            value={searchUsername}
            onChange={(event) => setSearchUsername(event.target.value)}
          />
          &nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              setSearchClick(true);
            }}
          />
        </form>
      </div>
      {reports !== null ? (
        <div className="table-responsive-sm mx-2">
          {username != null &&
          username.length > 0 &&
          username !== sessionStorage.getItem('me') ? (
            <button
              className="btn btn-link text-primary p-0 "
              onClick={() => {
                backToParent();
              }}
            >
              Back
            </button>
          ) : null}
          {username == null || username.length <= 8 ? (
            <>
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" rowSpan={2}>
                      Name
                    </th>
                    <th scope="col" rowSpan={2}>
                      Login
                      <br />
                      ID
                    </th>
                    <th scope="col" rowSpan={2}>
                      Wager
                    </th>
                    <th scope="col" rowSpan={2}>
                      Turn Over
                    </th>
                    <th scope="col" rowSpan={2}>
                      Valid
                      <br />
                      Turn Over
                    </th>
                    <th scope="col" rowSpan={2}>
                      Member
                      <br />
                      W/L
                    </th>
                    <th scope="col" rowSpan={2}>
                      Gross
                      <br />
                      Comm
                    </th>
                    {/* <th scope="col" rowSpan={2}>Valid Turn Over</th> */}
                    {/* <th scope="col" rowSpan={2}>Gross Comm</th> */}
                    <th scope="col" colSpan={4}>
                      Agent
                    </th>
                    {level === 'COMPANY' ? (
                      <>
                        <th scope="col" colSpan={4}>
                          Master
                        </th>
                        <th scope="col" colSpan={4}>
                          Senior
                        </th>
                        <th scope="col" colSpan={4}>
                          Vendor
                        </th>
                        <th scope="col" colSpan={4}>
                          Company
                        </th>
                      </>
                    ) : level === 'VENDOR' ? (
                      <>
                        <th scope="col" colSpan={4}>
                          Master
                        </th>
                        <th scope="col" colSpan={4}>
                          Senior
                        </th>
                        <th scope="col" colSpan={4}>
                          Vendor
                        </th>
                      </>
                    ) : level === 'SENIOR' ? (
                      <>
                        <th scope="col" colSpan={4}>
                          Master
                        </th>
                        <th scope="col" colSpan={4}>
                          Senior
                        </th>
                      </>
                    ) : level === 'MASTER' ? (
                      <>
                        <th scope="col" colSpan={4}>
                          Master
                        </th>
                      </>
                    ) : null}
                  </tr>
                  <tr>
                    <th scope="col">Stake</th>
                    <th scope="col">Win/Lose</th>
                    <th scope="col">Comm</th>
                    <th scope="col">Total</th>
                    {level === 'COMPANY' ? (
                      <>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                      </>
                    ) : level === 'VENDOR' ? (
                      <>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                      </>
                    ) : level === 'SENIOR' ? (
                      <>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                      </>
                    ) : level === 'MASTER' ? (
                      <>
                        <th scope="col">Stake</th>
                        <th scope="col">Win/Lose</th>
                        <th scope="col">Comm</th>
                        <th scope="col">Total</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {reports.map((info, index) => (
                    <tr key={index}>
                      <td className="text-center">{info.name}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-link text-primary p-0 "
                          onClick={() => {
                            setUsername(info.username);
                            clearForm();
                          }}
                        >
                          {info.username}
                        </button>
                      </td>
                      <td className="text-center">{info.wager}</td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={info.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={info.validAmount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td
                        className={`text-right${
                          info.playerWin < 0 ? ' text-danger' : ''
                        }`}
                      >
                        <CurrencyFormat
                          value={info.playerWin}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td
                        className={`text-right${
                          info.agentCommission +
                            info.masterCommission +
                            info.seniorCommission +
                            info.vendorCommission +
                            info.companyCommission <
                          0
                            ? ' text-danger'
                            : ''
                        }`}
                      >
                        <CurrencyFormat
                          value={
                            info.agentCommission +
                            info.masterCommission +
                            info.seniorCommission +
                            info.vendorCommission +
                            info.companyCommission
                          }
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      {/* <td className="text-right"><CurrencyFormat value={info.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td> */}
                      {/* <td className="text-right"><CurrencyFormat value={info.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td> */}
                      {level === 'COMPANY' ||
                      level === 'VENDOR' ||
                      level === 'SENIOR' ||
                      level === 'MASTER' ||
                      level === 'AGENT' ? (
                        <>
                          <td className="text-right">
                            <CurrencyFormat
                              value={info.agentContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.agentWinning < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.agentWinning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.agentCommission < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.agentCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.agentWinning + info.agentCommission < 0
                                ? ' text-danger'
                                : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.agentWinning + info.agentCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' ||
                      level === 'VENDOR' ||
                      level === 'SENIOR' ||
                      level === 'MASTER' ? (
                        <>
                          <td className="text-right">
                            <CurrencyFormat
                              value={info.masterContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.masterWinning < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.masterWinning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.masterCommission < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.masterCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.masterWinning + info.masterCommission < 0
                                ? ' text-danger'
                                : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.masterWinning + info.masterCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' ||
                      level === 'VENDOR' ||
                      level === 'SENIOR' ? (
                        <>
                          <td className="text-right">
                            <CurrencyFormat
                              value={info.seniorContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.seniorWinning < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.seniorWinning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.seniorCommission < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.seniorCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.seniorWinning + info.seniorCommission < 0
                                ? ' text-danger'
                                : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.seniorWinning + info.seniorCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' || level === 'VENDOR' ? (
                        <>
                          <td className="text-right">
                            <CurrencyFormat
                              value={info.vendorContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.vendorWinning < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.vendorWinning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.vendorCommission < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.vendorCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.vendorCommission + info.vendorWinning < 0
                                ? ' text-danger'
                                : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.vendorWinning + info.vendorCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' ? (
                        <>
                          <td className="text-right">
                            <CurrencyFormat
                              value={info.companyContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.companyWinning < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.companyWinning}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.companyCommission < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={info.companyCommission}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`text-right${
                              info.companyWinning + info.companyCommission < 0
                                ? ' text-danger'
                                : ''
                            }`}
                          >
                            <CurrencyFormat
                              value={
                                info.companyWinning + info.companyCommission
                              }
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-center" colSpan="2">
                      Total
                    </td>
                    <td className={`text-center`}>{sumWager}</td>
                    <td className={`text-right`}>
                      <CurrencyFormat
                        value={sumTurnover}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className={`text-right`}>
                      <CurrencyFormat
                        value={sumValid}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-right${
                        sumPlayerWin < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={sumPlayerWin}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-right${
                        sumCommAgent +
                          sumCommMaster +
                          sumCommSenior +
                          sumCommVendor +
                          sumCommVendor <
                        0
                          ? ' text-danger'
                          : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={
                          sumCommAgent +
                          sumCommMaster +
                          sumCommSenior +
                          sumCommVendor +
                          sumCommCompany
                        }
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    {level === 'COMPANY' ||
                    level === 'VENDOR' ||
                    level === 'SENIOR' ||
                    level === 'MASTER' ||
                    level === 'AGENT' ? (
                      <>
                        <td className={`text-right`}>
                          <CurrencyFormat
                            value={sumStakeAgent}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlAgent < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumWlAgent}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumCommAgent < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommAgent}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlAgent + sumCommAgent < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommAgent + sumWlAgent}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </>
                    ) : null}
                    {level === 'COMPANY' ||
                    level === 'VENDOR' ||
                    level === 'SENIOR' ||
                    level === 'MASTER' ? (
                      <>
                        <td className={`text-right`}>
                          <CurrencyFormat
                            value={sumStakeMaster}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlMaster < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumWlMaster}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumCommMaster < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommMaster}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlMaster + sumCommMaster < 0
                              ? ' text-danger'
                              : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommMaster + sumWlMaster}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </>
                    ) : null}
                    {level === 'COMPANY' ||
                    level === 'VENDOR' ||
                    level === 'SENIOR' ? (
                      <>
                        <td className={`text-right`}>
                          <CurrencyFormat
                            value={sumStakeSenior}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlSenior < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumWlSenior}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumCommSenior < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommSenior}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlSenior + sumCommSenior < 0
                              ? ' text-danger'
                              : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommSenior + sumWlSenior}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </>
                    ) : null}
                    {level === 'COMPANY' || level === 'VENDOR' ? (
                      <>
                        <td className={`text-right`}>
                          <CurrencyFormat
                            value={sumStakeVendor}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlVendor < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumWlVendor}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumCommVendor < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommVendor}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlVendor + sumCommVendor < 0
                              ? ' text-danger'
                              : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommVendor + sumWlVendor}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </>
                    ) : null}
                    {level === 'COMPANY' ? (
                      <>
                        <td className={`text-right`}>
                          <CurrencyFormat
                            value={sumStakeCompany}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlCompany < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumWlCompany}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumCommCompany < 0 ? ' text-danger' : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommCompany}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`text-right${
                            sumWlCompany + sumCommCompany < 0
                              ? ' text-danger'
                              : ''
                          }`}
                        >
                          <CurrencyFormat
                            value={sumCommCompany + sumWlCompany}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                      </>
                    ) : null}
                    {/* <td colSpan="4"></td> */}
                  </tr>
                </tfoot>
              </table>
            </>
          ) : (
            <>
              <table className="table table-sm table-striped">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Username</th>
                    <th scope="col">Date</th>
                    <th scope="col">Reference</th>
                    <th scope="col">Bet Type</th>
                    <th scope="col">Bet Details</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Result</th>
                    <th scope="col">Winning</th>
                  </tr>
                </thead>
                <tbody>
                  {bets.map((bet, index) => (
                    <tr className={classes.border} key={index}>
                      <td className="text-dark">{index + 1}</td>
                      <td className="text-left">{username}</td>
                      <td className="text-left">
                        {moment(bet.created).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td className="text-left">{bet.reference}</td>
                      <td className="text-left">
                        {bet.market === 'PARLAY' ? (
                          bet.type
                        ) : (
                          <>
                            {bet.sport} {bet.market}
                            <br />
                            {bet.game}
                          </>
                        )}
                      </td>
                      <td className="text-left">
                        {bet.market === 'PARLAY' ? (
                          <>
                            Mix Parlay @ {bet.odds.toFixed(2)}
                            <table
                              className="table table-sm table-striped"
                              width="300"
                            >
                              {bet.parlays.map((parlay) => (
                                <>
                                  <tr>
                                    <td width="200">
                                      <span
                                        className={`${classes.compName} ${
                                          parlay.status === 'REJECTED'
                                            ? classes.reject
                                            : null
                                        }`}
                                      >
                                        {parlay.comp}
                                      </span>
                                      <br />
                                      <span
                                        className={`${classes.teams} ${
                                          parlay.status === 'REJECTED'
                                            ? classes.reject
                                            : null
                                        }`}
                                      >
                                        <span
                                          className={
                                            parlay.high === 1
                                              ? classes.strong
                                              : null
                                          }
                                        >
                                          {parlay.home} vs{' '}
                                        </span>
                                        <span
                                          className={
                                            parlay.high === 2
                                              ? classes.strong
                                              : null
                                          }
                                        >
                                          {parlay.away}
                                        </span>
                                      </span>
                                      <br />
                                      {parlay.market === 'LIVE' ? (
                                        <>
                                          <span className={classes.running}>
                                            (Running {parlay.homeGoal}-
                                            {parlay.awayGoal})
                                          </span>
                                          <br />
                                        </>
                                      ) : null}
                                      <span
                                        className={`${classes.handiCap} ${
                                          parlay.status === 'REJECTED'
                                            ? classes.reject
                                            : null
                                        }`}
                                      >
                                        <span className={classes.compName}>
                                          {parlay.desc}{' '}
                                          {parlay.handicap !== '' ? (
                                            <span className={classes.handiCap}>
                                              ({parlay.handicap}){' '}
                                            </span>
                                          ) : null}
                                          <span>
                                            @ {parlay.odds.toFixed(2)}
                                          </span>
                                        </span>
                                      </span>
                                    </td>
                                    <td width="50">
                                      {parlay.ftHome}-{parlay.ftAway} (
                                      {parlay.fhHome}-{parlay.fhAway})
                                    </td>
                                    <td width="50">
                                      {parlay.result.charAt(0).toUpperCase() +
                                        parlay.result
                                          .slice(1)
                                          .toLowerCase()
                                          .replace('_', ' ')}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </table>
                          </>
                        ) : (
                          <>
                            <span
                              className={`${classes.compName} ${
                                bet.status === 'REJECTED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              {bet.comp}
                            </span>
                            <br />
                            <span
                              className={`${classes.teams} ${
                                bet.status === 'REJECTED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              <span
                                className={
                                  bet.high === 1 ? classes.strong : null
                                }
                              >
                                {bet.home} vs{' '}
                              </span>
                              <span
                                className={
                                  bet.high === 2 ? classes.strong : null
                                }
                              >
                                {bet.away}
                              </span>
                            </span>
                            <br />
                            {bet.market === 'LIVE' ? (
                              <>
                                <span className={classes.running}>
                                  (Running {bet.homeGoal}-{bet.awayGoal})
                                </span>
                                <br />
                              </>
                            ) : null}
                            <span
                              className={`${classes.handiCap} ${
                                bet.status === 'REJECTED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              <span className={classes.compName}>
                                {bet.desc}{' '}
                                {bet.handicap !== '' ? (
                                  <span className={classes.handiCap}>
                                    ({bet.handicap}){' '}
                                  </span>
                                ) : null}
                                <span>@ {bet.odds.toFixed(2)}</span>
                              </span>
                            </span>
                          </>
                        )}
                      </td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={bet.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td className="text-center">
                        {bet.result.charAt(0).toUpperCase() +
                          bet.result.slice(1).toLowerCase().replace('_', ' ')}
                      </td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={bet.winning}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td className="text-center" colSpan="6">
                      Total
                    </td>
                    <td className={`text-right`}>
                      <CurrencyFormat
                        value={playerWager}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td>&nbsp;</td>
                    <td className={`text-right`}>
                      <CurrencyFormat
                        value={playerWinning}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tfoot>
              </table>
              <Pagination
                count={totalPage}
                onChange={handleChange}
                className={classes.pagination}
              />
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ProfitLoss;
