

const Utils = {

  copyLocalToSessionStorage: () => {
    for (var i = 0; i < localStorage.length; i++) {
      sessionStorage.setItem(localStorage.key(i), localStorage.getItem(localStorage.key(i)));
    }
    localStorage.clear();
  },

  handleLinkClick: (e, history, url) => {
    e.preventDefault();
    if (e.ctrlKey) {
      //copy session to localstorage
      localStorage.clear();
      for (var i = 0; i < sessionStorage.length; i++){
        localStorage.setItem(sessionStorage.key(i), sessionStorage.getItem(sessionStorage.key(i)));
      }
      window.open(url, '_blank');
    } else {
      if (window.location.pathname === url) history.go(0);
      else history.push(url);
    }
   }
}

export default Utils;
