import Axios from 'axios';
import { toast } from 'react-toastify';
import Utils from './utils';

const baseURL = process.env.REACT_APP_API_URL;
const axiosInstance = Axios.create({
  baseURL: baseURL,
});

const ERRORCODES = {
  // AdminExceptionCode
  ADM001: 'Username not unique',
  ARES001: 'Result not found',
  ARES002: 'Cancel result already calculated',
  ABET001: 'Bet not found',
  ABET002: 'Parlay not found',
  ABET101: 'Accept / Reject bet already rejected',
  ABET102: 'Accept / Reject bet already calculated',
  ABET103: 'Accept / Reject bet already accepted',
  ABET110: 'Accept / Reject bet invalid type',
  RB001: 'Rollback no change',
  RB002: 'Rollback not available',
  RB999: 'Rollback failed',
  ADD001: 'Domain not found',
  ADD002: 'Incomplete param',
  ADD003: 'Invalid IP',
  ARP001: 'Invalid admin',
  ARP002: 'Invalid supervisor',
  ARP003: 'Invalid supervisor password',
  ADA001: 'Error disable admin',
  ADA002: 'Error enable admin',

  //AgentExceptionCode
  AG800: 'Invalid downline ID',
  AG801: 'Invalid upline password',
  AG000: 'Input validation',
  AG001: 'Username not unique',
  AG002: 'Invalid username format',
  AG003: 'Unauthorised',
  AG004: 'Insufficient credit',
  AG005: 'Unauthorised copy',
  AG006: 'Unauthorised role',
  AG007: 'Invalid copy role',
  AG008: 'Insufficient upline balance',
  AG009: 'Insufficient commission',
  AG010: 'Invalid commission withdrawal type',
  AG100: 'Invalid agent setting limit',
  AG101: 'Invalid player setting limit',
  AG200: 'Invalid password',
  AG400: 'Agent not found',
  AG401: 'Member not found',
  AG402: 'Agent setting not found',
  AG501: 'Error disable downline',
  AG502: 'Error enable downline',
  AG601: 'Agent creation not allowed',
  AG602: 'User creation not allowed',
  AG603: 'System setting duplicated error',
  AG604: 'System setting invalid',
  AG999: 'Other validation',
  AS001: 'Invalid max share rate',
  AS002: 'Invalid min share rate',
  AS003: 'Invalid share rate due to downlines',
  AS004: 'Invalid upline share rate',
  AS005: 'Invalid upline share / max rate',
  AS006: 'Invalid upline share / min rate',
  ANS001: 'Cannot unsuspend, account is not suspended',
  ANS002: 'Account suspended. Please contact your upline',
  ANS003: 'Account disabled. Please contact your upline',
  ANS004: 'Cannot delete. Account has business',
  ABR001: 'Withdrawal is paused. Please try again later!',

  // AuthenticationExceptionCode
  AUTH000: 'Invalid credential',
  AUTH001: 'Wrong password',
  AUTH002: 'Disabled user',
  AUTH003: 'Empty password',
  AUTH004: 'Password patterns need to be 6 or more alphanumeric characters',
  AUTH005: 'Exceed max consecutive failed login',

  //BusinessException
  // MemberWithdrawalExceptionCode
  MW001: 'Member not found',
  MW002: 'Agent not found',
  MW003: 'Invalid downline',
  MW004: 'Insufficient balance',
  MW005: 'Invalid OTP',
  MW006: 'Withdrawal respond null',

  // PlayerExceptionCode
  AP000: 'Player not found',
  AP001: 'Invalid session',
  AP002: 'Valid player not found',
  AP003: 'Password expired',
  AP004: 'Bet not found',

  // Favorite
  AP500: 'Invalid favorite',
  AP501: 'Invalid favorite comp',
  AP502: 'Invalid favorite match',
  APP801: 'Invalid parlay quote',
  APP802: 'Parlay quote used',
  APP803: 'Unauthorised parlay quote',
  APP804: 'Invalid parlay param',
  APO501: 'ODD not available',
  APQ301: 'Invalid quote',
  APQ302: 'Quote used',
  APQ303: 'Unauthorised quote',
  APB001: 'Bet error duplicate references',
  APB999: 'Bet error others',
  APC01: 'Insufficient credit',

  // WithdrawOtpExceptionCode
  OTP100: 'OTP expired',
  OTP101: 'OTP invalid',
};

axiosInstance.interceptors.request.use(
  function (config) {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    } else {
      const storageToken = localStorage.getItem('token');

      if (storageToken) {
        config.headers['Authorization'] = 'Bearer ' + storageToken;
        Utils.copyLocalToSessionStorage();
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log('Error: ', error);
    if (error && error.response && error.response.status === 401) {
      sessionStorage.clear();

      let errMessage = `Error: ${error.message}`;
      let errorCode =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        '';

      if (errorCode && ERRORCODES[errorCode]) {
        errMessage = ERRORCODES[errorCode];
      }
      toast.error(errMessage, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
      return Promise.reject(error);
    } else {
      let errMessage = `Error: ${error.message}`;
      let errorCode =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        '';

      if (errorCode && ERRORCODES[errorCode]) {
        errMessage = ERRORCODES[errorCode];
      }

      if (error && error.response && error.response.status === 503) {
        if (error.response.config.url !== 'agent/me') {
          if (window.location.pathname !== '/maintenance') {
            window.location.href = '/maintenance';
          }
        }
      } else {
        toast.error(errMessage, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
      return Promise.reject(error);
    }
  }
);
export default axiosInstance;
