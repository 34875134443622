import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../config';
import ReportNavbar from './navbars/ReportNavbar';
import UserNavbar from './navbars/UserNavbar';
import BalNavbar from './navbars/BalNavbar';
import AdjustmentNavbar from './navbars/AdjustmentNavbar';
import Password from './Password';
import Utils from '../utils';

const Navbar = ({ username, User }) => {
  const role = sessionStorage.getItem('role');
  const history = useHistory();
  // reset password modal
  const [reset, setReset] = useState(false);
  // handle logout
  const handleLogout = () => {
    axiosInstance
      .post('/token/invalidate')
      .then((res) => {
        console.log(res);
        sessionStorage.clear();
        history.push('/');
      })
      .catch((err) => console.log(err));
  };

  const copySession = () => {
    for (var i = 0; i < sessionStorage.length; i++) {
      localStorage.setItem(
        sessionStorage.key(i),
        sessionStorage.getItem(sessionStorage.key(i))
      );
    }
    window.open(window.location.pathname, '_blank');
  };

  return (
    <>
      <nav
        className={`navbar navbar-expand-lg navbar-light  py-0 border-bottom`}
      >
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav position-relative">
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/dashboard' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/dashboard')}
            >
              Dashboard
            </button>
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/message' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/message')}
            >
              Messages
            </button>

            {User.privUser !== false ? (
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/member' ||
                  window.location.pathname === '/agent' ||
                  window.location.pathname === '/master' ||
                  window.location.pathname === '/senior' ||
                  window.location.pathname === '/vendor' ||
                  window.location.pathname === '/operation' ||
                  window.location.pathname === '/operationm' ||
                  window.location.pathname === '/creditm' ||
                  window.location.pathname === '/credit'
                    ? ' active'
                    : ''
                }`}
                onClick={(e) => {
                  Utils.handleLinkClick(e, history, '/member');
                }}
              >
                User
              </button>
            ) : null}

            {User.privReport !== false ? (
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/wl' ||
                  window.location.pathname === '/wlDetail' ||
                  window.location.pathname === '/wlPackage' ||
                  window.location.pathname === '/wlType' ||
                  window.location.pathname === '/out' ||
                  window.location.pathname === '/outDetail' ||
                  window.location.pathname === '/outType' ||
                  window.location.pathname === '/cancel' ||
                  window.location.pathname === '/outstanding' ||
                  window.location.pathname === '/gamecategory' ||
                  window.location.pathname === '/bigwinner' ||
                  window.location.pathname === '/sharerate' ||
                  window.location.pathname === '/indisharerate' ||
                  window.location.pathname === '/consolidated' ||
                  window.location.pathname === '/win'
                    ? ' active'
                    : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/wl')}
              >
                Report
              </button>
            ) : null}
            {User.privBalReport !== false ? (
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/winlose' ||
                  window.location.pathname === '/winlose2' ||
                  window.location.pathname === '/grosscomm' ||
                  window.location.pathname === '/grosscomm2' ||
                  window.location.pathname === '/security' ||
                  window.location.pathname === '/negative' ||
                  window.location.pathname === '/maxloss' ||
                  window.location.pathname === '/balcreditm' ||
                  window.location.pathname === '/balcredit'
                    ? ' active'
                    : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/winlose')}
              >
                Bal Report
              </button>
            ) : null}
            {role === 'COMPANY' ||
            (User.agentType != null && User.agentType === 'COMPANY') ? (
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/rollback' ? ' active' : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/rollback')}
              >
                Rollback
              </button>
            ) : null}
            {role === 'COMPANY' ||
            (User.agentType != null && User.agentType === 'COMPANY') ? (
              <>
                <button
                  className={`btn btn-link nav-link text-dark${
                    window.location.pathname === '/summary' ? ' active' : ''
                  }`}
                  onClick={(e) => Utils.handleLinkClick(e, history, '/summary')}
                >
                  Company Summary
                </button>
                <button
                  className={`btn btn-link nav-link text-dark${
                    window.location.pathname === '/stats' ? ' active' : ''
                  }`}
                  onClick={(e) => Utils.handleLinkClick(e, history, '/stats')}
                >
                  Statistic
                </button>
                <button
                  className={`btn btn-link nav-link text-dark${
                    window.location.pathname === '/writeoff' ||
                    window.location.pathname === '/recalculateList' ||
                    window.location.pathname === '/recalculate'
                      ? ' active'
                      : ''
                  }`}
                  onClick={(e) =>
                    Utils.handleLinkClick(e, history, '/recalculate')
                  }
                >
                  Adjustment
                </button>
                <button
                  className={`btn btn-link nav-link text-dark${
                    window.location.pathname === '/systemSettings'
                      ? ' active'
                      : ''
                  }`}
                  onClick={(e) =>
                    Utils.handleLinkClick(e, history, '/systemSettings')
                  }
                >
                  Settings
                </button>
              </>
            ) : null}
            <button
              className={`btn btn-link nav-link text-dark`}
              onClick={() => {
                setReset(true);
              }}
            >
              Change Password
            </button>
          </div>
          <div className={`d-flex ml-auto align-items-center`}>
            {sessionStorage.getItem('me') !== undefined ? (
              <span className="active">
                Hi, <strong>{sessionStorage.getItem('me')}</strong>
                {User.suspendAt ? (
                  <span className="text-danger"> (Suspended)</span>
                ) : null}
                &nbsp;&nbsp;
              </span>
            ) : null}
            &nbsp;
            <button className={`btn btn-sm btn-primary`} onClick={copySession}>
              New Tab
            </button>
            &nbsp;
            <button onClick={handleLogout} className="btn btn-sm btn-danger">
              Logout
            </button>
          </div>
        </div>
      </nav>
      {/* navbars for different menus */}
      <UserNavbar
        User={User}
        role={role}
        isUserNavbar={
          window.location.pathname === '/sub' ||
          window.location.pathname === '/member' ||
          window.location.pathname === '/agent' ||
          window.location.pathname === '/master' ||
          window.location.pathname === '/senior' ||
          window.location.pathname === '/vendor' ||
          window.location.pathname === '/operation' ||
          window.location.pathname === '/operationm' ||
          window.location.pathname === '/creditm' ||
          window.location.pathname === '/credit'
        }
      />
      {/* report navbar */}

      <ReportNavbar
        role={role === 'SUBAGENT' ? User.agentType : role}
        isReportNavbar={
          window.location.pathname === '/wl' ||
          window.location.pathname === '/wlDetail' ||
          window.location.pathname === '/wlPackage' ||
          window.location.pathname === '/wlType' ||
          window.location.pathname === '/out' ||
          window.location.pathname === '/outDetail' ||
          window.location.pathname === '/outType' ||
          window.location.pathname === '/cancel' ||
          window.location.pathname === '/outstanding' ||
          window.location.pathname === '/gamecategory' ||
          window.location.pathname === '/sharerate' ||
          window.location.pathname === '/indisharerate' ||
          window.location.pathname === '/win' ||
          window.location.pathname === '/consolidated' ||
          // window.location.pathname === '/winlose' ||
          window.location.pathname === '/bigwinner'
        }
      />

      <BalNavbar
        role={
          User.username.indexOf('@') === -1
            ? role
            : User.username.split('@')[1] === 'COMPANY'
            ? 'COMPANY'
            : User.username.split('@')[1].length === 2
            ? 'VENDOR'
            : 'SUBAGENT'
        }
        isBalNavbar={
          window.location.pathname === '/winlose' ||
          window.location.pathname === '/winlose2' ||
          window.location.pathname === '/grosscomm' ||
          window.location.pathname === '/grosscomm2' ||
          window.location.pathname === '/balcreditm' ||
          window.location.pathname === '/balcredit' ||
          window.location.pathname === '/security' ||
          window.location.pathname === '/maxloss' ||
          window.location.pathname === '/negative'
        }
      />

      <AdjustmentNavbar
        isAdjustmentNavbar={
          window.location.pathname === '/recalculate' ||
          window.location.pathname === '/recalculateList' ||
          window.location.pathname === '/writeoff'
        }
      />

      {/* reset password modal */}
      {sessionStorage.getItem('me') !== undefined ? (
        <Password
          passwordModal={reset}
          setPasswordModal={setReset}
          username={sessionStorage.getItem('me')}
        />
      ) : null}
    </>
  );
};

export default Navbar;
