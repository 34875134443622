import { makeStyles, Modal } from '@material-ui/core';
import axiosInstance from '../../config';
import React, { useEffect, useState, useRef } from 'react';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import qs from 'querystring';
import Edit from './functions/Edit';
import Setting from '../../components/Setting';
import Password from '../../components/Password';
import UserActionPwd from '../../components/UserActionPwd';
import CopySetting from './functions/CopySetting';
import Pagination from '@material-ui/lab/Pagination';
import { toast } from 'react-toastify';
import CreditLogM from './functions/CreditLogM';
import OperationLogM from './functions/OperationLogM';
import AgentUtils from '../../agentutils';
import DepWd from '../../components/DepWd';
import DatePicker from 'react-datepicker';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#bfbfbf',
  },
  borderSuspend: {
    backgroundColor: '#e1e1e1',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const Member = ({
  username,
  User,
  sort,
  asc,
  down,
  role,
  setAgent,
  searchName,
  searchUser,
  searchStatus,
  setTab,
  setTabm,
  tabm,
  backToParent,
}) => {
  const classes = useStyles();
  const [member, setMember] = useState(null);
  // modal
  const [open, setOpen] = useState(false);
  // currencies
  const [currency, setCurrency] = useState([]);
  // sort by
  const [Sort, setSort] = useState([]);
  // login id for popup
  const [ids, setId] = useState([]);
  // array for options
  const number = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  // copyFrom
  const [copy, setCopy] = useState([]);

  // modal for edit
  const [editModal, setEditModal] = useState(false);

  const [pageNo, setPageNo] = useState(1);
  // pagination
  const [totalPage, setTotalPage] = useState(null);
  // modal for password
  const [passwordModal, setPasswordModal] = useState(false);
  // modal for action
  const [actionModal, setActionModal] = useState(false);
  // modal for password
  const [actionType, setActionType] = useState(null);
  // modal for setting
  const [settingModal, setSettingModal] = useState(false);
  // modal for copy
  const [copyModal, setCopyModal] = useState(false);
  // modal for deposit
  const [selected, setSelected] = useState(null);
  const [depositType, setDepositType] = useState(null);
  const [depositModal, setDepositModal] = useState(false);
  // for passing value inside edit function also in password modal
  const editUsername = useRef('');
  // for passing user id in op log
  const memberId = useRef('');
  const [sumCredit, setSumCredit] = useState(0);
  const [sumBalance, setSumBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchDate, setSearchDate] = useState();
  const [maxDate, setMaxDate] = useState(null);

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  // UI component
  const renderDateSearch = (retBtn) => {
    return (
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={(e) => e.preventDefault()} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-1"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-1"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              // handleChange(null);
            }}
          >
            Get Report
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
          {retBtn ? (
            <button
              className="btn btn-sm btn-secondary px-3 py-1"
              onClick={() => {
                tabm === 0 ? backToParent() : setTabm(0);
              }}
            >
              Return
            </button>
          ) : null}
          {loading ? (
            <img
              src={require('../../img/Iphone-spinner-2.gif')}
              height={25}
              width={25}
              alt=""
            />
          ) : null}
        </form>
      </div>
    );
  };

  const handleCredit = (usr, usrname) => {
    console.log(usrname.length);
    if (usrname.length > 8) {
      setTabm(6);
    } else {
      setTabm(7);
    }
  };

  const sumCredBalance = (members) => {
    let sumC = 0;
    let sumB = 0;
    for (let i = 0; i < members.length; i++) {
      sumC += members[i].credit;
      sumB += members[i].availableBalance;
    }
    setSumCredit(sumC);
    setSumBalance(sumB);
  };

  // handle operation log
  const handleOpLog = () => {
    setTabm(2);
  };

  // handle memeber search
  const handleMemberSearch = (e, page) => {
    if (e != null) e.preventDefault();
    const memberForm = document.getElementById('member-form');
    const searchNameM = memberForm['member-name'].value;
    const searchUserM = memberForm['member-login'].value;
    const searchStatusM =
      memberForm['member-status'].value === '2'
        ? null
        : memberForm['member-status'].value;
    const searchSuspendM =
      memberForm['member-status'].value === '2' ? '1' : null;
    const sortm = memberForm['member-sort'].value;
    const ascm = memberForm['member-asc'].value;
    let pg = page;
    if (pg != null) setPageNo(page);
    else pg = pageNo;
    axiosInstance
      .get('agent/members', {
        params: {
          searchName: searchNameM !== '' ? searchNameM : null,
          searchUser: searchUserM !== '' ? searchUserM : null,
          searchStatus: searchStatusM !== '' ? searchStatusM : null,
          searchSuspend: searchSuspendM !== '' ? searchSuspendM : null,
          sort: sortm !== '' ? sortm : null,
          asc: ascm !== '' ? ascm : null,
          pageNo: isNaN(pg) ? null : pg - 1,
          agent: down ? down : null,
        },
      })
      .then((res) => {
        setMember(res.data.content);
        sumCredBalance(res.data.content);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  };

  // handle create member
  const handleMember = (e) => {
    e.preventDefault();
    setLoading(true);
    const form = document.getElementById('memberForm');
    const prefix = form['prefix'].value;
    const no1 = form['no1'].value;
    const no2 = form['no2'].value;
    const no3 = form['no3'].value;
    const mb_username = `${no1}${no2}${no3}`;
    const mb_name = form['name'].value;
    const password = form['pass'].value;
    const confirmPassword = form['cnfPass'].value;
    const myPassword = form['agentPass'].value;
    // const credit = form['credit'].value;
    const mb_copy = form['copyFrom'].value;
    const status = form['status'].value;
    const mobile = form['mobile'].value;
    const remarks = form['remark'].value;

    if (password !== confirmPassword) {
      setLoading(false);
      toast.error('Password and confirm password does not match!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return;
    }

    const requestBody = {
      prefix: prefix,
      username: mb_username,
      name: mb_name,
      password: password,
      mobile: mobile,
      copy: mb_copy,
      status: status,
      remarks: remarks,
      // credit: credit,
      myPassword: myPassword,
    };

    axiosInstance
      .post('agent/members', qs.stringify(requestBody))
      .then((res) => {
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // refreshing the memebers and closing the modal
        axiosInstance
          .get('/agent/members', {
            params: {
              agent: down != null ? down : null,
            },
          })
          .then((res) => {
            setMember(res.data.content);
            setTotalPage(res.data.totalPages);
            sumCredBalance(res.data.content);
          })
          .catch((err) => console.log(err));
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // on change of user update copy
  const handleCopy = (e) => {
    axiosInstance
      .get('agent/members/copies', {
        params: {
          agent: e.target.value,
        },
      })
      .then((res) => {
        setCopy(res.data);
        var no1 = document.getElementById('no1');
        var no2 = document.getElementById('no2');
        var no3 = document.getElementById('no3');
        for (var i = 0; i < no1.length; i++) {
          for (var j = 0; j < no2.length; j++) {
            if (no2[j].value !== '') {
              for (var k = 0; k < no3.length; k++) {
                if (no3[k].value !== '') {
                  let usr =
                    e.target.value + no1[i].value + no2[j].value + no3[k].value;
                  if (!res.data.find((el) => el.val === usr)) {
                    document.getElementById('no1').selectedIndex = i;
                    document.getElementById('no2').selectedIndex = j;
                    document.getElementById('no3').selectedIndex = k;
                    k = no3.length;
                    j = no2.length;
                    i = no1.length;
                  }
                }
              }
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  // modal handling
  const handleView = (e) => {
    if (down != null) {
      ids.push({ val: down });
      getUpline(down);
    } else {
      axiosInstance
        .get('agent/uplines')
        .then((res) => {
          setId(res.data);
          getUpline(res.data[0].val);
        })
        .catch((err) => console.log(err));
    }
    setOpen(true);
  };

  const getUpline = (fromUser) => {
    axiosInstance
      .get('agent/members/copies', {
        params: {
          agent: fromUser,
        },
      })
      .then((res) => {
        setCopy(res.data);
        var no1 = document.getElementById('no1');
        var no2 = document.getElementById('no2');
        var no3 = document.getElementById('no3');
        for (var i = 0; i < no1.length; i++) {
          for (var j = 0; j < no2.length; j++) {
            if (no2[j].value !== '') {
              for (var k = 0; k < no3.length; k++) {
                if (no3[k].value !== '') {
                  let usr =
                    document.getElementById('prefix').value +
                    no1[i].value +
                    no2[j].value +
                    no3[k].value;
                  if (!res.data.find((el) => el.val === usr)) {
                    document.getElementById('no1').selectedIndex = i;
                    document.getElementById('no2').selectedIndex = j;
                    document.getElementById('no3').selectedIndex = k;
                    k = no3.length;
                    j = no2.length;
                    i = no1.length;
                  }
                }
              }
            }
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    setOpen(false);
  };

  // load member when component mounts
  useEffect(() => {
    axiosInstance
      .get('/agent/members', {
        params: {
          searchName: searchName !== '' ? searchName : null,
          searchUser: searchUser !== '' ? searchUser : null,
          searchStatus: searchStatus !== '' ? searchStatus : null,
          sort: sort != null ? sort : null,
          agent: down != null ? down : null,
          asc: asc != null ? asc : null,
        },
      })
      .then((res) => {
        setMember(res.data.content);
        setTotalPage(res.data.totalPages);
        sumCredBalance(res.data.content);
      })
      .catch((err) => console.log(err));

    // currencies for new member addition
    axiosInstance
      .get('/agent/currencies')
      .then((res) => {
        setCurrency(res.data);
      })
      .catch((err) => console.log(err));
    // sort by option
    axiosInstance
      .get('/agent/sort ')
      .then((res) => {
        setSort(res.data);
      })
      .catch((err) => console.log(err));
    // getting users for popup modal
  }, [searchName, searchStatus, searchUser, asc, sort, role]);
  return (
    <>
      {/* {down != null ? null : <Navbar User={User} username={username} />} */}

      {member !== null && tabm === 0 ? (
        <>
          {/* {down == null ? ( */}
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form
              action=""
              className="form-inline"
              id="member-form"
              onSubmit={handleMemberSearch}
            >
              <label
                htmlFor="member-name"
                className="text-body font-weight-bold mx-2 ml-0"
              >
                Name{' '}
              </label>
              <input
                type="text"
                id="member-name"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="member-login"
                className="text-body font-weight-bold mx-2"
              >
                Login Id{' '}
              </label>
              <input
                type="text"
                id="member-login"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="member-status"
                className="text-body font-weight-bold mx-2"
              >
                Status{' '}
              </label>
              <select
                name=""
                id="member-status"
                className="form-control form-control-xs"
              >
                {/* <select name="" id="member-status" className="form-control form-control-xs"> */}
                <option value="">All</option>
                <option value={1}>Active</option>
                <option value={2}>Suspended</option>
                <option value={0}>InActive</option>
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label
                htmlFor="member-sort"
                className="text-body font-weight-bold mx-2"
              >
                Sort by{' '}
              </label>
              <select
                name=""
                id="member-sort"
                className="form-control form-control-xs"
              >
                {/* <option value="">None</option> */}
                {Sort.map((srt, index) => (
                  <option value={srt.key} key={index}>
                    {srt.val}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <select
                name=""
                id="member-asc"
                className="form-control form-control-xs mx-2"
              >
                {/* <option value="">None</option> */}
                <option value={1}>Asc</option>
                <option value={0}>Desc</option>
              </select>
              <input
                type="submit"
                value="Search"
                className="btn btn-sm btn-primary px-3 py-1 "
              />
              {/* &nbsp;&nbsp;&nbsp; */}
              {down != null &&
              down.length > 0 &&
              AgentUtils.getDownLevel(down) !== AgentUtils.getUrlRole() &&
              down !== sessionStorage.getItem('me') ? (
                <div className="table-responsive-sm mx-2">
                  <button
                    className="btn btn-sm btn-secondary px-3 py-1 mx-1"
                    onClick={() => {
                      setTab(0);
                      backToParent();
                    }}
                  >
                    Return
                  </button>
                </div>
              ) : null}
            </form>
            {User.privUser == null ||
            (User.privUser === true && User.privUserMember === true) ? (
              <button
                className="btn btn-sm btn-primary px-3 py-1 "
                onClick={handleView}
              >
                Create Member
              </button>
            ) : null}
          </div>
          <div className="table-responsive-sm mx-2">
            <table className="table table-sm table-hover table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th colSpan={10}>
                    {down
                      ? down + ' Member Listing'
                      : sessionStorage.getItem('me') + ' Member Listing'}
                  </th>
                </tr>
                <tr>
                  <th>No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Login Id</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Given Credit</th>
                  <th scope="col">Bet Credit</th>
                  <th scope="col">Last Login Time</th>
                  <th scope="col">Create Time</th>
                  <th scope="col">Status</th>
                  <th scope="col">Function</th>
                </tr>
              </thead>
              <tbody>
                {member.map((info, index) => (
                  <tr
                    className={`${
                      info.status === true
                        ? info.suspendAt
                          ? classes.borderSuspend
                          : ''
                        : classes.borderInactive
                    }`}
                    key={index}
                  >
                    <td className="text-dark text-center">{index + 1}</td>
                    <td className="text-dark">{info.name}</td>
                    <td className="text-dark text-center">{info.username}</td>
                    <td className="text-dark text-center">{info.currency}</td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.credit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-dark text-right">
                      <CurrencyFormat
                        value={info.availableBalance}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-dark text-center">
                      {info.lastLogin == null ? (
                        '-'
                      ) : (
                        <>
                          {moment(info.lastLogin).format('DD/MM/YYYY HH:mm:ss')}{' '}
                        </>
                      )}
                    </td>
                    <td className="text-dark text-center">
                      {moment(info.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    {info.status === true ? (
                      info.suspendAt ? (
                        <td className="text-danger text-center">Suspended</td>
                      ) : (
                        <td className="text-dark text-center">Active</td>
                      )
                    ) : (
                      <td className="text-danger text-center">InActive</td>
                    )}
                    <td className="text-primary">
                      {User.privUser == null ||
                      (User.privUser === true &&
                        User.privUserSetting === true) ? (
                        <>
                          <button
                            className="btn btn-link text-primary p-0 "
                            onClick={() => {
                              memberId.current = info.id;
                              editUsername.current = info.username;
                              setEditModal(true);
                            }}
                          >
                            Edit
                          </button>
                          /
                        </>
                      ) : null}
                      {User.privUser == null ||
                      (User.privUser === true &&
                        User.privUserSetting === true) ? (
                        <>
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              memberId.current = info.id;
                              editUsername.current = info.username;
                              setSettingModal(true);
                            }}
                          >
                            Setting
                          </button>
                          /
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              memberId.current = info.id;
                              editUsername.current = info.username;
                              setCopyModal(true);
                            }}
                          >
                            Copy Setting
                          </button>
                          /
                        </>
                      ) : null}
                      {User.privBalReport == null ||
                      (User.privBalReport === true &&
                        User.privBalReportDw === true) ? (
                        <>
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              setSelected(info.username);
                              setDepositType('DEPOSIT');
                              setDepositModal(true);
                            }}
                          >
                            Deposit
                          </button>
                          /
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              setSelected(info.username);
                              setDepositType('WITHDRAW');
                              setDepositModal(true);
                            }}
                          >
                            Withdraw
                          </button>
                          /
                        </>
                      ) : null}
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          memberId.current = info.id;
                          handleCredit(info.id, info.username);
                        }}
                      >
                        Cash log
                      </button>
                      /
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          memberId.current = info.id;
                          handleOpLog();
                        }}
                      >
                        Operation Log
                      </button>
                      {User.privUser == null ||
                      (User.privUser === true &&
                        User.privUserPassword === true) ? (
                        <>
                          /
                          <button
                            className="btn btn-sm btn-link text-primary p-0"
                            onClick={() => {
                              editUsername.current = info.username;
                              setPasswordModal(true);
                            }}
                          >
                            Password
                          </button>
                        </>
                      ) : null}
                      {User.privUser == null ||
                      (User.privUser === true && User.privUserEdit === true) ? (
                        <>
                          /
                          <button
                            className="btn btn-link text-primary p-0 "
                            onClick={() => {
                              setActionType('DELETE');
                              editUsername.current = info.username;
                              setActionModal(true);
                            }}
                          >
                            Delete
                          </button>
                        </>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="font-weight-bold">
                  <td className="text-dark text-center" colSpan="4">
                    Total
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumCredit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumBalance}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td colSpan="4"></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <Pagination
            count={totalPage}
            onChange={handleMemberSearch}
            className={classes.pagination}
            page={pageNo}
          />
          {/* modal */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modalStyle}
          >
            <div className={classes.Modal}>
              <form onSubmit={handleMember} id="memberForm">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        colSpan="2"
                        className="bg-primary text-light font-weight-bold"
                      >
                        New Member
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tableBody}>
                    <tr>
                      <td className="text-body font-weight-bold ">
                        Your Password
                      </td>
                      <td>
                        <input type="password" id="agentPass" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Login Id</td>
                      <td>
                        <select name="" id="prefix" onChange={handleCopy}>
                          {ids.map((id, index) => (
                            <option value={id.val} key={index}>
                              {id.val}
                            </option>
                          ))}
                        </select>
                        <select name="" className="mx-2" id="no1">
                          {number.map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select name="" className="mx-2" id="no2">
                          {number.map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                          <option value=""></option>
                        </select>
                        <select name="" className="mx-2" id="no3">
                          {number.map((option, index) => (
                            <option value={option} key={index}>
                              {option}
                            </option>
                          ))}
                          <option value=""></option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Name</td>
                      <td>
                        <input type="text" id="name" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Password</td>
                      <td>
                        <input type="password" id="pass" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">
                        Confirm Password
                      </td>
                      <td>
                        <input type="password" id="cnfPass" required />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Status</td>
                      <td>
                        <select name="" id="status">
                          <option value={1}>Active</option>
                          <option value={2}>InActive</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Currency</td>
                      <td>
                        <select name="" id="">
                          {currency.map((currency, i) => (
                            <option value="" key={i}>
                              {currency.val}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-body font-weight-bold ">Mobile</td>
                      <td>
                        <input type="tel" id="mobile" />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold ">Remark</td>
                      <td>
                        <textarea
                          name=""
                          id="remark"
                          cols="30"
                          rows="3"
                        ></textarea>
                      </td>
                    </tr>

                    <tr>
                      <td className="text-body font-weight-bold ">Copy From</td>
                      <td>
                        <select name="" id="copyFrom">
                          <option value="">None</option>
                          {copy.map((cpy, index) => (
                            <option value={cpy.val} key={index}>
                              {cpy.val}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <div className="d-flex justify-content-end">
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-sm btn-primary mx-3 px-3 py-1"
                            disabled={loading}
                          />

                          <button
                            className="btn btn-sm btn-danger px-3 py-1"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>
          </Modal>
        </>
      ) : tabm === 1 ? (
        <CreditLogM id={memberId.current} setTab={setTabm} User={User} />
      ) : tabm === 2 ? (
        <OperationLogM id={memberId.current} setTab={setTabm} User={User} />
      ) : tabm === 6 ? (
        <>
          {renderDateSearch(true)}
          <CreditLogM
            startDate={startDate}
            endDate={endDate}
            id={memberId.current}
            setTab={setTabm}
            tp={'members'}
            page={'cashLog'}
          />
        </>
      ) : null}

      {member !== null ? (
        <>
          {/* edit modal */}
          <Edit
            setMember={setMember}
            id={memberId.current}
            editModal={editModal}
            pageNo={pageNo}
            down={down}
            setPageNo={setPageNo}
            setEditModal={setEditModal}
            givenRole="Member"
            copy={copy}
            username={editUsername.current}
            handleMemberSearch={handleMemberSearch}
          />
          {/* password modal */}
          <Password
            passwordModal={passwordModal}
            setPasswordModal={setPasswordModal}
            username={editUsername.current}
          ></Password>
          {/* setting modal */}
          <Setting
            setSettingModal={setSettingModal}
            settingModal={settingModal}
            id={memberId.current}
            username={editUsername.current}
          />
          {/* copy setting modal */}
          <CopySetting
            copy={copy}
            copyModal={copyModal}
            setCopyModal={setCopyModal}
            username={editUsername.current}
            id={memberId.current}
            setMember={setMember}
            down={down}
            handleMemberSearch={handleMemberSearch}
          />
          <UserActionPwd
            passwordModal={actionModal}
            setPasswordModal={setActionModal}
            username={editUsername.current}
            type={actionType}
            handleRoleSearch={handleMemberSearch}
          ></UserActionPwd>
          <DepWd
            type={depositType}
            depositModal={depositModal}
            setDepositModal={setDepositModal}
            username={selected}
            refresh={handleMemberSearch}
          />
        </>
      ) : null}
    </>
  );
};

export default Member;
