import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import BetList from '../../components/BetList';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import AgentUtils from '../../agentutils';

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'pointer',
  },
}));

const WlDetail = ({ summaryView, type, out, User }) => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [resp, setResp] = useState(null);
  const [reports, setReports] = useState([]);
  const [bets, setBets] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const [searchUsername, setSearchUsername] = useState(null);
  const [searchClick, setSearchClick] = useState(null);
  const [sumWager, setSumWager] = useState(0);
  const [sumTurnover, setSumTurnover] = useState(0);
  const [sumValid, setSumValid] = useState(0);
  const [sumPAgent, setSumPAgent] = useState(0);
  const [sumPMaster, setSumPMaster] = useState(0);
  const [sumPSenior, setSumPSenior] = useState(0);
  const [sumPVendor, setSumPVendor] = useState(0);
  const [sumPCompany, setSumPCompany] = useState(0);
  const [sumPUpline, setSumPUpline] = useState(0);
  const [sumWlAgent, setSumWlAgent] = useState(0);
  const [sumWlMaster, setSumWlMaster] = useState(0);
  const [sumWlSenior, setSumWlSenior] = useState(0);
  const [sumWlVendor, setSumWlVendor] = useState(0);
  const [sumWlCompany, setSumWlCompany] = useState(0);
  const [sumWlUpline, setSumWlUpline] = useState(0);
  const [sumCommPlayer, setSumCommPlayer] = useState(0);
  const [sumCommAgent, setSumCommAgent] = useState(0);
  const [sumCommMaster, setSumCommMaster] = useState(0);
  const [sumCommSenior, setSumCommSenior] = useState(0);
  const [sumCommVendor, setSumCommVendor] = useState(0);
  const [sumCommCompany, setSumCommCompany] = useState(0);
  const [sumCommGross, setSumCommGross] = useState(0);
  const [sumCommUpline, setSumCommUpline] = useState(0);
  const [sumWlPlayer, setSumWlPlayer] = useState(0);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [playerTurnover, setPlayerTurnover] = useState(0);
  const [playerComm, setPlayerComm] = useState(0);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [searchAp, setSearchAp] = useState(null);
  const [searchMp, setSearchMp] = useState(null);
  const [searchSp, setSearchSp] = useState(null);
  const [searchVp, setSearchVp] = useState(null);
  const [searchCp, setSearchCp] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [searchParlay, setSearchParlay] = useState(null);
  const [searchTO, setSearchTO] = useState(null);
  const [loading, setLoading] = useState(false);
  const [producers , setProducers] = useState();
  const [producerPubId , setProducerPubId] = useState(null);
  const [validDateRange , setValidDateRange] = useState(true)
  const history = useHistory();
  const handleSearch = () => {
    axiosInstance``
      .get('agent/wlDetail', {
        params: {
          username: username ? username : null,
          searchUsername: searchUsername ? searchUsername : null,
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
        },
      })
      .then((res) => {
        setReports(res.data);
        calculateSum(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const calculateSum = (report) => {
    let wager = 0;
    let turnover = 0;
    let valid = 0;
    // let stakeAgent = 0, stakeMaster = 0, stakeSenior = 0, stakeVendor = 0, stakeCompany = 0;
    let pAgent = 0,
      pMaster = 0,
      pSenior = 0,
      pVendor = 0,
      pCompany = 0,
      pUpline = 0;
    let wlAgent = 0,
      wlMaster = 0,
      wlSenior = 0,
      wlVendor = 0,
      wlCompany = 0,
      wlPlayer = 0,
      wlUpline = 0;
    let commAgent = 0,
      commMaster = 0,
      commSenior = 0,
      commVendor = 0,
      commCompany = 0,
      commUpline = 0,
      commPlayer = 0;
    let commGross = 0;

    for (var i = 0; i < report.length; i++) {
      wager += report[i].wager;
      turnover += report[i].amount;
      valid += report[i].turnover;
      // if (report[i].playerWin) wlPlayer += report[i].playerWin;
      // if (report[i].agentContra) stakeAgent += report[i].agentContra;
      // if (report[i].masterContra) stakeMaster += report[i].masterContra;
      // if (report[i].seniorContra) stakeSenior += report[i].seniorContra;
      // if (report[i].vendorContra) stakeVendor += report[i].vendorContra;
      // if (report[i].companyContra) stakeCompany += report[i].companyContra;
      if (report[i].playerWin) wlPlayer += report[i].playerWin;
      if (report[i].agentWin) wlAgent += report[i].agentWin;
      if (report[i].masterWin) wlMaster += report[i].masterWin;
      if (report[i].seniorWin) wlSenior += report[i].seniorWin;
      if (report[i].vendorWin) wlVendor += report[i].vendorWin;
      if (report[i].companyWin) wlCompany += report[i].companyWin;
      if (report[i].playerCom) commPlayer += report[i].playerCom;
      if (report[i].agentCom) commAgent += report[i].agentCom;
      if (report[i].masterCom) commMaster += report[i].masterCom;
      if (report[i].seniorCom) commSenior += report[i].seniorCom;
      if (report[i].vendorCom) commVendor += report[i].vendorCom;
      if (report[i].companyCom) commCompany += report[i].companyCom;
      if (report[i].grossCom) commGross += report[i].grossCom;
      if (report[i].uplineWin) wlUpline += report[i].uplineWin;
      if (report[i].uplineCom) commUpline += report[i].uplineCom;
      if (report[i].companyPercentage) pCompany += report[i].companyPercentage;
      if (report[i].vendorPercentage) pVendor += report[i].vendorPercentage;
      if (report[i].seniorPercentage) pSenior += report[i].seniorPercentage;
      if (report[i].masterPercentage) pMaster += report[i].masterPercentage;
      if (report[i].agentPercentage) pAgent += report[i].agentPercentage;
      if (report[i].uplinePercentage) pUpline += report[i].uplinePercentage;
    }
    setSumWager(wager);
    setSumTurnover(turnover);
    setSumValid(valid);
    setSumWlAgent(wlAgent);
    setSumWlMaster(wlMaster);
    setSumWlSenior(wlSenior);
    setSumWlVendor(wlVendor);
    setSumWlCompany(wlCompany);
    setSumWlUpline(wlUpline);
    setSumPAgent(pAgent);
    setSumPMaster(pMaster);
    setSumPSenior(pSenior);
    setSumPVendor(pVendor);
    setSumPCompany(pCompany);
    setSumPUpline(pUpline);
    setSumCommPlayer(commPlayer);
    setSumCommAgent(commAgent);
    setSumCommMaster(commMaster);
    setSumCommSenior(commSenior);
    setSumCommVendor(commVendor);
    setSumCommCompany(commCompany);
    setSumCommUpline(commUpline);
    setSumCommGross(commGross);
    setSumWlPlayer(wlPlayer);
  };

  const calculateBet = (report) => {
    let wager = 0;
    let turnover = 0;
    let comm = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning += report[i].winning;
      turnover += report[i].turnover;
      comm += report[i].commission;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning + comm);
    setPlayerTurnover(turnover);
    setPlayerComm(comm);
  };

  const calculateBetOut = (report) => {
    let wager = 0;
    let turnover = 0;
    let comm = 0;
    // let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      // if (report[i].winning) winning+= report[i].winning;
      if (report[i].turnover) turnover += report[i].turnover;
      if (report[i].grossComm) comm += report[i].grossComm;
    }
    setPlayerWager(wager);
    setPlayerWinning(0);
    setPlayerTurnover(turnover);
    setPlayerComm(comm);
  };

  const clearForm = () => {
    setBets([]);
    if (searchParlay === null) setSearchType(null);
    setSearchTO(null);
    setSearchParlay(null);
    document.getElementById('member-login').value = '';
    setSearchUsername(null);
  };

  const backToParent = () => {
    clearForm();
    if (
      username != null &&
      username.length > 0 &&
      username !== sessionStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };

  // main function
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    let url = 'agent/wlDetail';
    if (type != null && type === 'Y' && out != null && out === 'O')
      url = 'agent/outstandingType';
    else if (type != null && type === 'Y') {
      url = 'agent/wlType';
      if (
        document.getElementById('searchTp') &&
        document.getElementById('searchTp').value === 'match'
      )
        url = 'agent/wlMatch';
    } else if (out != null && out === 'O') url = 'agent/outstanding';
    if (searchParlay != null) {
      let pUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') pUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(pUrl, {
          params: {
            username: username ? username : sessionStorage.getItem('me'),
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            parlay: searchParlay ? searchParlay : null,
            wc: true,
            pageNo: isNaN(value) ? null : value - 1,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
            searchPlayer: document.getElementById('username')
              ? document.getElementById('username').value
              : null,
            searchWinLose: document.getElementById('resultvalue')
              ? document.getElementById('resultvalue').value
              : null,
            searchProducerPubId : producerPubId ? producerPubId : null
          },
        })
        .then((res) => {
          setBets(res.data.content);
          if (out != null && out === 'O') calculateBetOut(res.data.content);
          else calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (searchTO != null) {
      let toUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') toUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(toUrl, {
          params: {
            username: searchTO,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            wc: true,
            agent: searchAp ? searchAp : null,
            master: searchTO.length != 6 && searchMp ? searchMp : null,
            senior: searchTO.length != 4 && searchSp ? searchSp : null,
            vendor: searchTO.length != 2 && searchVp ? searchVp : null,
            company: searchCp ? searchCp : null,
            pageNo: isNaN(value) ? null : value - 1,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
            searchPlayer: document.getElementById('username')
              ? document.getElementById('username').value
              : null,
            searchWinLose: document.getElementById('resultvalue')
              ? document.getElementById('resultvalue').value
              : null,
            searchProducerPubId: producerPubId ? producerPubId : null,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          if (out != null && out === 'O') calculateBetOut(res.data.content);
          else calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (
      searchType === null &&
      (username == null || username.length <= 8)
    ) {
      setLoading(true);
      axiosInstance
        .get(url, {
          params: {
            username: username
              ? username === 'COMPANY'
                ? null
                : username
              : null,
            searchUsername: searchUsername ? searchUsername : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            summary: summaryView != null ? summaryView : null,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
            gameCategoryFilter: document.getElementById('gmSts')
              ? document.getElementById('gmSts').value
              : '',
            searchProducerPubId: producerPubId,
          },
        })
        .then((res) => {
          setResp(res.data);
          setReports(res.data.content);
          calculateSum(res.data.content);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (searchType != null && searchType === 'PARLAY') {
      let typeParlayUrl = 'agent/wlParlay';
      if (out != null && out === 'O') typeParlayUrl = 'agent/outstandingParlay';
      axiosInstance
        .get(typeParlayUrl, {
          params: {
            username: username
              ? username === 'COMPANY'
                ? null
                : username
              : null,
            searchUsername: searchUsername ? searchUsername : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            summary: summaryView != null ? summaryView : null,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
          },
        })
        .then((res) => {
          setResp(res.data);
          setReports(res.data.content);
          calculateSum(res.data.content);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else if (searchType != null && searchType !== 'PARLAY') {
      let typeUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') typeUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(typeUrl, {
          params: {
            username: username ? username : sessionStorage.getItem('me'),
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            game: searchType,
            wc: true,
            pageNo: isNaN(value) ? null : value - 1,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
            searchProducerPubId: producerPubId ? producerPubId : null,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          if (out != null && out === 'O') calculateBetOut(res.data.content);
          else calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else {
      if (value == null) value = 1;
      let betUrl = 'agent/wlDetails/bets';
      if (out != null && out === 'O') betUrl = 'agent/outstanding/bets';
      axiosInstance
        .get(betUrl, {
          params: {
            username: username ? username : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            agent: searchAp ? searchAp : null,
            master: username.length != 6 && searchMp ? searchMp : null,
            senior: username.length != 4 && searchSp ? searchSp : null,
            vendor: username.length != 2 && searchVp ? searchVp : null,
            company: searchCp ? searchCp : null,
            pageNo: isNaN(value) ? null : value - 1,
            searchProducerPubId: producerPubId ? producerPubId : null,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          if (out != null && out === 'O') calculateBetOut(res.data.content);
          else calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMemberHeader = () => {
    return (
      <>
        <th scope="col">Win/Lose</th>
        <th scope="col">Comm</th>
        <th scope="col">Total W/L</th>
      </>
    );
  };

  const getAgentHeader = () => {
    return (
      <>
        <th scope="col">{type === 'Y' ? 'Turnover' : '%'}</th>
        <th scope="col">Win/Lose</th>
        <th scope="col">Comm</th>
        <th scope="col">Total W/L</th>
      </>
    );
  };
  const generateRow = (percent, wl, com, sm) => {
    return (
      <>
        {percent != null && type === 'Y' ? (
          <td className={`text-right${percent < 0 ? ' text-danger' : ''}`}>
            <CurrencyFormat
              value={percent}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : percent != null ? (
          <td
            className={`text-right text-info${
              percent < 0 ? ' text-danger' : ''
            }`}
          >
            <CurrencyFormat
              value={percent}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : null}
        {wl != null ? (
          <td className={`text-right${wl < 0 ? ' text-danger' : ''}`}>
            <CurrencyFormat
              value={wl ? wl.toFixed(2): 0}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : null}
        {com != null ? (
          <td className={`text-right${com < 0 ? ' text-danger' : ''}`}>
            <CurrencyFormat
              value={com}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : null}
        {sm ? (
          <td className={`text-right${wl + com < 0 ? ' text-danger' : ''}`}>
            <CurrencyFormat
              value={(wl + com).toFixed(2)}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : null}
      </>
    );
  };

  const generateRowUpline = (percent, wl, com) => {
    return (
      <>
        <td className={`text-right${wl + com < 0 ? ' text-danger' : ''}`}>
          <CurrencyFormat
            value={wl + com}
            decimalScale={2}
            displayType={'text'}
            thousandSeparator={true}
            fixedDecimalScale={true}
          />
        </td>
      </>
    );
  };

  const moneyTd = (amt) => {
    return (
      <td className={`text-right${amt < 0 ? ' text-danger' : ''}`}>
        <CurrencyFormat
          value={amt}
          decimalScale={2}
          displayType={'text'}
          thousandSeparator={true}
          fixedDecimalScale={true}
        />
      </td>
    );
  };
  useEffect(() => {
    setSearchClick(false);
    if (username == null) {
      // setUsername(localStorage.getItem('me'));
      // setLevel(localStorage.getItem('role'));
    }
    // if (startDate == null || endDate == null) {
    //   axiosInstance
    //     .get('public/today')
    //     .then((res) => {
    //       if (startDate == null)  setStartDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
    //       if (endDate == null)  setEndDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
    //       setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
    //       let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
    //       minSearchDate.setDate(minSearchDate.getDate() - 90);
    //       setSearchDate(minSearchDate);
    //   });
    // }
    if (startDate != null && endDate != null && endDate >= startDate)
      handleChange(null);
  }, [username, searchType, startDate, endDate, searchTO, searchParlay , producerPubId]);

  useEffect(() => {
    axiosInstance.get('public/today').then((res) => {
      if (startDate == null)
        setStartDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      if (endDate == null)
        setEndDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
    setSearchTO(null);
    setSearchType(null);
    handleChange(null);
  }, [history.location]);

  useEffect(() => {
    axiosInstance.get('agent/selectProducer').then(res => {
      setProducers(res.data)
    })
  } , [])
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={(e) => e.preventDefault()} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => {
              if (date < new Date('2023-09-01')) {
                setValidDateRange(false);
                setProducerPubId('');
                setStartDate(date);
              } else {
                setValidDateRange(true);
                setStartDate(date);
              }
            }}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          {document.getElementById('searchTp') &&
          document.getElementById('searchTp').value === 'match' ? (
            ''
          ) : (
            <>
              End Date:
              <DatePicker
                selected={endDate ? endDate : new Date()}
                onChange={(date) => setEndDate(date)}
                className="mx-2"
                dateFormat="d MMM yyyy"
                minDate={startDate}
                maxDate={maxDate}
              />
            </>
          )}
          {producers && validDateRange && (
            <>
              <label htmlFor="producer">Producer : </label>
              <select
                name="producer"
                id="producerPubId"
                className="mx-2"
                onChange={(e) => {
                  console.log(e.target.value);
                  setProducerPubId(e.target.value);
                }}
              >
                <option value="" key={1}>
                  ALL
                </option>
                {producers.map((producer, index) => (
                  <>
                    <option value={producer.key} key={producer.key}>
                      {producer.val}
                    </option>
                  </>
                ))}
              </select>
            </>
          )}
          {AgentUtils.getCurrentLevel(sessionStorage.getItem('me')) ===
          'Company' ? (
            <>
              <input
                type="checkbox"
                id="matchDateCb"
                onChange={() => {
                  handleChange(null);
                }}
              />
              Match Date&nbsp;
            </>
          ) : null}
          {type != null && searchType !== 'PARLAY' ? (
            <>
              <select name="sts" id="gmSts" onChange={() => handleChange(null)}>
                <option value="ALL">All</option>
                <option value="RUNNING">Running</option>
                <option value="NONRUNNINGPARLAY">Non-Running</option>
                <option value="NONRUNNING">Today/Early Single</option>
                <option value="PARLAY">Parlay/All Up</option>
              </select>
              &nbsp;
              <select
                name="searchTp"
                id="searchTp"
                onChange={() => handleChange(null)}
              >
                <option value="type">Type</option>
                {out !== 'O' ? <option value="match">Match</option> : null}
              </select>
              &nbsp;
            </>
          ) : null}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              handleChange(null);
            }}
          >
            Get Report
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
          {/* Login ID{' '} */}
          <input
            type="hidden"
            id="member-login"
            value={searchUsername}
            onChange={(event) => setSearchUsername(event.target.value)}
          />
          {/* &nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              setSearchClick(true);
            }} */}
          {/* /> */}
          {loading ? (
            <>
              <br />
              <img
                src={require('../../img/loadingAnimation.gif')}
                height={13}
                width={208}
                alt=""
              />
            </>
          ) : null}
        </form>
      </div>
      {resp !== null ? (
        <div className="table-responsive-sm mx-2">
          {(username != null &&
            username.length > 0 &&
            username !== sessionStorage.getItem('me')) ||
          searchType != null ||
          searchTO != null ? (
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
          ) : null}
          {(searchType === null ||
            (searchType === 'PARLAY' && searchParlay === null)) &&
          searchTO === null &&
          (username == null || username.length <= 8) ? (
            <>
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    {type !== 'Y' ? (
                      <th scope="col" colSpan={30}>
                        {username
                          ? ' ' + username
                          : ' ' + sessionStorage.getItem('me')}{' '}
                        {AgentUtils.getCurrentLevel(
                          username != null && username.length > 0
                            ? username
                            : sessionStorage.getItem('me')
                        )}{' '}
                        Report [
                        {moment(startDate).format('DD/MMM/YYYY') +
                          ' - ' +
                          moment(endDate).format('DD/MMM/YYYY')}
                        ]
                      </th>
                    ) : type === 'Y' &&
                      document.getElementById('searchTp') &&
                      document.getElementById('searchTp').value === 'match' ? (
                      <th scope="col" colSpan={30}>
                        Match Report [{moment(startDate).format('DD/MMM/YYYY')}]
                      </th>
                    ) : (
                      <th scope="col" colSpan={30}>
                        Type Report [
                        {moment(startDate).format('DD/MMM/YYYY') +
                          ' - ' +
                          moment(endDate).format('DD/MMM/YYYY')}
                        ]
                      </th>
                    )}
                  </tr>
                  <tr>
                    {type === 'Y' ? (
                      <th scope="col" rowSpan={2} colSpan={2}>
                        Type
                      </th>
                    ) : (
                      <>
                        <th scope="col" rowSpan={2}>
                          Name
                        </th>
                        <th scope="col" rowSpan={2}>
                          Login
                          <br />
                          ID
                        </th>
                      </>
                    )}

                    <th scope="col" rowSpan={2}>
                      Currency
                    </th>
                    <th scope="col" rowSpan={2}>
                      Wager
                    </th>
                    <th scope="col" rowSpan={2}>
                      Turn Over
                    </th>
                    <th scope="col" rowSpan={2}>
                      Valid
                      <br />
                      Turn Over
                    </th>
                    {AgentUtils.getCurrentLevel(
                      sessionStorage.getItem('me')
                    ) === 'Company' ||
                    AgentUtils.getCurrentLevel(sessionStorage.getItem('me')) ===
                      'Vendor' ? (
                      <th scope="col" rowSpan={2}>
                        Gross
                        <br />
                        Comm
                      </th>
                    ) : null}
                    {resp.member || type != null ? (
                      <th scope="col" colSpan={3}>
                        Member
                      </th>
                    ) : null}
                    {resp.agent ? (
                      <th scope="col" colSpan={4}>
                        Agent
                      </th>
                    ) : null}
                    {resp.master ? (
                      <th scope="col" colSpan={4}>
                        Master Agent
                      </th>
                    ) : null}
                    {resp.senior ? (
                      <th scope="col" colSpan={4}>
                        Senior Agent
                      </th>
                    ) : null}
                    {resp.vendor ? (
                      <th scope="col" colSpan={4}>
                        Vendor
                      </th>
                    ) : null}
                    <th
                      scope="col"
                      colSpan={
                        resp.company || type === 'Y' || summaryView === 'N'
                          ? 4
                          : 1
                      }
                      rowSpan={
                        resp.company || type === 'Y' || summaryView === 'N'
                          ? 1
                          : 2
                      }
                    >
                      Company
                    </th>
                  </tr>
                  <tr>
                    {resp.member || type != null ? getMemberHeader() : null}
                    {resp.agent ? getAgentHeader() : null}
                    {resp.master ? getAgentHeader() : null}
                    {resp.senior ? getAgentHeader() : null}
                    {resp.vendor ? getAgentHeader() : null}
                    {resp.company || type === 'Y' || summaryView === 'N'
                      ? getAgentHeader()
                      : null}
                  </tr>
                </thead>
                {reports.length > 0 && loading !== true ? (
                  <tbody>
                    {reports.map((info, index) => (
                      <tr key={index}>
                        {type === 'Y' ? (
                          <td colSpan={2}>
                            {searchType === 'PARLAY' ? (
                              <button
                                className="btn btn-link text-primary p-0 "
                                onClick={() => {
                                  setSearchParlay(info.matches);
                                }}
                              >
                                {info.matches} Matches
                              </button>
                            ) : info.teamHome ? (
                              <>{info.teamHome + ' vs ' + info.teamAway}</>
                            ) : (
                              <button
                                className="btn btn-link text-primary p-0 "
                                onClick={() => {
                                  setSearchType(info.code);
                                }}
                              >
                                {info.name}
                              </button>
                            )}
                          </td>
                        ) : (
                          <>
                            <td className="text-center">{info.name}</td>
                            <td className="text-center">
                              <a
                                className={`${classes.pointer} text-primary p-0`}
                                onClick={() => {
                                  setUsername(info.username);
                                  if (info.username.length > 8) {
                                    if (info.agentPercentage != null)
                                      setSearchAp(info.agentPercentage);
                                    else setSearchAp(null);
                                    if (info.masterPercentage != null)
                                      setSearchMp(info.masterPercentage);
                                    else setSearchMp(null);
                                    if (info.seniorPercentage != null)
                                      setSearchSp(info.seniorPercentage);
                                    else setSearchSp(null);
                                    if (info.vendorPercentage != null)
                                      setSearchVp(info.vendorPercentage);
                                    else setSearchVp(null);
                                    if (info.companyPercentage != null)
                                      setSearchCp(info.companyPercentage);
                                    else setSearchCp(null);
                                  }
                                  clearForm();
                                }}
                              >
                                {info.username}
                              </a>
                            </td>
                          </>
                        )}
                        <td className="text-center">{info.currency}</td>
                        <td className="text-center">{info.wager}</td>
                        {type === 'Y' ? (
                          moneyTd(info.amount)
                        ) : (
                          <td
                            className={`text-right${
                              info.amount < 0 ? ' text-danger' : ''
                            }`}
                          >
                            <a
                              className={`${classes.pointer} text-primary p-0`}
                              onClick={() => {
                                setUsername(info.username);
                                if (info.agentPercentage != null)
                                  setSearchAp(info.agentPercentage);
                                else setSearchAp(null);
                                if (info.masterPercentage != null)
                                  setSearchMp(info.masterPercentage);
                                else setSearchMp(null);
                                if (info.seniorPercentage != null)
                                  setSearchSp(info.seniorPercentage);
                                else setSearchSp(null);
                                if (info.vendorPercentage != null)
                                  setSearchVp(info.vendorPercentage);
                                else setSearchVp(null);
                                if (info.companyPercentage != null)
                                  setSearchCp(info.companyPercentage);
                                else setSearchCp(null);
                                setSearchTO(info.username);
                                // setSearchType(info.code);
                              }}
                            >
                              <CurrencyFormat
                                value={info.amount}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </a>
                          </td>
                        )}
                        {moneyTd(info.turnover)}
                        {AgentUtils.getCurrentLevel(
                          sessionStorage.getItem('me')
                        ) === 'Company' ||
                        AgentUtils.getCurrentLevel(
                          sessionStorage.getItem('me')
                        ) === 'Vendor'
                          ? moneyTd(info.grossCom)
                          : null}
                        {resp.member || type != null
                          ? generateRow(null, info.playerWin, info.playerCom, 1)
                          : null}
                        {resp.agent
                          ? generateRow(
                              info.agentPercentage,
                              info.agentWin,
                              info.agentCom,
                              1
                            )
                          : null}
                        {resp.master
                          ? generateRow(
                              info.masterPercentage,
                              info.masterWin,
                              info.masterCom,
                              1
                            )
                          : null}
                        {resp.senior
                          ? generateRow(
                              info.seniorPercentage,
                              info.seniorWin,
                              info.seniorCom,
                              1
                            )
                          : null}
                        {resp.vendor
                          ? generateRow(
                              info.vendorPercentage,
                              info.vendorWin,
                              info.vendorCom,
                              1
                            )
                          : null}
                        {resp.company
                          ? generateRow(
                              info.companyPercentage,
                              info.companyWin,
                              info.companyCom,
                              1
                            )
                          : type === 'Y' || summaryView === 'N'
                          ? generateRow(
                              info.uplinePercentage,
                              info.uplineWin,
                              info.uplineCom,
                              1
                            )
                          : generateRowUpline(
                              info.uplinePercentage,
                              info.uplineWin,
                              info.uplineCom
                            )}
                      </tr>
                    ))}
                  </tbody>
                ) : null}
                <tfoot>
                  <tr className="font-weight-bold">
                    <td className="text-center" colSpan="3">
                      Total
                    </td>
                    <td className={`text-center`}>{sumWager}</td>
                    {moneyTd(sumTurnover)}
                    {moneyTd(sumValid)}
                    {AgentUtils.getCurrentLevel(
                      sessionStorage.getItem('me')
                    ) === 'Company' ||
                    AgentUtils.getCurrentLevel(sessionStorage.getItem('me')) ===
                      'Vendor'
                      ? moneyTd(sumCommGross)
                      : null}
                    {resp.member || type != null
                      ? generateRow(null, sumWlPlayer, sumCommPlayer, 1)
                      : null}
                    {resp.agent ? (
                      <>
                        {type === 'Y' ? (
                          moneyTd(sumPAgent)
                        ) : (
                          <td className={`text-right`}> </td>
                        )}
                        {generateRow(null, sumWlAgent, sumCommAgent, 1)}
                      </>
                    ) : null}
                    {resp.master ? (
                      <>
                        {type === 'Y' ? (
                          moneyTd(sumPMaster)
                        ) : (
                          <td className={`text-right`}> </td>
                        )}
                        {generateRow(null, sumWlMaster, sumCommMaster, 1)}
                      </>
                    ) : null}
                    {resp.senior ? (
                      <>
                        {type === 'Y' ? (
                          moneyTd(sumPSenior)
                        ) : (
                          <td className={`text-right`}> </td>
                        )}
                        {generateRow(null, sumWlSenior, sumCommSenior, 1)}
                      </>
                    ) : null}
                    {resp.vendor ? (
                      <>
                        {type === 'Y' ? (
                          moneyTd(sumPVendor)
                        ) : (
                          <td className={`text-right`}> </td>
                        )}
                        {generateRow(null, sumWlVendor, sumCommVendor, 1)}
                      </>
                    ) : null}
                    {resp.company ? (
                      <>
                        {type === 'Y' ? (
                          moneyTd(sumPCompany)
                        ) : (
                          <td className={`text-right`}> </td>
                        )}
                        {generateRow(null, sumWlCompany, sumCommCompany, 1)}
                      </>
                    ) : (
                      <>
                        {type === 'Y' || summaryView === 'N' ? (
                          <>
                            {type === 'Y' ? (
                              moneyTd(sumPUpline)
                            ) : (
                              <td className={`text-right`}> </td>
                            )}
                            {generateRow(null, sumWlUpline, sumCommUpline, 1)}
                          </>
                        ) : (
                          generateRowUpline(null, sumWlUpline, sumCommUpline)
                        )}
                      </>
                    )}
                  </tr>
                </tfoot>
              </table>
            </>
          ) : (
            <>
              <BetList
                detailType={username}
                startDate={startDate}
                endDate={endDate}
                bets={bets}
                handleChange={handleChange}
                viewDetail={handleChange}
                playerWager={playerWager}
                playerWinning={playerWinning}
                totalComm={playerComm}
                totalTurnover={playerTurnover}
                totalPage={totalPage}
                agent={'Y'}
                type={out === 'O' ? 'OUTSTANDING' : ''}
                username={User.username}
                searchTO={searchTO}
                searchAp={searchAp}
                searchCp={searchCp}
                searchMp={searchMp}
                searchSp={searchSp}
                searchVp={searchVp}
                producerPubId={producerPubId}
              />
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default WlDetail;
