import React, {useEffect, useState} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const CreateSub = ({
  open,
  setOpen,
  handleRoleSearch,
  type,
  user,
  resetForm,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [subagent, setSubagent] = useState(null);

  const handleCreate = (e) => {
    e.preventDefault();
    if (subagent != null){
      const form = document.getElementById('create-form');
      const name = form['create-name'].value;
      const myPassword = form['agentPass'].value;
      const status = form['create-status'].value;
      const mobile = form['create-mobile'].value;
      const remarks = form['create-remark'].value;
      const balReport = form['balReportCb'].checked;
      const report = form['reportCb'].checked;
      const usr = form['userCb'].checked;
      const requestBody = {
        name: name,
        mobile: mobile,
        status: status,
        remarks: remarks,
        myPassword: myPassword,
        privReport: report,
        privBalReport: balReport,
        privBalReportDw: form['balReportCb'].checked && form['dwCb'].checked,
        privUser: usr,
        privUserAgent: form['userCb'].checked && form['newAgentCb'].checked,
        privUserMember: form['userCb'].checked && form['newMemberCb'].checked,
        privUserEdit: form['userCb'].checked && form['editCb'].checked,
        privUserSetting: form['userCb'].checked && form['settingCb'].checked,
        privUserShare: form['userCb'].checked && form['shareCb'].checked,
        privUserPassword: form['userCb'].checked && form['passwordCb'].checked,
      };
      axiosInstance
        .patch('agent/subagents/'+subagent.id, qs.stringify(requestBody))
        .then((res) => {
          // refreshing the agent and closing the modal
          handleClose();
          handleRoleSearch(null);
        })
        .catch((err) => {
          if (err.response && err.response.data.message === 'AG200'){
            toast.error('Invalid password!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              });
          }
          else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
    else {
      const form = document.getElementById('create-form');
      const username = form['sub-user-name'].value;
      const name = form['create-name'].value;
      const password = form['create-pass'].value;
      const passwordCfm = form['confirm-pass'].value;
      const myPassword = form['agentPass'].value;
      const status = form['create-status'].value;
      const mobile = form['create-mobile'].value;
      const remarks = form['create-remark'].value;
      const balReport = form['balReportCb'].checked;
      const report = form['reportCb'].checked;
      const usr = form['userCb'].checked;
      if (password !== passwordCfm){
        toast.error('Password and confirm password does not match!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          });
        return;
      }

      const requestBody = {
        username: username,
        name: name,
        password: password,
        mobile: mobile,
        status: status,
        remarks: remarks,
        myPassword: myPassword,
        privReport: report,
        privBalReport: balReport,
        privBalReportDw: form['balReportCb'].checked && form['dwCb'].checked,
        privUser: usr,
        privUserAgent: form['userCb'].checked && form['newAgentCb'].checked,
        privUserMember: form['userCb'].checked && form['newMemberCb'].checked,
        privUserEdit: form['userCb'].checked && form['editCb'].checked,
        privUserSetting: form['userCb'].checked && form['settingCb'].checked,
        privUserShare: form['userCb'].checked && form['shareCb'].checked,
        privUserPassword: form['userCb'].checked && form['passwordCb'].checked,
      };

      axiosInstance
        .post('agent/subagents', qs.stringify(requestBody))
        .then((res) => {
          console.log(res.data);
          // refreshing the agent and closing the modal
          handleClose();
          handleRoleSearch(null);
        })
        .catch((err) => {
          if (err.response && err.response.data.message === 'AG200'){
            toast.error('Invalid password!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              });
          }
          else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  // closing the modal
  const handleClose = () => {
    setSubagent(null);
    resetForm();
  };

  useEffect(() => {
    if (open && type === 'U') {
      // getting prefix for popup modal
      axiosInstance
        .get('agent/subagents/'+user)
        .then((res) => {
          console.log(res.data);
          setSubagent(res.data);
          const form = document.getElementById('create-form');
          form['create-name'].value = res.data.name;
          if (res.data.status === true){
            form['create-status'].value = '1';
          }
          // form['create-status'].value = res.data.status;
          form['create-mobile'].value = res.data.mobile;
          form['create-remark'].value = res.data.remarks;
          if (res.data.privUser === true) {
            form['userCb'].checked = true;
            document.getElementById('userChild').style.display = 'block';
            if (res.data.privUserAgent === true) form['newAgentCb'].checked = true;
            if (res.data.privUserMember === true) form['newMemberCb'].checked = true;
            if (res.data.privUserEdit === true) form['editCb'].checked = true;
            if (res.data.privUserSetting === true) form['settingCb'].checked = true;
            if (res.data.privUserShare === true) form['shareCb'].checked = true;
            if (res.data.privUserPassword === true) form['passwordCb'].checked = true;
          }
          if (res.data.privReport === true) form['reportCb'].checked = true;
          if (res.data.privBalReport === true) {
            form['balReportCb'].checked = true;
            document.getElementById('balReportChild').style.display = 'block';
            if (res.data.privBalReportDw === true) form['dwCb'].checked = true;
          }
        })
        .catch((err) => console.log(err));
    }
  }, [open]);

  return (

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form onSubmit={handleCreate} id="create-form">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  New Sub
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr width='30%'>
                <td className="text-body font-weight-bold ">
                  Your Password
                </td>
                <td width='70%'>
                  <input type="password" id="agentPass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Login Id</td>
                <td>
                  {type === 'U' && user != null ? (
                    user
                  ): (
                    <>
                      <input type="text" id="sub-user-name" required />@{sessionStorage.getItem('me')}
                    </>
                  )}
                  
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Name</td>
                <td>
                  <input type="text" id="create-name" required />
                </td>
              </tr>
              {subagent === null? (
                <>
                  <tr>
                    <td className="text-body font-weight-bold ">Password</td>
                    <td>
                      <input type="password" id="create-pass" required />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-body font-weight-bold ">
                      Confirm Password
                    </td>
                    <td>
                      <input type="password" id="confirm-pass" required />
                    </td>
                  </tr>
                </>
              ) : null}
              <tr>
                <td className="text-body font-weight-bold ">Status</td>
                <td>
                  <select name="" id="create-status">
                    <option value={1}>Active</option>
                    <option value={0}>InActive</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Phone</td>
                <td>
                  <input type="tel" id="create-mobile" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Privilege</td>
                <td>
                  <input type="checkbox" id="reportCb" name="reportCb"/>{' '}
                  <label htmlFor="reportCb"><strong>Report</strong></label>{' '}<br/>
                  <input type="checkbox" id="balReportCb" name="balReportCb" onChange={(e) => {
                    if (e.target.checked){
                      document.getElementById('balReportChild').style.display='block';
                    }
                    else {
                      document.getElementById('balReportChild').style.display='none';
                    }
                  }}/>{' '}
                  <label htmlFor="balReportCb"><strong>Bal Report</strong></label>{' '}
                  <span id='balReportChild' style={{display:'none'}}>
                    <input type="checkbox" id="dwCb" name="dwCb"/>{' '}
                    <label htmlFor="dwCb"> Deposit Withdraw</label>{' '}
                  </span>
                  <br/>
                  <input type="checkbox" id="userCb" name="userCb" onChange={(e) => {
                    if (e.target.checked){
                      document.getElementById('userChild').style.display='block';
                    }
                    else {
                      document.getElementById('userChild').style.display='none';
                    }
                  }}/>{' '}
                  <label htmlFor="userCb"><strong>User</strong></label>{' '}
                  <span id='userChild' style={{display:'none'}}>
                    <input type="checkbox" id="newAgentCb" name="newAgentCb"/>{' '}
                    <label htmlFor="newAgentCb"> New Agent</label>{' '}
                    <input type="checkbox" id="newMemberCb" name="newMemberCb"/>{' '}
                    <label htmlFor="newMemberCb"> New Member</label>{' '}
                    <input type="checkbox" id="editCb" name="editCb"/>{' '}
                    <label htmlFor="editCb"> Edit</label>{' '}
                    <input type="checkbox" id="settingCb" name="settingCb"/>{' '}
                    <label htmlFor="settingCb"> Setting</label>{' '}
                    <input type="checkbox" id="shareCb" name="shareCb"/>{' '}
                    <label htmlFor="shareCb"> Share</label>{' '}
                    <input type="checkbox" id="passwordCb" name="passwordCb"/>{' '}
                    <label htmlFor="passwordCb"> Password</label>{' '}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea
                    name=""
                    id="create-remark"
                    cols="30"
                    rows="3"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default CreateSub;