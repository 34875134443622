import React, { useEffect, useState } from 'react';
import axiosInstance from '../../config';
import Table from '../../components/Table';
import Create from '../../components/Create';
import Navbar from '../../components/Navbar';
import Member from '../member/Member';
import AgentUtils from '../../agentutils';

const Agent = ({ User, username, selectedRole }) => {
  const [down, setDown] = useState(null);
  // modal for creating agent
  const [open, setOpen] = useState(false);
  // data for table in agent
  const [agent, setAgent] = useState(null);
  // currencies
  const [currency, setCurrency] = useState([]);
  // sort by
  const [Sort, setSort] = useState([]);
  // copy of selected Role
  const [selectedRoleCopy, setSelectedRoleCopy] = useState(selectedRole);
  // array for options
  const number = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  // tabm for op log credit log and normal
  const [tabm, setTabm] = useState(0);
  const chars = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  // tab for op log credit log and normal
  const [tab, setTab] = useState(0);
  // for passing sort and asc to member

  const [searchUserM, setSearchUserM] = useState(null);
  const [searchNameM, setSearchNameM] = useState(null);
  const [searchStatusM, setSearchStatusM] = useState(null);
  const [searchSuspendM, setSearchSuspendM] = useState(null);
  const [searchSortM, setSearchSortM] = useState(null);
  const [searchAscM, setSearchAscM] = useState(null);
  // pagination
  const [totalPage, setTotalPage] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [sumCredit, setSumCredit] = useState(0);
  const [sumAvailableCredit, setSumAvailableCredit] = useState(0);
  const [sumBalance, setSumBalance] = useState(0);

  const backToParent = () => {
    // clearForm();
    if (
      down != null &&
      down.length > 0 &&
      down !== sessionStorage.getItem('me')
    ) {
      let up = down.substring(0, down.length - 2);
      if (
        AgentUtils.getDownLevel(up) !== AgentUtils.getUrlRole() &&
        down.length < 8
      ) {
        setDown(null);
      } else if (down.length > 8) {
        setDown(down.substring(0, 8));
      } else {
        setDown(up);
      }
      if (AgentUtils.getDownLevel(up) !== AgentUtils.getUrlRole()) {
        handleRoleSearch(null, 1, up);
      } else {
        setDown(null);
        handleRoleSearch(null, 1, '');
      }
    } else {
      handleRoleSearch(null, 1, '');
    }
  };

  //  handle search
  const handleRoleSearch = (e, page, usr) => {
    if (e != null) e.preventDefault();
    let tp = selectedRole.toUpperCase();
    let url = 'agent/agents';
    if (usr == null && down != null) {
      usr = down;
    }
    if (usr != null && usr != '') {
      if (usr.length === 2) {
        tp = 'SENIOR';
        setSelectedRoleCopy('Senior');
        setTab(0);
      } else if (usr.length < 2) {
        tp = 'VENDOR';
        setSelectedRoleCopy('Vendor');
        setTab(0);
      } else if (usr.length === 4) {
        tp = 'MASTER';
        setSelectedRoleCopy('Master');
        setTab(0);
      } else if (usr.length === 6) {
        tp = 'AGENT';
        setSelectedRoleCopy('Agent');
        setTab(0);
      } else if (usr.length === 8) {
        tp = null;
        url = 'agent/members';
        setSelectedRoleCopy('Member');
        setTab(3);
      }
      setDown(usr);
    } else {
      setSelectedRoleCopy(AgentUtils.getUrlRole());
    }
    const memberForm = document.getElementById('searchform');
    const searchName = memberForm ? memberForm['name'].value : searchNameM;
    const searchUser = memberForm ? memberForm['login'].value : searchUserM;
    const searchStatus = memberForm
      ? memberForm['status'].value === '2'
        ? null
        : memberForm['status'].value
      : searchStatusM;
    const searchSuspend = memberForm
      ? memberForm['status'].value === '2'
        ? 1
        : null
      : searchSuspendM;
    const sort = memberForm ? memberForm['sort'].value : searchSortM;
    const asc = memberForm ? memberForm['asc'].value : searchAscM;
    if (memberForm) {
      setSearchNameM(memberForm['name'].value);
      setSearchUserM(memberForm['login'].value);
      setSearchStatusM(
        memberForm['status'].value === '2' ? null : memberForm['status'].value
      );
      setSearchSuspendM(memberForm['status'].value === '2' ? 1 : null);
      setSearchSortM(memberForm['sort'].value);
      setSearchAscM(memberForm['asc'].value);
    }
    let searchPage = page;
    if (page) setPageNo(page);
    else {
      searchPage = pageNo;
    }
    axiosInstance
      .get(url, {
        params: {
          searchName: searchName !== '' ? searchName : null,
          searchUser: searchUser !== '' ? searchUser : null,
          agent: usr != null ? usr : down != null ? down : null,
          searchStatus: searchStatus !== '' ? searchStatus : null,
          searchSuspend: searchSuspend !== '' ? searchSuspend : null,
          sort: sort !== '' ? sort : null,
          asc: asc !== '' ? asc : null,
          type: tp,
          pageNo: isNaN(searchPage) ? null : searchPage - 1,
        },
      })
      .then((res) => {
        console.log(res.data);
        setTotalPage(res.data.totalPages);
        setAgent(res.data.content);
        sumCredBalance(res.data.content);
        setPageNo(res.data.pageable.pageNumber + 1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sumCredBalance = (agents) => {
    let sumC = 0;
    let sumB = 0;
    let sumA = 0;
    for (let i = 0; i < agents.length; i++) {
      let cashBalance =
        agents[i].cashBalance !== undefined ? agents[i].cashBalance : 0;
      let availableCredit =
        agents[i].availableCredit !== undefined ? agents[i].availableCredit : 0;
      // let availableBalance =  agents[i].availableBalance !== undefined ? agents[i].availableBalance : 0;
      sumC += agents[i].credit;
      sumB += cashBalance * -1;
      // sumB += availableBalance;
      sumA += availableCredit;
    }
    setSumCredit(sumC);
    setSumBalance(sumB);
    setSumAvailableCredit(sumA);
  };

  useEffect(() => {
    // getting agent list
    if (selectedRole == null) return;
    let url = '/agent/agents?type=' + selectedRole.toUpperCase();
    if (selectedRole === 'Member') {
      setTab(3);
      url = 'agent/members';
    }
    axiosInstance
      .get(url)
      .then((res) => {
        console.log(res.data);
        setTotalPage(res.data.totalPages);
        setAgent(res.data.content);
        sumCredBalance(res.data.content);
      })
      .catch((err) => console.log(err));
    // currencies for new addition
    axiosInstance
      .get('/agent/currencies')
      .then((res) => {
        console.log(res.data);
        setCurrency(res.data);
      })
      .catch((err) => console.log(err));
    // sort by option
    axiosInstance
      .get('/agent/sort ')
      .then((res) => {
        console.log(res.data);
        setSort(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Navbar username={username} User={User} />

      {agent !== null &&
      (tab === 0 || selectedRoleCopy === 'Member') &&
      tabm === 0 &&
      tab !== 3 ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form
              action=""
              className="form-inline"
              id="searchform"
              onSubmit={handleRoleSearch}
            >
              <label
                htmlFor="name"
                className="text-body font-weight-bold mx-2 ml-0"
              >
                Name{' '}
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="login"
                className="text-body font-weight-bold mx-2"
              >
                Login Id{' '}
              </label>
              <input
                type="text"
                id="login"
                className="form-control form-control-xs"
              />
              <label
                htmlFor="status"
                className="text-body font-weight-bold mx-2"
              >
                Status{' '}
              </label>
              <select
                name=""
                id="status"
                className="form-control form-control-xs"
              >
                {/* <select name="" id="status" className="form-control form-control-xs"> */}
                <option value="">All</option>
                <option value={1}>Active</option>
                <option value={2}>Suspended</option>
                <option value={0}>InActive</option>
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <label htmlFor="sort" className="text-body font-weight-bold mx-2">
                Sort by{' '}
              </label>
              <select
                name=""
                id="sort"
                className="form-control form-control-xs"
              >
                {/* <option value="">None</option> */}
                {Sort.map((srt, index) => (
                  <option value={srt.key} key={index}>
                    {srt.val}
                  </option>
                ))}
              </select>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <select
                name=""
                id="asc"
                className="form-control form-control-xs mx-2"
              >
                {/* <option value="">None</option> */}
                <option value={1}>Asc</option>
                <option value={0}>Desc</option>
              </select>
              <input
                type="submit"
                value="Search"
                className="btn btn-sm btn-primary px-3 py-1 "
              />
              {/* &nbsp;&nbsp;&nbsp; */}
              {/* -------------return button----------------------------- */}
              {down != null &&
              down.length > 0 &&
              AgentUtils.getDownLevel(down) !== AgentUtils.getUrlRole() &&
              down !== sessionStorage.getItem('me') ? (
                <div className="table-responsive-sm mx-2">
                  <button
                    className="btn btn-sm btn-secondary px-3 py-1 mx-1"
                    onClick={() => {
                      backToParent();
                    }}
                  >
                    Return
                  </button>
                </div>
              ) : null}
            </form>
            {User.privUser == null ||
            (User.privUser === true && User.privUserAgent === true) ? (
              <button
                className="btn btn-sm btn-primary px-3 py-1 "
                onClick={() => {
                  setOpen(true);
                }}
              >
                Create {selectedRoleCopy}
              </button>
            ) : null}
          </div>
        </>
      ) : null}

      {/* -------------return button----------------------------- */}

      {/* {down != null &&
      down.length > 0 &&
      down !== sessionStorage.getItem('me') ? (
        <div className="table-responsive-sm mx-2">
          <button
            className="btn btn-sm btn-primary px-1 py-1 my-2"
            onClick={() => {
              backToParent();
            }}
          >
            Return
          </button>
        </div>
      ) : null} */}

      {/* --------------------------table and create ------------------- */}
      {agent !== null ? (
        <>
          {tab === 3 ? (
            <>
              <Member
                username={username}
                User={User}
                searchName={searchNameM}
                searchUser={searchUserM}
                searchStatus={searchStatusM}
                setTab={setTab}
                sort={searchSortM}
                role={agent}
                setAgent={setAgent}
                asc={searchAscM}
                down={down}
                tabm={tabm}
                setTabm={setTabm}
                backToParent={backToParent}
              />
            </>
          ) : (
            <>
              <Table
                User={User}
                role={agent}
                givenRole={selectedRole}
                setSelectedRoleCopy={setSelectedRoleCopy}
                setRole={setAgent}
                totalPage={totalPage}
                pageNo={pageNo}
                setPageNo={setPageNo}
                sumCredit={sumCredit}
                sumBalance={sumBalance}
                sumAvailableCredit={sumAvailableCredit}
                handleRoleSearch={handleRoleSearch}
                setTab={setTab}
                tab={tab}
                down={down}
              />
            </>
          )}

          {/* modal for create agent */}
          {selectedRoleCopy.toUpperCase() === 'VENDOR' ||
          selectedRoleCopy.toUpperCase() === 'SENIOR' ||
          selectedRoleCopy.toUpperCase() === 'MASTER' ? (
            <Create
              down={down}
              type={selectedRoleCopy}
              open={open}
              setOpen={setOpen}
              number={chars}
              setAgent={setAgent}
              currency={currency}
              refresh={handleRoleSearch}
            />
          ) : selectedRoleCopy.toUpperCase() === 'AGENT' ||
            selectedRoleCopy.toUpperCase() === 'MEMBER' ? (
            <Create
              down={down}
              type={selectedRoleCopy}
              open={open}
              setOpen={setOpen}
              number={number}
              setAgent={setAgent}
              currency={currency}
              refresh={handleRoleSearch}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Agent;
