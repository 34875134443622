import axiosInstance from '../../config';
import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const moneyTd = (amt) => {
  return (
    <td className={`text-right${amt < 0?' text-danger':''}`}><CurrencyFormat value={amt != null ? amt : 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
  );
}

const moneyTd2 = (amt, cmp) => {
  return (
    <td className={`text-right${amt < 0?' text-danger':''}${amt !== cmp?' font-weight-bold':''}`}><CurrencyFormat value={amt != null ? amt : 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
  );
}

const Recalculate = ( {User} ) => {
  const [resp, setResp] = useState(null);
  const handleRecalculate = (e) => {
    if (e != null) e.preventDefault();
    setResp(null);
    axiosInstance
    .get('agent/balReport/recalculate', {
      params: {
        username: document.getElementById('recalculateId').value
      },
    })
    .then((res) => {
      setResp(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const makeAdjustment = (username) => {
    axiosInstance
    .get('agent/balReport/adjust', {
      params: {
        username: username
      },
    })
    .then((res) => {
      handleRecalculate(null);
      // setResp(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" className="form-inline" onSubmit={handleRecalculate} id="form">
          <label
            htmlFor="login"
            className="text-body font-weight-bold mx-2"
          >
            Login Id{' '}
          </label>
          <input
            type="text"
            id="recalculateId"
            className="form-control form-control-xs"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1 "
          />
        </form>
      </div>
      {resp ? (
        <>
        <div className="row mt-3 px-3">
          <div className="table-responsive-sm col-md-6">
            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-blue">
                <tr>
                  <th scope="col" colSpan={3}>
                    {resp.username} Transaction Summary
                  </th>
                </tr>
                <tr>
                  <th scope="col">Total Bets: {resp.totalBets}</th>
                  <th scope="col">Bal Report</th>
                  <th scope="col">Player Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-dark text-center">Net W/L</td>
                  {moneyTd(resp.totalBetResults)}
                  {moneyTd(resp.totalBetResults)}
                </tr>
                <tr className='text-danger'>
                  <td className="text-dark text-center">Outstanding</td>
                  <td></td>
                  {moneyTd(resp.totalOutstanding)}
                </tr>
                <tr>
                  <td className="text-dark text-center">Total Deposit</td>
                  {moneyTd(resp.totalDeposit)}
                  {moneyTd(resp.totalDeposit)}
                </tr>
                <tr className='text-danger'>
                  <td className="text-dark text-center">Total Withdraw</td>
                  {moneyTd(resp.totalWithdraw)}
                  {moneyTd(resp.totalWithdraw)}
                </tr>
                <tr>
                  <td className="text-dark text-center">Balance Writeoff</td>
                  {moneyTd(resp.writeOffReportBalance)}
                  {moneyTd(resp.writeOffBalance)}
                </tr>
              </tbody>
              <tfoot>
                <tr className="font-weight-bold">
                  <td className="text-dark text-center">Total</td>
                  {moneyTd(resp.totalDeposit + resp.totalWithdraw + resp.totalBetResults + resp.writeOffReportBalance)}
                  {moneyTd(resp.totalDeposit + resp.totalWithdraw + resp.totalBetResults - resp.totalOutstanding + resp.writeOffBalance)}
                </tr>
                </tfoot>
            </table>
          </div>
          <div className="table-responsive-sm col-md-6">
            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-blue">
                <tr>
                  <th scope="col" colSpan={2}>
                    Transaction Details (to be updated)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-dark text-center">Current Player Balance</td>
                  {moneyTd(resp.beginningBalance)}
                </tr>
                <tr>
                  <td className="text-dark text-center">Calculated Player Balance</td>
                  {moneyTd(resp.endBalance)}
                </tr>
                <tr>
                  <td className="text-dark text-center">Adjustment Player Balance</td>
                  { moneyTd(resp.endBalance - resp.beginningBalance) }
                </tr>
                <tr>
                  <td className="text-dark text-center">Current Bal Report Balance</td>
                  { moneyTd(resp.beginningReportBalance) }
                </tr>
                <tr>
                  <td className="text-dark text-center">Calculated Report Balance</td>
                  { moneyTd(resp.endReportBalance) }
                </tr>
                <tr>
                  <td className="text-dark text-center">Adjustment Bal Report Balance</td>
                  { moneyTd(resp.endReportBalance - resp.beginningReportBalance) }
                </tr>
                <tr>
                  <td className="text-dark text-center" colSpan={2}>
                    { resp.endReportBalance - resp.beginningReportBalance == 0 && resp.endBalance - resp.beginningBalance == 0 ? (
                      <>
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            makeAdjustment(resp.username);
                          }}
                        >
                          Make Adjustment
                        </button>&nbsp;&nbsp;
                        <span className='font-weight-bold'>
                          Adjustment is not necessary
                        </span>
                      </>
                    ): (
                      <button
                        className="btn btn-sm btn-link text-primary p-0"
                        onClick={() => {
                          makeAdjustment(resp.username);
                        }}
                      >
                        Make Adjustment
                      </button>
                    )}
                    
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="table-responsive-sm mx-2">
          <table className="table table-sm table-striped table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col" colSpan={11}>Last 100 records</th>
              </tr>
              <tr>
                <th scope="col" rowSpan={2}>
                  No
                </th>
                <th scope="col" rowSpan={2}>
                  Created Date
                </th>
                <th scope="col" rowSpan={2}>
                  Tx Date
                </th>
                <th scope="col" rowSpan={2}>
                  Type
                </th>
                <th scope="col" rowSpan={2}>
                  Credit /<br/>Debit
                </th>
                <th scope="col" rowSpan={2}>
                  Bet<br/>Amount
                </th>
                <th scope="col" rowSpan={2}>
                  Bet<br/>Winning
                </th>
                <th scope="col" colSpan={2}>
                  Balance
                </th>
                <th scope="col" colSpan={2}>
                  Report Balance
                </th>
                {/* <th scope="col" colSpan={2}>
                  Commission
                </th> */}
              </tr>
              <tr>
                <th scope="col">Current</th><th scope="col">Calculated</th>
                <th scope="col">Current</th><th scope="col">Calculated</th>
                {/* <th scope="col">Before</th><th scope="col">After</th> */}
              </tr>
            </thead>
            {resp.content.length > 0 ? (
              <tbody>
                {resp.content.map((info, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {index+1}
                    </td>                    
                    <td className="text-center">{moment(info.createdAt).format('DD-MM HH:mm:ss')}</td>
                    <td className="text-center">{moment(info.reportDate).format('DD-MM HH:mm:ss')}</td>
                    <td className="text-center">{info.transactionType}</td>
                    { moneyTd(info.balanceAmount) }
                    { moneyTd(info.betAmount) }
                    { moneyTd(info.winning) }
                    { moneyTd(info.endBalance) }
                    { moneyTd2(info.endBalance2, info.endBalance) }
                    { moneyTd(info.endReportBalance) }
                    { moneyTd2(info.endReportBalance2, info.endReportBalance) }
                  </tr>
                ))}
              </tbody>
            ) : null} 
          </table>
        </div>
        </>
      ) : null}
    </>
  );
};

export default Recalculate;
