import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));


const Details = ({ transactions, handleChange, totalAmount, totalPage }) => {
  const classes = useStyles();
  return (
    <>
    <div className="table-responsive-sm mx-2">
      <table className="table table-sm table-striped table-bordered">
        <thead className="text-center thead-dark">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Date</th>
            <th scope="col">Name</th>
            <th scope="col">Login ID</th>
            <th scope="col">Operator</th>
            <th scope="col">Currency</th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Remarks</th>
          </tr>
        </thead>
        {transactions.length > 0 ? (
          <tbody>
            {transactions.map((tx, index) => (
              <tr>
                <td className="text-dark text-center">{index + 1}</td>
                <td className="text-dark text-center">{moment(tx.createdAt).format('DD/MM/YYYY HH:mm')}</td>
                <td className="text-dark text-center">{tx.name}</td>
                <td className="text-dark text-center">{tx.username}</td>
                <td className="text-dark text-center">{tx.operator}</td>
                <td className="text-dark text-center">{tx.currency}</td>
                <td className="text-dark text-center">{tx.type}</td>
                <td className={`text-right ${tx.amount < 0? 'text-danger': ''}`}>
                  <CurrencyFormat
                    value={tx.amount}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    />
                </td>
                <td className="text-dark text-center">{tx.remarks}</td>
              </tr>
            ))}
          </tbody>
        ) : null}
        <tfoot className="font-weight-bold">
          <tr>
            <td className="text-center" colSpan="7">Total</td>
            <td className={`text-right ${totalAmount && totalAmount < 0? 'text-danger': ''}`}><CurrencyFormat value={totalAmount} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
            <td>&nbsp;</td>
          </tr>
        </tfoot>
      </table>
    </div>
      {totalPage && totalPage > 0? (
        <Pagination
          count={totalPage}
          onChange={handleChange}
          className={classes.pagination}
        />
      ) : null}
      </>
  )
}

export default Details;