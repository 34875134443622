import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const WinLoseDetail = () => {
  // const classes = useStyles();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [username, setUsername] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [totalWl, setTotalWl] = useState(0);
  const [totalComm, setTotalComm] = useState(0);
  const [totalDep, setTotalDep] = useState(0);
  const [totalWd, setTotalWd] = useState(0);
  const history = useHistory();
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/balanceReport/winLoseDetail', {
        params: {
          startDate: startDate? moment(startDate).format('DD-MM-YYYY'): null,
          endDate: endDate? moment(endDate).format('DD-MM-YYYY'): null,
          username: query.get('username')
        },
      })
      .then((res) => {
        setReports(res.data);
        let sumWl = 0, sumComm = 0, sumDep = 0, sumWd = 0;
        for (let i = 0; i < res.data.length; i++){
          sumWl += res.data[i].netWinLose;
          sumComm += res.data[i].winCommission;
          sumDep += res.data[i].deposit;
          sumWd += res.data[i].withdraw;
        }
        setTotalWl(sumWl);
        setTotalComm(sumComm);
        setTotalDep(sumDep);
        setTotalWd(sumWd);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    if (username == null) {
      setUsername(sessionStorage.getItem('me'));
    }
    if (startDate == null && query.get("startDate") != null){
      console.log('set start date ');
      setStartDate(new Date(moment(query.get("startDate"),"DD-MM-YYYY")));
    }
    if (endDate == null && query.get("endDate") != null){
      console.log('set end date');
      setEndDate(new Date(moment(query.get("endDate"),"DD-MM-YYYY")));
    }
    handleChange(null);
  }, [username, startDate, endDate]);

  return (
    <>
    
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          <h6>{query.get('username')} Win Lose Details</h6>
          Start Date:
          <DatePicker
            selected={startDate ? startDate: new Date()}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
            End Date:
            <DatePicker
              selected={endDate ? endDate: new Date()}
              onChange={(date) => setEndDate(date)}
              className="mx-2"
              dateFormat="d MMM yyyy"
            />
        </form>
      </div>
      {reports !== null ? (
        <div className="table-responsive-sm">
          <table className="table table-sm table-hover table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Day</th>
                <th scope="col">Currency</th>
                <th scope="col">Beginning</th>
                <th scope="col">Net W/L</th>
                <th scope="col">Commission</th>
                <th scope="col">Deposit</th>
                <th scope="col">Withdraw</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((info, index) => (
                <tr key={index}>
                  <td className="text-dark text-center">{moment(info.reportDate).format('DD-MM-YYYY')}</td>
                  <td className="text-dark text-center">{moment(info.reportDate).format('ddd')}</td>
                  <td className="text-dark text-center">{info.currency}</td>
                  <td className={`${info.beginning < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.beginning? info.beginning: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.netWinLose < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.netWinLose? info.netWinLose: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.winCommission< 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.winCommission? info.winCommission: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.deposit < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.deposit? info.deposit: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.withdraw < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.withdraw? info.withdraw: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                  <td className={`${info.balance < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={info.balance? info.balance: 0} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td className="text-dark text-center" colSpan="4">Total</td>
                <td className={`${totalWl < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalWl} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalComm < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalComm} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalDep < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalDep} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td className={`${totalWd < 0? 'text-danger ':''}text-right`}><CurrencyFormat value={totalWd} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : null}
    </>
  );
};

export default WinLoseDetail;
