import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar';
import axiosInstance from '../../config';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const ShareRate = ({ User }) => {
  const classes = useStyles();
  const [level, setLevel] = useState(0);
  const [share, setShare] = useState(null);
  // const [sport, setSport] = useState(null);
  // const [market, setMarket] = useState(null);
  // const [sports, setSports] = useState([]);
  // const [markets] = useState(['LIVE', 'TODAY', 'EARLY', 'PARLAY']);
  const [totalPage, setTotalPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    setLoading(true);
    // select sport
    axiosInstance
      .get('agent/shareRateReport', {
        params: {
          // market: market ? market : markets[0],
          // sport: sport ? sport : sports[0].key,
          name:
            document.getElementById('member-name').value.length > 0
              ? document.getElementById('member-name').value
              : null,
          username:
            document.getElementById('member-id').value.length > 0
              ? document.getElementById('member-id').value
              : null,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((resp) => {
        setLevel(resp.data.level);
        setShare(resp.data.list.content);
        setTotalPage(resp.data.list.totalPages);
      })
      .catch((err) => {
        console.log(err);
        setShare([]);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // if (sports.length === 0) {
    //   axiosInstance.get('public/selectSport').then((res) => {
    //     setSports(res.data);
    //   });
    // } else {
    handleChange(null);
    // }
  }, []);
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          {/* <label htmlFor="type" className="mx-2 my-0">
            Type :
          </label> */}
          {/* <select
            name=""
            id="type"
            style={{ minWidth: '100px' }}
            className="py-1 "
            onChange={(sp) => setSport(sp.target.value)}
          >
            {sports.map((sp) => (
              <option value={sp.key}>{sp.val}</option>
            ))}
          </select>
          <label htmlFor="type" className="mx-2 my-0">
            Market :
          </label>
          <select
            name=""
            id="type"
            style={{ minWidth: '100px' }}
            className="py-1 "
            onChange={(mk) => setMarket(mk.target.value)}
          >
            {markets.map((mk) => (
              <option value={mk}>{mk}</option>
            ))}
          </select>{' '} */}
          <label htmlFor="member-name mx-2 my-0">Name: </label>
          <input type="text" id="member-name" className="mx-2" />
          <label htmlFor="member-id mx-2">Login ID: </label>
          <input type="text" id="member-id" className="mx-2" />
          <input
            type="submit"
            value="Submit"
            className="btn btn-sm btn-primary px-1 py-1 mx-1"
            disabled={loading}
          />
        </form>
      </div>
      {/* table */}
      <div className="table-responsive-sm mx-2">
        <table className="table table-sm table-striped table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Login ID</th>
              {/* <th>Sport</th>
              <th>Market</th> */}
              <th>Agent</th>
              {level > 10 ? <th>Master Agent</th> : null}
              {level > 20 ? <th>Senior Master</th> : null}
              {level > 30 ? <th>Vendor</th> : null}
              <th>Company</th>
            </tr>
          </thead>
          {share !== null ? (
            <tbody className="text-center">
              {share.map((info, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{info.name}</td>
                  <td>{info.username}</td>
                  {/* <td>{info.sport}</td>
                  <td>{info.market}</td> */}
                  <td>{info.agentStake}</td>
                  {level === 10 ? (
                    <td>{100 - info.agentStake}</td>
                  ) : level === 20 ? (
                    <>
                      <td>{info.masterStake}</td>
                      <td>{100 - info.masterStake}</td>
                    </>
                  ) : level === 30 ? (
                    <>
                      <td>{info.masterStake}</td>
                      <td>{info.seniorStake}</td>
                      <td>{100 - info.seniorStake}</td>
                    </>
                  ) : (
                    <>
                      <td>{info.masterStake}</td>
                      <td>{info.seniorStake}</td>
                      <td>{info.vendorStake}</td>
                      <td>{info.companyStake}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
        <Pagination
          count={totalPage}
          onChange={handleChange}
          className={classes.pagination}
        />
      </div>
    </>
  );
};

export default ShareRate;
