import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const Consolidated = ( {User} ) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [report, setReport] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleChange = (e) => {
    if (e != null) e.preventDefault();
    setReport(null);
    let url = 'agent/balReport/consolidated';
    if (document.getElementById('username').value.length === 0) {
      return;
    }
    setLoading(true);
    axiosInstance
      .get(url, {
        params: {
          username: document.getElementById('username').value,
          startDate: startDate
            ? moment(startDate).format('DD-MM-YYYY')
            : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
        },
      })
      .then((res) => {
        setReport(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(()=>{
        setLoading(false);
      });
  };

  const getToday = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        today.setDate(today.getDate() - minus);
        setStartDate(today);
        setEndDate(today);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getWeek = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let diff = today.getDay() - 1;
        if (diff < 0) diff += 7;
        today.setDate(today.getDate() - diff);
        if (minus > 0){
          today.setTime(today.getTime() - minus * 7 * 24*60*60*1000);
          today2.setTime(today.getTime() + 6 * 24*60*60*1000);
        }
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getMonth = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        if (minus > 0) {
          today.setMonth(today.getMonth() - minus);
          if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
          today2.setDate(minus - 1);
        }
        today.setDate(1);
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const moneyTd = (amt) => {
    return (
      <td className={`text-right${amt < 0?' text-danger':''}`}><CurrencyFormat value={amt} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td>
    );
  }
  useEffect(() => {
    axiosInstance
        .get('public/today')
        .then((res) => {
          let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          setStartDate(today);
          setEndDate(today2);
          setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
          let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          minSearchDate.setDate(minSearchDate.getDate() - 90);
          setSearchDate(minSearchDate);
      });
    // handleChange(null);
  }, [history.location]);

  useEffect(() => {
    if (startDate != null && endDate != null && endDate >= startDate) handleChange(null);
  }, [startDate, endDate]);
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={(e) => e.preventDefault()} id="consolForm">
          <label
            htmlFor="login"
            className="text-body font-weight-bold mx-2"
          >
            Login Id{' '}
          </label>
          <input
            type="text"
            id="username"
            // className="form-control form-control-xs"
            required
          />&nbsp;&nbsp;
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          &nbsp;
          {loading === true ? (
            <img src={require('../../img/Iphone-spinner-2.gif')} height={25} width={25} alt="" />
          ) : (
            <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                handleChange(null);
              }}
            >
              Get Report
          </button>
          )}
          {' '}
          <button
              className="btn btn-sm btn-primary px-1 py-1"
              onClick={() => {
                getToday(0);
              }}
            >
              Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
        </form>
      </div>
      {report != null ? (
        <div className="table-responsive-sm mx-2">
          <table className="table table-sm table-striped table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col" colSpan={15}>W/L, Balance and Gross Comm [{moment(startDate).format('DD/MMM/YYYY')+' - '+moment(endDate).format('DD/MMM/YYYY')}]</th>
              </tr>
              <tr>
                <th scope="col" rowSpan={2}>
                  Login ID
                </th>
                <th scope="col" rowSpan={2}>
                  Currency
                </th>
                <th scope="col" colSpan={3}>
                  W/L
                </th>
                <th scope="col" colSpan={5}>
                  Balance Report
                </th>
                <th scope="col" colSpan={4}>
                  Commission
                </th>
                <th scope="col" rowSpan={2}>
                  Net W/L +<br/>Commission
                </th>
              </tr>
              <tr>
                <th scope="col">Win/Lose</th>
                <th scope="col">Comm</th>
                <th scope="col">Total W/L</th>
                <th scope="col">Beginning</th>
                <th scope="col">Net W/L</th>
                <th scope="col">Deposit</th>
                <th scope="col">Withdraw</th>
                <th scope="col">Balance</th>
                <th scope="col">Beginning</th>
                <th scope="col">Commission</th>
                <th scope="col">Withdraw</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>
            {report != null ? (
              <tbody>
                <tr>
                  <td>{report.balance.username}</td>
                  <td>{report.balance.currency}</td>
                  {moneyTd(report.reportWin)}
                  {moneyTd(report.reportComm)}
                  {moneyTd(report.reportWin + report.reportComm)}
                  {moneyTd(report.balance.beginning)}
                  {moneyTd(report.balance.netWinLose)}
                  {moneyTd(report.balance.deposit)}
                  {moneyTd(report.balance.withdraw)}
                  {moneyTd(report.balance.balance)}
                  {moneyTd(report.comm.beginning)}
                  {moneyTd(report.comm.winCommission)}
                  {moneyTd(report.comm.withdraw)}
                  {moneyTd(report.comm.beginning+report.comm.winCommission+report.comm.withdraw)}
                  {moneyTd(report.comm.winCommission+report.balance.netWinLose)}
                </tr>
              </tbody>
            ) : null} 
          </table>
        </div>
      ) : null}
    </>
  );
};

export default Consolidated;
