import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import Pagination from "@material-ui/lab/Pagination";
import qs from 'querystring';
import Navbar from '../../components/Navbar';
import MaxLossModal from '../../components/MaxLossModal';

const useStyles = makeStyles((theme) => ({
  border: {
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  borderInactive: {
    backgroundColor: '#e1e1e1',
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const MaxLoss = ({ User }) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [pageNo, setPageNo] = useState(null);
  const history = useHistory();
  const handleChange = (e, page) => {
    if (e != null) e.preventDefault();
    const maxForm = document.getElementById('maxForm');
    const searchName = maxForm['name'].value;
    const searchUser = maxForm['login'].value;
    const searchStatus = maxForm['status'].value;
    // const sort = maxForm['sort'].value;
    // const asc = maxForm['asc'].value;
    axiosInstance
      .get('agent/balanceReport/maxloss', {
        params: {
          searchName: searchName !== '' ? searchName : null,
          searchUser: searchUser !== '' ? searchUser : null,
          searchStatus: searchStatus !== '' ? searchStatus : null,
          // sort: sort !== '' ? sort : null,
          // asc: asc !== '' ? asc : null,
          pageNo: isNaN(page) ? null : page - 1,
        },
      })
      .then((res) => {
        setPageNo(res.data.pageable.pageNo);
        setTotalPage(res.data.totalPages);
        setReports(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const handleDelete = (usr) => {
    const requestBody = {
      username: usr,
    };
    axiosInstance
      .post('agent/balanceReport/maxloss/remove',qs.stringify(requestBody))
      .then((res) => {
        handleChange(null, pageNo);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    handleChange(null);
  }, []);

  return (
    <>
      <Navbar User={User} />
        <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form
          action=""
          className="form-inline"
          id="maxForm"
          onSubmit={handleChange}
        >
          <label
            htmlFor="name"
            className="text-body font-weight-bold mx-2 ml-0"
          >
            Name{' '}
          </label>
          <input
            type="text"
            id="name"
            className="form-control form-control-xs"
          />
          <label
            htmlFor="login"
            className="text-body font-weight-bold mx-2"
          >
            Login Id{' '}
          </label>
          <input
            type="text"
            id="login"
            className="form-control form-control-xs"
          />
          <label
            htmlFor="status"
            className="text-body font-weight-bold mx-2"
          >
            Status{' '}
          </label>
          <select
            name=""
            id="status"
            className="form-control form-control-xs"
          >
            {/* <select name="" id="status" className="form-control form-control-xs"> */}
            <option value="">All</option>
            <option value={true}>Active</option>
            <option value={false}>InActive</option>
          </select>
          &nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1 "
          />
          {/* &nbsp;&nbsp;&nbsp; */}
        </form>
        <button
          className="btn btn-sm btn-primary px-3 py-1 "
          onClick={() => {
            setSelected(null);
            setOpen(true);
          }}
        >
          Create
        </button>
      </div>
      
        <div className="table-responsive-sm mx-2">
          <table className="table table-sm table-hover table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Login ID</th>
                <th scope="col">Currency</th>
                <th scope="col">Available Credit</th>
                <th scope="col">Cash Balance</th>
                <th scope="col">Max Loss</th>
                <th scope="col">Status</th>
                <th scope="col">Remarks</th>
                <th scope="col">Function</th>
              </tr>
            </thead>
            {reports.length > 0 ? (
            <tbody>
              {reports.map((info, index) => (
                <tr
                  className={`${
                    info.status === true
                      ? classes.border
                      : classes.borderInactive
                  }`}
                  key={index}
                >
                  <td className="text-dark text-left">{index + 1}</td>
                  <td className="text-dark text-left">{info.name}</td>
                  <td className="text-dark text-center">{info.username}</td>
                  <td className="text-dark text-center">{info.currency}</td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={info.availableCredit ? info.availableCredit : 0}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      className={info.availableCredit < 0? 'text-danger':''}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={info.cashBalance ? info.cashBalance * -1 : 0}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      className={info.cashBalance > 0? 'text-danger':''}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={info.maxLoss ? info.maxLoss : 0}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      className={info.maxLoss < 0? 'text-danger':''}
                    />
                  </td>
                  {info.status === true ? (
                    <td className="text-dark text-center">Active</td>
                  ) : (
                    <td className="text-danger text-center">InActive</td>
                  )}
                  <td className="text-dark text-center">{info.maxLossRemarks}</td>
                  <td>
                    <button
                      className="btn btn-sm btn-link text-primary p-0"
                      onClick={() => {
                        setSelected(info.username);
                        setOpen(true);
                      }}
                    >
                      Edit
                    </button>
                    &nbsp;|&nbsp;
                    <button
                      className="btn btn-sm btn-link text-primary p-0"
                      onClick={() => {
                        handleDelete(info.username);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            ) : null}
          </table>
          <Pagination
            count={totalPage}
            onChange={handleChange}
            className={classes.pagination}
            page={pageNo}
          />
        </div>
      
      <MaxLossModal
        cashModal={open}
        setCashModal={setOpen}
        username={selected}
        refresh={handleChange}
      />
    </>
  );
};

export default MaxLoss;
