import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import Navbar from '../../components/Navbar';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#e1e1e1',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const Negative = ({ User }) => {
  const classes = useStyles();
  const [member, setMember] = useState([]);
  // sort by
  const [Sort, setSort] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  // pagination
  const [totalPage, setTotalPage] = useState(null);

  // handle memeber search
  const handleMemberSearch = (e, page) => {
    e.preventDefault();
    const memberForm = document.getElementById('member-form');
    const searchNameM = memberForm['member-name'].value;
    const searchUserM = memberForm['member-login'].value;
    const searchStatusM = memberForm['member-status'].value;
    const sortm = memberForm['member-sort'].value;
    const ascm = memberForm['member-asc'].value;
    setPageNo(page);
    axiosInstance
      .get('agent/members/negative', {
        params: {
          searchName: searchNameM !== '' ? searchNameM : null,
          searchUser: searchUserM !== '' ? searchUserM : null,
          searchStatus: searchStatusM !== '' ? searchStatusM : null,
          sort: sortm !== '' ? sortm : null,
          asc: ascm !== '' ? ascm : null,
          pageNo: isNaN(page) ? null : page - 1,
        },
      })
      .then((res) => {
        console.log(res.data);
        setMember(res.data.content);
      })
      .catch((err) => console.log(err));
  };

  // load member when component mounts
  useEffect(() => {
    axiosInstance
      .get('/agent/members/negative')
      .then((res) => {
        console.log(res);
        setMember(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => console.log(err));
    // sort by option
    axiosInstance
      .get('/agent/sort ')
      .then((res) => {
        console.log(res.data);
        setSort(res.data);
      })
      .catch((err) => console.log(err));
    // getting users for popup modal
  }, []);
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form
          action=""
          className="form-inline"
          id="member-form"
          onSubmit={handleMemberSearch}
        >
          <label
            htmlFor="member-name"
            className="text-body font-weight-bold mx-2 ml-0"
          >
            Name{' '}
          </label>
          <input
            type="text"
            id="member-name"
            className="form-control form-control-xs"
          />
          <label
            htmlFor="member-login"
            className="text-body font-weight-bold mx-2"
          >
            Login Id{' '}
          </label>
          <input
            type="text"
            id="member-login"
            className="form-control form-control-xs"
          />
          <label
            htmlFor="member-status"
            className="text-body font-weight-bold mx-2"
          >
            Status{' '}
          </label>
          <select
            name=""
            id="member-status"
            className="form-control form-control-xs"
          >
            <option value="">All</option>
            <option value={true}>Active</option>
            <option value={false}>InActive</option>
          </select>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <label
            htmlFor="member-sort"
            className="text-body font-weight-bold mx-2"
          >
            Sort by{' '}
          </label>
          <select
            name=""
            id="member-sort"
            className="form-control form-control-xs"
          >
            {Sort.map((srt, index) => (
              <option value={srt.key} key={index}>
                {srt.val}
              </option>
            ))}
          </select>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <select
            name=""
            id="member-asc"
            className="form-control form-control-xs mx-2"
          >
            <option value={1}>Asc</option>
            <option value={0}>Desc</option>
          </select>
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1 "
          />
          &nbsp;&nbsp;&nbsp;
        </form>
      </div>
      <div className="table-responsive-sm mx-2">
        <table className="table table-sm table-hover table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th>No.</th>
              <th scope="col">Name</th>
              <th scope="col">Login Id</th>
              <th scope="col">Currency</th>
              <th scope="col">Given Credit</th>
              <th scope="col">Bet Credit</th>
              <th scope="col">Last Login Time</th>
              <th scope="col">Create Time</th>
              <th scope="col">Status</th>
              <th scope="col">Negative From</th>
              <th scope="col">Function</th>
            </tr>
          </thead>
          <tbody>
            {member.map((info, index) => (
              <tr
                className={`${
                  info.status === true ? '' : classes.borderInactive
                }`}
                key={index}
              >
                <td className="text-dark text-center">{index + 1}</td>
                <td className="text-dark">{info.name}</td>
                <td className="text-dark text-center">{info.username}</td>
                <td className="text-dark text-center">{info.currency}</td>
                <td className="text-dark text-right">
                  <CurrencyFormat
                    value={info.credit}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </td>
                <td className="text-dark text-right">
                  <CurrencyFormat
                    value={info.availableBalance}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                    className={info.availableBalance < 0 ? 'text-danger' : null}
                  />
                </td>
                <td className="text-dark text-center">
                  {info.lastLogin == null ? (
                    '-'
                  ) : (
                    <>{moment(info.lastLogin).format('DD/MM/YYYY HH:mm:ss')} </>
                  )}
                </td>
                <td className="text-dark text-center">
                  {moment(info.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                {info.status === true ? (
                  <td className="text-dark text-center">Active</td>
                ) : (
                  <td className="text-danger text-center">InActive</td>
                )}
                <td className="text-dark text-center">
                  {moment(info.negativeFrom).format('DD/MM/YYYY HH:mm:ss')}
                </td>
                <td className="text-primary">&nbsp;</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        count={totalPage}
        onChange={handleMemberSearch}
        className={classes.pagination}
        page={pageNo}
      />
    </>
  );
};

export default Negative;
