import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/login/Login';
import Message from './pages/message/Message';
import SystemSettings from './pages/systemSettings/SystemSettings';
import WlDetail from './pages/report/WlDetail';
import WlPackage from './pages/report/WlPackage';
import ProfitLoss from './pages/report/ProfitLoss';
import Outstanding from './pages/report/Outstanding';
import GameCategory from './pages/report/GameCategory';
import WinLose from './pages/balance/WinLose';
import WinLoseDetail from './pages/balance/WinLoseDetail';
import ProtectedRoute from './components/ProtectedRoute';
import CreditLog from './components/CreditLog';
import Vendor from './pages/vendor/Vendor';
import OperationLog from './components/OperationLog';
import OperationLogM from './pages/member/functions/OperationLogM';
import CreditLogM from './pages/member/functions/CreditLogM';
import Dashboard from './pages/dashboard/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BigWinner from './pages/report/BigWinner';
import Win from './pages/report/Win';
import Cancel from './pages/report/Cancel';
import ShareRate from './pages/report/ShareRate';
import ShareRateSearch from './pages/report/ShareRateSearch';
import OwnOperationLog from './pages/OwnOperationLog';
import Sub from './pages/agent/Sub';
import Security from './pages/balance/Security';
import Negative from './pages/balance/Negative';
import MaxLoss from './pages/balance/MaxLoss';
import Notification from './components/Notification';
import RollbackList from './pages/rollback/RollbackList';
import Summary from './pages/report/Summary';
import Recalculate from './pages/balance/Recalculate';
import RecalculateList from './pages/balance/RecalculateList';
import Writeoff from './pages/balance/Writeoff';
import Maintenance from './pages/Maintenance';
import Consolidated from './pages/report/Consolidated';
import AdjustAgent from './pages/ops/AdjustAgent';
import RecalculateBet from './pages/ops/RecalculateBet';
import Statistic from './pages/Statistic';
import MissingTrx from './pages/hidden/MissingTrx';

function App() {
  return (
    <div className="App">
      <Notification />
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/maintenance" component={Maintenance} />
          <ProtectedRoute exact path="/sub" component={Sub} />
          <ProtectedRoute
            exact
            path="/member"
            component={(props) => <Vendor {...props} selectedRole="Member" />}
          />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          <ProtectedRoute exact path="/message" component={Message} />
          <ProtectedRoute
            exact
            path="/systemSettings"
            component={SystemSettings}
          />

          {/* <ProtectedRoute exact path="/senior" component={Senior} /> */}
          <ProtectedRoute exact path="/ownOpLog" component={OwnOperationLog} />
          <ProtectedRoute exact path="/consolidated" component={Consolidated} />
          <ProtectedRoute
            exact
            path="/summary"
            component={(props) => <Summary {...props} />}
          />
          <ProtectedRoute
            exact
            path="/stats"
            component={(props) => <Statistic {...props} />}
          />
          <ProtectedRoute
            exact
            path="/wl"
            component={(props) => <WlDetail {...props} summaryView="Y" />}
          />
          <ProtectedRoute
            exact
            path="/wlDetail"
            component={(props) => <WlDetail {...props} summaryView="N" />}
          />
          <ProtectedRoute
            exact
            path="/wlType"
            component={(props) => <WlDetail {...props} type="Y" />}
          />
          <ProtectedRoute
            exact
            path="/WlPackage"
            component={(props) => <WlPackage {...props} type="Y" />}
          />
          <ProtectedRoute
            exact
            path="/out"
            component={(props) => (
              <WlDetail {...props} out="O" summaryView="Y" />
            )}
          />
          <ProtectedRoute
            exact
            path="/outDetail"
            component={(props) => <WlDetail {...props} out="O" />}
          />
          <ProtectedRoute
            exact
            path="/outType"
            component={(props) => <WlDetail {...props} type="Y" out="O" />}
          />
          <ProtectedRoute
            exact
            path="/opsAdjustAgent"
            component={AdjustAgent}
          />
          <ProtectedRoute
            exact
            path="/opsRecalculate"
            component={RecalculateBet}
          />
          {/* <ProtectedRoute exact path="/wl">
            <WlDetail summaryView="Y" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/wlDetail">
            <WlDetail summaryView="N" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/wlType">
            <WlDetail type="Y" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/out">
            <WlDetail out="O" summaryView="Y" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/outDetail">
            <WlDetail out="O" />
          </ProtectedRoute>
          <ProtectedRoute exact path="/outType">
            <WlDetail type="Y" out="O" />
          </ProtectedRoute> */}
          <ProtectedRoute exact path="/bigwinner" component={BigWinner} />
          <ProtectedRoute exact path="/win" component={Win} />
          <ProtectedRoute exact path="/cancel" component={Cancel} />
          <ProtectedRoute exact path="/sharerate" component={ShareRate} />
          <ProtectedRoute
            exact
            path="/indisharerate"
            component={ShareRateSearch}
          />
          <ProtectedRoute exact path="/report" component={ProfitLoss} />
          <ProtectedRoute exact path="/outstanding" component={Outstanding} />
          <ProtectedRoute exact path="/gamecategory" component={GameCategory} />
          {/* <ProtectedRoute exact path="/agent" component={Agent} /> */}
          <ProtectedRoute exact path="/security" component={Security} />
          <ProtectedRoute exact path="/negative" component={Negative} />
          <ProtectedRoute exact path="/maxloss" component={MaxLoss} />
          <ProtectedRoute exact path="/winlose" component={WinLose} />
          <ProtectedRoute
            exact
            path="/winlose2"
            component={(props) => <WinLose {...props} opt={true} />}
          />
          <ProtectedRoute exact path="/recalculate" component={Recalculate} />
          <ProtectedRoute
            exact
            path="/recalculateList"
            component={RecalculateList}
          />
          <ProtectedRoute
            exact
            path="/grosscomm"
            component={(props) => <WinLose {...props} type="Comm" />}
          />
          <ProtectedRoute
            exact
            path="/grosscomm2"
            component={(props) => <WinLose {...props} type="Comm" opt={true} />}
          />
          <ProtectedRoute
            exact
            path="/winlosedetail"
            component={WinLoseDetail}
          />
          {/* <ProtectedRoute exact path="/master" component={Master} /> */}
          <ProtectedRoute exact path="/credit" component={CreditLog} />
          <ProtectedRoute exact path="/creditm" component={CreditLogM} />
          <ProtectedRoute exact path="/operation" component={OperationLog} />
          <ProtectedRoute exact path="/operationm" component={OperationLogM} />
          <ProtectedRoute exact path="/balcredit" component={CreditLog} />
          <ProtectedRoute exact path="/balcreditm" component={CreditLogM} />
          {/* <ProtectedRoute exact path="/vendor" component={Vendor} /> */}
          <ProtectedRoute
            exact
            path="/vendor"
            component={(props) => <Vendor {...props} selectedRole="Vendor" />}
          />
          <ProtectedRoute
            exact
            path="/senior"
            component={(props) => <Vendor {...props} selectedRole="Senior" />}
          />
          <ProtectedRoute
            exact
            path="/master"
            component={(props) => <Vendor {...props} selectedRole="Master" />}
          />
          <ProtectedRoute
            exact
            path="/agent"
            component={(props) => <Vendor {...props} selectedRole="Agent" />}
          />
          <ProtectedRoute exact path="/rollback" component={RollbackList} />
          <ProtectedRoute exact path="/writeoff" component={Writeoff} />
          <ProtectedRoute exact path="/opsMissingTrx" component={MissingTrx} />
        </Switch>
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
