import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import AgentUtils from '../agentutils';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Edit = ({
  editModal,
  setEditModal,
  username,
  id,
  pageNo,
  setRole,
  givenRole,
  handleRoleSearch,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [copy, setCopy] = useState([]);
  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);
  const [validPwd, setValidPwd] = useState(true);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setEditModal(false);
  };

  useEffect(() => {
    if (id != null && editModal) {
      axiosInstance
        .get(`/agent/agents/${id}`)
        .then((res) => {
          console.log(res.data);
          axiosInstance
            .get('agent/precreate', {
              params: {
                agent: username.slice(0, -2),
                searchUser: username,
                type:
                  username.length > 8
                    ? 'MEMBER'
                    : username.length === 8
                    ? 'AGENT'
                    : username.length === 6
                    ? 'MASTER'
                    : username.length === 4
                    ? 'SENIOR'
                    : username.length === 2
                    ? 'VENDOR'
                    : 'COMPANY',
                // type: givenRole.toUpperCase(),
              },
            })
            .then((response) => {
              setMax(response.data.availableCredit + res.data.credit);
              setMin(response.data.assignedBalance);
            });
          const form = document.getElementById('editForm');
          form['name'].value = res.data.name;
          form['mobile'].value = res.data.mobile;
          form['credit'].value = res.data.credit;
          form['remark'].value = res.data.remarks;
          if (res.data.suspendAt) form['status'].value = 2;
          else if (res.data.status === true) form['status'].value = 1;
          else form['status'].value = 0;
        })
        .catch((err) => console.log(err));
      axiosInstance
        .get('agent/copies', {
          params: {
            agent: username.slice(0, -2),
            type:
              username.length === 2
                ? 'VENDOR'
                : username.length === 4
                ? 'SENIOR'
                : username.length === 6
                ? 'MASTER'
                : username.length === 8
                ? 'AGENT'
                : givenRole.toUpperCase(),
          },
        })
        .then((res) => {
          console.log(res.data);
          setCopy(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, editModal]);

  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);
    setValidPwd(true);
    console.log(e.target.status.value);
    const requestBody = {
      name: e.target.name.value !== '' ? e.target.name.value : null,
      status:
        e.target.status.value === '1' || e.target.status.value === '0'
          ? e.target.status.value
          : null,
      suspend: e.target.status.value === '2' ? '1' : null,
      mobile: e.target.mobile.value !== '' ? e.target.mobile.value : null,
      copy: e.target.copyFrom.value !== '' ? e.target.copyFrom.value : null,
      remarks: e.target.remark.value !== '' ? e.target.remark.value : null,
      credit: e.target.credit.value !== '' ? e.target.credit.value : null,
      password: e.target.pwd.value,
    };

    axiosInstance
      .patch(`/agent/agents/${id}`, qs.stringify(requestBody))
      .then((res) => {
        console.log(res);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setEditModal(false);

        if (handleRoleSearch) handleRoleSearch();
      })
      .catch((err) => {
        if (err.response && err.response.data.message === 'AG200') {
          setValidPwd(false);
        } else if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={editModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleEdit} id="editForm">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  Edit {AgentUtils.getCurrentLevel(username)}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Your Password</td>
                <td>
                  <input type="password" id="pwd" required />
                  {validPwd === false ? (
                    <font color="red">Invalid password</font>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Username:</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Name</td>
                <td>
                  <input type="text" id="name" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Status</td>
                <td>
                  <select name="" id="status">
                    <option value={1}>Active</option>
                    <option value={2}>Suspended</option>
                    <option value={0}>InActive</option>
                  </select>
                  <a className="text-danger">
                    **Disabling this agent will automatically disable all
                    downline agents/players.
                  </a>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Mobile</td>
                <td>
                  <input type="tel" id="mobile" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Credit</td>
                <td>
                  <input
                    type="text"
                    placeholder="0.00"
                    id="credit"
                    className="mr-1"
                    max={max}
                    min={min}
                  />
                  Min (Assigned Credit):{' '}
                  <CurrencyFormat
                    value={min}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                  , Available:{' '}
                  <CurrencyFormat
                    value={max}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Copy From</td>
                <td>
                  <select name="" id="copyFrom">
                    <option value="">None</option>
                    {copy.map((cpy, index) =>
                      cpy.val !== username ? (
                        <option value={cpy.val} key={index}>
                          {cpy.val}
                        </option>
                      ) : null
                    )}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea name="" id="remark" cols="30" rows="3"></textarea>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                      disabled={loading}
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Edit;
