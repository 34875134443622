import { makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import CreditLog from './CreditLog';
import Edit from './Edit';
import OperationLog from './OperationLog';
import Password from './Password';
import Setting from './Setting';
import ShareRate from './ShareRate';
import UserActionPwd from './UserActionPwd';
import CopySetting from '../pages/member/functions/CopySetting';
import AgentUtils from '../agentutils';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#bfbfbf',
  },
  borderSuspend: {
    backgroundColor: '#e1e1e1',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

// role is for the data received from the data and givenRole is for "agent , master etc. for functions"

const Table = ({
  User,
  role,
  givenRole,
  setRole,
  setSelectedRoleCopy,
  totalPage,
  pageNo,
  setPageNo,
  sumCredit,
  sumBalance,
  sumAvailableCredit,
  handleRoleSearch,
  tab,
  setTab,
  down,
}) => {
  const classes = useStyles();
  const history = useHistory();

  // modal for edit
  const [editModal, setEditModal] = useState(false);
  const [stakeModal, setStakeModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [actionModal, setActionModal] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [actionType, setActionType] = useState(null);
  // modal for password
  const [passwordModal, setPasswordModal] = useState(false);
  // for passing value inside edit function also in password modal
  const username = useRef('');
  // for passing user id in op log
  const memberId = useRef('');

  // handle Credit
  const handleCredit = () => {
    // window.open(`/credit?id=${memberId.current}`, 'blank');
    // window.location.href = `/credit?id=${memberId.current}`;
    // history.push(`/credit?id=${memberId.current}`);
    setTab(1);
  };
  // handle operation log
  const handleOpLog = () => {
    // window.open(`/operation?id=${memberId.current}`, 'blank');
    // window.location.href = `/operation?id=${memberId.current}`;
    // history.push(`/operation?id=${memberId.current}`);
    setTab(2);
  };
  // console.log('edit = ' + User.privUserEdit);

  return (
    <>
      {tab === 0 ? (
        <>
          <div className="table-responsive-sm mx-2">
            <table className="table table-sm table-hover table-bordered">
              <thead className="text-center thead-dark">
                {down ? (
                  <tr>
                    <th colSpan={11}>
                      {down} {AgentUtils.getDownLevel(down)} Listing
                    </th>
                  </tr>
                ) : (
                  <tr>
                    <th colSpan={11}>
                      {sessionStorage.getItem('me')} {givenRole} Listing
                    </th>
                  </tr>
                )}
                <tr>
                  <th>No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Login Id</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Given Credit</th>
                  <th scope="col">Available Credit</th>
                  <th scope="col">Cash Balance</th>
                  <th scope="col">Last Login Time</th>
                  <th scope="col">Create Time</th>
                  <th scope="col">Status</th>
                  <th scope="col">Function</th>
                </tr>
              </thead>
              {role.length > 0 ? (
                <tbody>
                  {role.map((info, index) => (
                    <tr
                      className={`${
                        info.status === true
                          ? info.suspendAt
                            ? classes.borderSuspend
                            : ''
                          : classes.borderInactive
                      }`}
                      key={index}
                    >
                      <td className="text-dark text-center">{index + 1}</td>
                      <td className="text-dark">{info.name}</td>
                      <td className="text-dark text-center">
                        {info.username.length <= 8 ? (
                          <a
                            className={`${classes.pointer} text-primary p-0`}
                            onClick={() => {
                              handleRoleSearch(null, 1, info.username);
                            }}
                          >
                            {info.username}
                          </a>
                        ) : (
                          info.username
                        )}
                      </td>
                      <td className="text-dark text-center">{info.currency}</td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={info.credit}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={
                            info.availableCredit !== undefined
                              ? info.availableCredit
                              : 0
                          }
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={
                            (info.cashBalance !== undefined
                              ? info.cashBalance
                              : 0) * -1
                          }
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td className="text-dark text-center">
                        {info.lastLogin == null ? (
                          '-'
                        ) : (
                          <>
                            {moment(info.lastLogin).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}{' '}
                          </>
                        )}
                      </td>
                      <td className="text-dark text-center">
                        {moment(info.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      {info.status === true ? (
                        info.suspendAt ? (
                          <td className="text-danger text-center">Suspended</td>
                        ) : (
                          <td className="text-dark text-center">Active</td>
                        )
                      ) : (
                        <td className="text-danger text-center">InActive</td>
                      )}
                      <td className="text-primary">
                        {User.privUser == null ||
                        (User.privUser === true &&
                          User.privUserEdit === true) ? (
                          <>
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                memberId.current = info.id;
                                username.current = info.username;
                                setEditModal(true);
                              }}
                            >
                              Edit
                            </button>
                            /
                          </>
                        ) : null}
                        {User.privUser == null ||
                        (User.privUser === true &&
                          User.privUserShare === true) ? (
                          <>
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                memberId.current = info.id;
                                username.current = info.username;
                                setStakeModal(true);
                              }}
                            >
                              Edit %
                            </button>
                            /
                          </>
                        ) : null}
                        {User.privUser == null ||
                        (User.privUser === true &&
                          User.privUserSetting === true) ? (
                          <>
                            <button
                              className="btn btn-sm btn-link text-primary p-0"
                              onClick={() => {
                                memberId.current = info.id;
                                username.current = info.username;
                                setSettingModal(true);
                              }}
                            >
                              Setting
                            </button>
                            /
                          </>
                        ) : null}
                        {User.privUser == null ||
                        (User.privUser === true &&
                          User.privUserSetting === true) ? (
                          <>
                            <button
                              className="btn btn-sm btn-link text-primary p-0"
                              onClick={() => {
                                memberId.current = info.id;
                                username.current = info.username;
                                setCopyModal(true);
                              }}
                            >
                              Copy Setting
                            </button>
                            /
                          </>
                        ) : null}
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            memberId.current = info.id;
                            handleCredit();
                          }}
                        >
                          Credit Log
                        </button>
                        /
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            memberId.current = info.id;
                            handleOpLog();
                          }}
                        >
                          Operation Log
                        </button>
                        {User.privUser == null ||
                        (User.privUser === true &&
                          User.privUserPassword === true) ? (
                          <>
                            /
                            <button
                              className="btn btn-sm btn-link text-primary p-0"
                              onClick={() => {
                                username.current = info.username;
                                setPasswordModal(true);
                              }}
                            >
                              Password
                            </button>
                          </>
                        ) : null}
                        {User.privUser == null ||
                        (User.privUser === true &&
                          User.privUserEdit === true) ? (
                          <>
                            /
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                setActionType('DELETE');
                                username.current = info.username;
                                setActionModal(true);
                              }}
                            >
                              Delete
                            </button>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
              <tfoot>
                <tr className="font-weight-bold">
                  <td className="text-dark text-center" colSpan="4">
                    Total
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumCredit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumAvailableCredit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={sumBalance}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td colSpan="4"></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <Pagination
            count={totalPage}
            onChange={handleRoleSearch}
            className={classes.pagination}
            page={pageNo}
          />

          {/* edit modal */}
          <Edit
            setRole={setRole}
            pageNo={pageNo}
            setPageNo={setPageNo}
            id={memberId.current}
            editModal={editModal}
            setEditModal={setEditModal}
            username={username.current}
            givenRole={givenRole}
            handleRoleSearch={handleRoleSearch}
          />
          {/* stakeModal */}
          <ShareRate
            id={memberId.current}
            pageNo={pageNo}
            setPageNo={setPageNo}
            stakeModal={stakeModal}
            setStakeModal={setStakeModal}
            username={username.current}
          />
          {/* settingModal */}
          <Setting
            setSettingModal={setSettingModal}
            pageNo={pageNo}
            setPageNo={setPageNo}
            settingModal={settingModal}
            id={memberId.current}
            username={username.current}
          />
          <CopySetting
            copyModal={copyModal}
            setCopyModal={setCopyModal}
            username={username.current}
            id={memberId.current}
            handleMemberSearch={handleRoleSearch}
            type={'AGENT'}
          />
          {/* password modal */}
          <Password
            passwordModal={passwordModal}
            pageNo={pageNo}
            setPageNo={setPageNo}
            setPasswordModal={setPasswordModal}
            username={username.current}
          ></Password>
          <UserActionPwd
            passwordModal={actionModal}
            setPasswordModal={setActionModal}
            username={username.current}
            type={actionType}
            handleRoleSearch={handleRoleSearch}
          ></UserActionPwd>
        </>
      ) : tab === 1 ? (
        <CreditLog id={memberId.current} setTab={setTab} User={User} />
      ) : tab === 2 ? (
        <OperationLog id={memberId.current} setTab={setTab} User={User} />
      ) : null}
    </>
  );
};

export default Table;
