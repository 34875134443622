import axiosInstance from '../../config';
import React from 'react';
import '../../styles/styles.css';
import Navbar from '../../components/Navbar';
import { toast } from 'react-toastify';
import qs from 'querystring';

const AdjustAgent = ( {User} ) => {
  const handleAdjust = (e) => {
    if (e != null) e.preventDefault();
    const requestBody = {
      username: document.getElementById('adjustId').value,
      level: document.getElementById('levelId').value,
    };
    axiosInstance
    .post('ops/adjustAgent', qs.stringify(requestBody))
    .then((res) => {
      toast.success('Success!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    })
    .catch((err) => {
      console.log(err);
    });
  }

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" className="form-inline" onSubmit={handleAdjust} id="form">
          <label
            htmlFor="login"
            className="text-body font-weight-bold mx-2"
          >
            Login Id{' '}
          </label>
          <input
            type="text"
            id="adjustId"
            className="form-control form-control-xs"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <select name="sts" id="levelId">
            <option value="AGENT">Agent</option>
            <option value="MASTER">Master</option>
            <option value="SENIOR">Senior</option>
            <option value="VENDOR">Vendor</option>
            <option value="">All</option>
          </select>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Adjust"
            className="btn btn-sm btn-primary px-3 py-1 "
          />
        </form>
      </div>
    </>
  );
};

export default AdjustAgent;
