import React from 'react';
import Utils from '../../utils';
import { useHistory } from 'react-router-dom';

const ReportNavbar = ({ isReportNavbar, role }) => {
  const history = useHistory();
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light py-0 border-bottom ${
        isReportNavbar !== true ? 'd-none' : 'd-block'
      }`}
      id="report-navbar"
    >
      <div>
        <div className="navbar-nav position-relative">
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/wl' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/wl')}
          >
            Win Lose
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/wlDetail' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/wlDetail')}
          >
            W/L Detail
          </button>
          { role === 'COMPANY' || role === 'VENDOR' ? (
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/wlPackage' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/wlPackage')}
            >
              W/L Package
            </button>
          ) : null }
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/wlType' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/wlType')}
          >
            Type W/L
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/win' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/win')}
          >
            Win
          </button>
          {/* <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/outstanding' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/outstanding')}
          >
            Outstanding
          </button> */}
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/out' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/out')}
          >
            Outstanding
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/outDetail' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/outDetail')}
          >
            Outstanding Detail
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/outType' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/outType')}
          >
            Type Outstanding
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/cancel' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/cancel')}
          >
            Cancel
          </button>
          {/* {role === 'COMPANY' || role === 'VENDOR' ? (
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/gamecategory' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/gamecategory')}
            >
              Game Category
            </button>
          ) : null} */}
          { role !== 'AGENT' ? (
            <>
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/sharerate' ? ' active' : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/sharerate')}
              >
                Share Rate
              </button>
            </>
          ) : null }
          {role === 'COMPANY' || role === 'VENDOR' ? (
            <>
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/consolidated' ? ' active' : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/consolidated')}
              >
                Balance W/L
              </button>
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/indisharerate' ? ' active' : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/indisharerate')}
                >
                  Share Rate2
              </button>
            </>
          ): null }
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/bigwinner' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/bigwinner')}
          >
            Big Winner
          </button>
          {/* <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/winlose' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/winlose')}
          >
            Win Lose
          </button> */}
        </div>
      </div>
    </nav>
  );
};

export default ReportNavbar;
