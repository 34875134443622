import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request } from 'http';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const convertType = (type) => {
  if (type === 'WE_REQUIRE') return 'We Require';
  return 'I Require';
};

const ShareRate = ({ stakeModal, setStakeModal, id, username }) => {
  const classes = useStyles();
  const [stakes, setStakes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setStakeModal(false);
  };
  const history = useHistory();
  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);
    const requestBody = {
      up: document.getElementById('upShare')
        ? document.getElementById('upShare').value
        : null,
      min: document.getElementById('minShare')
        ? document.getElementById('minShare').value
        : null,
      max: document.getElementById('maxShare')
        ? document.getElementById('maxShare').value
        : null,
      share: document.getElementById('share')
        ? document.getElementById('share').value
        : null,
      type: document.getElementById('type')
        ? document.getElementById('type').value
        : null,
    };

    if (requestBody.min != null && requestBody.min !== '') {
      if (parseFloat(requestBody.min) > parseFloat(requestBody.max)) {
        toast.error(
          'Error, Max value must not be bigger than min value [ > ' +
            requestBody.min +
            ' ] ',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return false;
      }
    }

    if (requestBody.type != null && requestBody.type === 'WE_REQUIRE') {
      if (parseFloat(requestBody.max) > parseFloat(requestBody.share)) {
        toast.error(
          'Error, Max value must not be bigger than share [ ' +
            requestBody.min +
            ' - ' +
            requestBody.share +
            ' ] ',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return false;
      }
      if (parseFloat(requestBody.max) > parseFloat(requestBody.up)) {
        toast.error(
          'Error, Max value must not be bigger than share [ ' +
            requestBody.min +
            ' - ' +
            requestBody.up +
            ' ] ',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          }
        );
        return false;
      }
    }

    axiosInstance
      .patch(`/agent/agents/${id}/agentShare`, qs.stringify(requestBody))
      .then((res) => {
        setError(null);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => {
        console.log(err);
        // if (err.response && err.response.status === 400) {
        //   toast.error('Error, please reload or try again', {
        //     position: 'top-right',
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //   });
        //   setError('Error, invalid value!');
        // } else
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (stakeModal === true) {
      axiosInstance
        .get(`/agent/agents/${id}/agentShare`)
        .then((res) => {
          // setMinShare(res.data.minSportShare);
          // setMaxShare(res.data.maxSportShare);
          // setUpShare(res.data.sportShareLimit);
          // setShare(res.data.sportShare);
          // setRole(res.data.role);
          // setUprole(res.data.uprole);
          console.log(res.data);
          setStakes(res.data);
          // document.getElementById('sportShare').value = res.data.sportShare;
        })
        .catch((err) => console.log(err));
    } else {
      setStakes(null);
      // setMinShare(null);
      // setMaxShare(null);
      // setUpShare(null);
      // setShare(null);
      // setRole(null);
      // setUprole(null);
    }
  }, [stakeModal, id]);

  return (
    <Modal
      open={stakeModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form id="form" onSubmit={handleEdit}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="bg-primary text-light text-left font-weight-bold">
                  Edit % {username}
                </th>
              </tr>
            </thead>
          </table>
          <p />
          {stakes != null ? (
            <div className="table-responsive-sm mx-2">
              <table className="table table-sm table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th rowSpan="2" className="text-center thead-dark">
                      Market Type
                    </th>
                    {stakes.upMinLimit != null ? (
                      <th colSpan="2" className="text-center thead-dark">
                        Share Rate Limit
                      </th>
                    ) : null}
                    {stakes.sportShareLimit != null ? (
                      <th rowSpan="2" className="text-center thead-dark">
                        {stakes.uprole} Share Rate
                      </th>
                    ) : null}
                    {stakes.minSportShare != null ? (
                      <th colSpan="2" className="text-center thead-dark">
                        {stakes.role} Share Rate Limit
                      </th>
                    ) : null}
                    {stakes.sportShare != null ? (
                      <th rowSpan="2" className="text-center thead-dark">
                        {stakes.role} Share Rate
                      </th>
                    ) : null}
                  </tr>
                  {stakes.minSportShare != null || stakes.upMinLimit != null ? (
                    <tr>
                      {stakes.upMinLimit != null ? (
                        <>
                          <th className="text-center thead-dark">Min (%)</th>
                          <th className="text-center thead-dark">Max (%)</th>
                        </>
                      ) : null}
                      {stakes.minSportShare != null ? (
                        <>
                          <th className="text-center thead-dark">Min (%)</th>
                          <th className="text-center thead-dark">Max (%)</th>
                        </>
                      ) : null}
                    </tr>
                  ) : null}
                </thead>
                <tbody className={classes.tableBody}>
                  <tr>
                    <td className="text-dark text-center">Sport</td>
                    {stakes.upMinLimit != null ? (
                      <>
                        <td className="text-dark text-center">
                          {stakes.upMinLimit}
                        </td>
                        <td className="text-dark text-center">
                          {stakes.upMaxLimit}
                        </td>
                      </>
                    ) : null}
                    {stakes.sportShareLimit != null ? (
                      <td className="text-dark text-center">
                        {stakes.uprole === 'COMPANY' ? (
                          (stakes.upType
                            ? convertType(stakes.upType) + ' '
                            : null) + stakes.sportShareLimit
                        ) : (
                          <>
                            {stakes.type ? (
                              <select id="type" defaultValue={stakes.type}>
                                <option value="I_REQUIRE">I Require</option>
                                <option value="WE_REQUIRE">We Require</option>
                              </select>
                            ) : null}
                            <input
                              type="number"
                              size="5"
                              placeholder="0.00"
                              id="upShare"
                              defaultValue={stakes.sportShareLimit}
                              style={{ width: '50px' }}
                            />
                          </>
                        )}
                      </td>
                    ) : null}
                    {stakes.minSportShare != null ? (
                      <>
                        <td className="text-dark text-center">
                          {/* {stakes.uprole === 'COMPANY' ? stakes.minSportShare : ( */}
                          <input
                            size="5"
                            type="number"
                            id="minShare"
                            defaultValue={stakes.minSportShare}
                            style={{ width: '50px' }}
                          />
                          {/* )} */}
                        </td>
                        <td className="text-dark text-center">
                          {/* {stakes.uprole === 'COMPANY' ? stakes.maxSportShare : ( */}
                          <input
                            size="5"
                            type="number"
                            id="maxShare"
                            defaultValue={stakes.maxSportShare}
                            style={{ width: '50px' }}
                          />
                          {/* )} */}
                        </td>
                      </>
                    ) : null}
                    {stakes.sportShare != null ? (
                      <td className="text-dark text-center">
                        {stakes.uprole === 'VENDOR' ? (
                          <select id="type" defaultValue={stakes.type}>
                            <option value="I_REQUIRE">I Require</option>
                            <option value="WE_REQUIRE">We Require</option>
                          </select>
                        ) : null}
                        &nbsp;
                        <input
                          type="number"
                          size="1"
                          placeholder="0.00"
                          id="share"
                          defaultValue={stakes.sportShare}
                          style={{ width: '50px' }}
                        />
                      </td>
                    ) : null}
                  </tr>
                  <tr>
                    <td colSpan="8">
                      <div className="d-flex justify-content-end">
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-sm btn-primary mx-3 px-3 py-1"
                          disabled={loading}
                        />
                        <button
                          className="btn btn-sm btn-danger px-3 py-1"
                          onClick={() => {
                            setStakeModal(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}
          &nbsp;
          <p />
        </form>
      </div>
    </Modal>
  );
};

export default ShareRate;
