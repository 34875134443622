import React, { useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import qs from 'querystring';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const UserActionPwd = ({
  passwordModal,
  setPasswordModal,
  username,
  type,
  handleRoleSearch,
}) => {
  const [validPwd, setValidPwd] = useState(true);
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => {
    setPasswordModal(false);
  };

  // handle action
  const handlePassword = (e) => {
    e.preventDefault();
    setValidPwd(true);

    const isValidate = e.target.pwd.value.length > 0;
    if (isValidate === true) {
      const requestBody = {
        password: e.target.pwd.value,
      };
      let url = '/agent/' + username;
      if (type === 'SUSPEND') url += '/suspend';
      else if (type === 'UNSUSPEND') url += '/unsuspend';
      else if (type === 'DELETE') url += '/delete';
      axiosInstance
        .post(url, qs.stringify(requestBody))
        .then((res) => {
          if (res.status === 200) {
            console.log(type + ' action :', res);
            toast.success('Success!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setPasswordModal(false);
            if (handleRoleSearch) handleRoleSearch();
          }
        })
        .catch((err) => {
          if (err.response && err.response.data.message === 'AG200') {
            setValidPwd(false);
          } else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  return (
    <Modal
      open={passwordModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handlePassword}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  {type === 'SUSPEND'
                    ? 'Suspend Accounts (Including Downlines and Subs)'
                    : type === 'UNSUSPEND'
                    ? 'Unsuspend Selected Account'
                    : type === 'DELETE'
                    ? 'Delete Selected Account'
                    : null}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Username</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Your Password</td>
                <td>
                  <input type="password" id="pwd" required />
                  {validPwd === false ? (
                    <font color="red">Invalid password</font>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setPasswordModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default UserActionPwd;
