import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/Navbar';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import CurrencyFormat from 'react-currency-format';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const RollbackList = ( {User}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [rollback, setRollback] = useState([]);
  const [selected, setSelected] = useState(null);
  const [rollbackDetail, setRollbackDetail] = useState(null);
  const [detail, setDetail] = useState(null);
  const [pageNo, setPageNo] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalDelta, setTotalDelta] = useState(0);
  const history = useHistory();

  const backToParent = () => {
    setDetail(null);
    setRollbackDetail(null);
    handleChange(null);
    setTotalAmount(0);
    setTotalAmount(0);
    setTotalDelta(0);
  }
  
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (value == null && pageNo != null) value = pageNo;
    else if (value == null) value = 1;
    axiosInstance
      .get('agent/rollbackRequest', {
        params: {
          date: date ? moment(date).format('YYYYMMDD') : null,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        setRollback(res.data.content);
        setTotalPage(res.data.totalPages);
        setPageNo(res.data.pageNumber + 1);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  }

  useEffect(() => {
    if (detail != null) {
      axiosInstance
        .get('agent/rollbackRequest/'+detail)
        .then((res) => {
          setRollbackDetail(res.data);
          let sumCount = 0;
          let sumAmount = 0;
          let sumDelta = 0;
          for (var i = 0; i < res.data.members.length; i++){
            sumCount += res.data.members[i].rollbackCount;
            sumAmount += res.data.members[i].rollbackAmount;
            sumDelta += res.data.members[i].rollbackDelta;
          }
          setTotalCount(sumCount);
          setTotalAmount(sumAmount);
          setTotalDelta(sumDelta);
        })
        .catch((err) => {
          setDetail(null);
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  }, [detail]);

  useEffect(() => {
    handleChange(null);
  }, [date]);

  useEffect(() => {
    if (date == null){
      axiosInstance
        .get('public/today')
        .then((res) => {
          let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          today.setDate(today.getDate());
          if (date == null) setDate(today);
          let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
          minSearchDate.setDate(minSearchDate.getDate() - 90);
          setSearchDate(minSearchDate);
      });
    }
  }, [history.location]);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" id="form">
          Date:
          <DatePicker
            selected={date ? date : new Date()}
            minDate={searchDate}
            onChange={(dt) => setDate(dt)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
        </form>
      </div>
      {/* table */}
      <div className="table-responsive-sm mx-2">
        {rollbackDetail == null ? (
          <>
            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th scope="col" rowSpan="2">No</th>
                  <th scope="col" rowSpan="2">Match</th>
                  <th scope="col" colSpan="3">First Half</th>
                  <th scope="col" colSpan="3">Full Time</th>
                  <th scope="col" rowSpan="2">By</th>
                  <th scope="col" rowSpan="2">Details</th>
                </tr>
                <tr>
                  <th>Before</th>
                  <th>After</th>
                  <th>Current</th>
                  <th>Before</th>
                  <th>After</th>
                  <th>Current</th>
                </tr>
              </thead>
              {rollback.length > 0 ? (
                <tbody>
                  {rollback.map((rb, index) => (
                    <tr>
                      <td className="text-center">{index+1}</td>
                      <td className="text-center">{rb.matchResult.match.competition.name}<br/>
                      {rb.matchResult.match.home.team.name} vs {rb.matchResult.match.away.team.name}</td>
                      <td className="text-center">{rb.fhHomeGoal != null? rb.fhHomeGoal + '-'+ rb.fhAwayGoal +' ('+(rb.fhStatus? 'Finished)': rb.fhCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                      <td className="text-center">{rb.newFhHomeGoal != null? rb.newFhHomeGoal + '-'+ rb.newFhAwayGoal +' ('+(rb.newFhStatus? 'Finished)': rb.newFhCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                      <td className="text-center">{rb.matchResult.fhHomeGoal != null? rb.matchResult.fhHomeGoal + '-'+ rb.matchResult.fhAwayGoal +' ('+(rb.matchResult.fhComplete? 'Finished)': rb.matchResult.fhCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                      <td className="text-center">{rb.ftHomeGoal != null? rb.ftHomeGoal + '-'+ rb.ftAwayGoal +' ('+(rb.ftStatus? 'Finished)': rb.ftCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                      <td className="text-center">{rb.newFtHomeGoal != null? rb.newFtHomeGoal + '-'+ rb.newFtAwayGoal +' ('+(rb.newFtStatus? 'Finished)': rb.newFtCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                      <td className="text-center">{rb.matchResult.ftHomeGoal != null? rb.matchResult.ftHomeGoal + '-'+ rb.matchResult.ftAwayGoal +' ('+(rb.matchResult.ftComplete? 'Finished)': rb.matchResult.ftCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                      <td className="text-center">{rb.createdBy}<br/>{moment(rb.createdAt).format('DD MMM YYYY HH:mm')}</td>
                      <td className="text-center">
                        <button
                          className="btn btn-link px-1 py-1 my-2 mx-2"
                          onClick={() => {
                            setSelected(rb);
                            setDetail(rb.pubId);
                          }}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ): null}

            </table>
            {totalPage && totalPage > 0? (
              <Pagination
                count={totalPage}
                onChange={handleChange}
                className={classes.pagination}
              />
            ) : null}
          </>
        ) : (
          <>
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2 mx-2"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th colSpan={4}>Summary</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><strong>Match</strong></td>
                  <td colSpan={3}>{selected.matchResult.match.competition.name}<br/>
                      {selected.matchResult.match.home.team.name} vs {selected.matchResult.match.away.team.name}</td>
                </tr>
                {selected.fhHomeGoal != null? (
                  <tr>
                    <td className><strong>First half</strong></td>
                    <td className="text-center">Before Rollback: {selected.fhHomeGoal != null? selected.fhHomeGoal + '-'+ selected.fhAwayGoal +' ('+(selected.fhStatus? 'Finished)': selected.fhCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                    <td className="text-center">After Rollback: {selected.newFhHomeGoal != null? selected.newFhHomeGoal + '-'+ selected.newFhAwayGoal +' ('+(selected.newFhStatus? 'Finished)': selected.newFhCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                    <td className="text-center">Current: {selected.matchResult.fhHomeGoal != null? selected.matchResult.fhHomeGoal + '-'+ selected.matchResult.fhAwayGoal +' ('+(selected.matchResult.fhComplete? 'Finished)': selected.matchResult.fhCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                  </tr>
                ) : null }
                {selected.ftHomeGoal != null? (
                  <tr>
                    <td><strong>Full Time</strong></td>
                    <td className="text-center">Before Rollback: {selected.ftHomeGoal != null? selected.ftHomeGoal + '-'+ selected.ftAwayGoal +' ('+(selected.ftStatus? 'Finished)': selected.ftCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                    <td className="text-center">After Rollback: {selected.newFtHomeGoal != null? selected.newFtHomeGoal + '-'+ selected.newFtAwayGoal +' ('+(selected.newFtStatus? 'Finished)': selected.newFtCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                    <td className="text-center">Current: {selected.matchResult.ftHomeGoal != null? selected.matchResult.ftHomeGoal + '-'+ selected.matchResult.ftAwayGoal +' ('+(selected.matchResult.ftComplete? 'Finished)': selected.matchResult.ftCancel? 'Cancelled)' : 'Running)') : '-'}</td>
                  </tr>
                ) : null }
                <tr>
                  <td><strong>Negative Members:</strong></td>
                  <td className="text-center">{rollbackDetail.rollbackNegativeMember}</td>
                  <td><strong>Affected Bet(s):</strong></td>
                  <td className="text-center">{rollbackDetail.rollbackCount}</td>
                </tr>
              </tbody>
            </table>

            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th colSpan={9}>Members</th>
                </tr>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Username</th>
                  <th>Currency</th>
                  <th>Current Balance</th>
                  <th>Status</th>
                  <th>No of Rollback</th>
                  <th>Rollback Bet Amount</th>
                  <th>Rollback Winning</th>
                </tr>
              </thead>
              {rollbackDetail.members.length > 0 ? (
                <tbody>
                  {rollbackDetail.members.map((member, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{member.name}</td>
                      <td className="text-center">{member.username}</td>
                      <td className="text-center">{member.currency}</td>
                      <td className="text-center">
                        <CurrencyFormat
                            value={member.balance}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                            className={member.balance < 0? 'text-danger': null}
                          />
                      </td>
                      <td className="text-center">{member.status}</td>
                      <td className="text-right">{member.rollbackCount}</td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={member.rollbackAmount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={member.rollbackDelta}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          className={member.rollbackDelta < 0? 'text-danger': null}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
              <tfoot>
                <tr>
                  <td className="text-dark text-center" colSpan={6}>
                    Total
                  </td>
                  <td className="text-right">{totalCount}</td>
                  <td className="text-right">
                    <CurrencyFormat
                      value={totalAmount}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="text-right">
                    <CurrencyFormat
                      value={totalDelta}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                      className={totalDelta < 0? 'text-danger': null}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </>
        )}
      </div>
    </>
  );
};

export default RollbackList;
