import axiosInstance from '../../config';
import React from 'react';
import '../../styles/styles.css';
import Navbar from '../../components/Navbar';
import { toast } from 'react-toastify';
import qs from 'querystring';

const RecalculateBet = ({ User }) => {
  const handleAdjust = (e) => {
    if (e != null) e.preventDefault();
    const requestBody = {
      betId: document.getElementById('adjustBetId').value,
    };
    axiosInstance
      .post('ops/insertBetTx', qs.stringify(requestBody))
      .then((res) => {
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMissingTrx = (e) => {
    e.preventDefault();
    axiosInstance
      .post('agent/clearMissingTx')
      .then((res) => {
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form
          action=""
          className="form-inline"
          onSubmit={handleAdjust}
          id="form"
        >
          <label htmlFor="login" className="text-body font-weight-bold mx-2">
            Bet Id{' '}
          </label>
          <input
            type="text"
            id="adjustBetId"
            className="form-control form-control-xs"
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input
            type="submit"
            value="Recalculate"
            className="btn btn-sm btn-primary px-3 py-1 "
          />
        </form>
      </div>
      <label
        htmlFor="clearMissingTrx"
        className="text-body font-weight-bold mx-4 py-0 my-0"
      >
        Clear Missing Transaction :
      </label>
      <button
        id="clearMissigTrx"
        onClick={handleMissingTrx}
        className="btn btn-sm btn-primary px-3 py-1 mx-2"
      >
        Submit
      </button>
    </>
  );
};

export default RecalculateBet;
