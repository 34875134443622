import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Create = ({
  open,
  setOpen,
  type,
  number,
  setAgent,
  currency,
  down,
  refresh,
}) => {
  const classes = useStyles();
  const [copy, setCopy] = useState([]);
  const [max, setMax] = useState(null);
  const [ids, setId] = useState([]);
  const [loading, setLoading] = useState(false);
  // array for options
  const number2 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const history = useHistory();
  useEffect(() => {
    if (open) {
      // getting prefix for popup modal
      if (type !== 'Vendor') {
        axiosInstance
          .get('agent/uplines', {
            params: {
              type: type === 'Member' ? null : type.toUpperCase(),
              searchUser: down,
            },
          })
          .then((res) => {
            console.log(res);
            setId(res.data);
            if (type === 'Member') {
              axiosInstance
                .get('agent/members/copies', {
                  params: {
                    agent: document.getElementById('create-prefix').value,
                  },
                })
                .then((resp) => {
                  console.log(resp.data);
                  setCopy(resp.data);
                  var no1 = document.getElementById('no1');
                  var no2 = document.getElementById('no2');
                  var no3 = document.getElementById('no3');
                  for (var i = 0; i < no1.length; i++) {
                    for (var j = 0; j < no2.length; j++) {
                      for (var k = 0; k < no3.length; k++) {
                        let usr =
                          down + no1[i].value + no2[j].value + no3[k].value;
                        if (!resp.data.find((el) => el.val === usr)) {
                          document.getElementById('no1').selectedIndex = i;
                          document.getElementById('no2').selectedIndex = j;
                          document.getElementById('no3').selectedIndex = k;
                          k = no3.length;
                          j = no2.length;
                          i = no1.length;
                        }
                      }
                    }
                  }
                })
                .catch((err) => console.log(err));
            }
            if (res.data.length > 0 && type !== 'Member') {
              handleCopy(null, res.data[0].val);
            }
          })
          .catch((err) => console.log(err));
      } else {
        handleCopy(null);
      }
    }
  }, [open]);

  const handleCreate = (e) => {
    e.preventDefault();
    setLoading(true);
    document.getElementById('createBtn').disabled = true;
    const form = document.getElementById('create-form');
    const prefix = form['create-prefix'].value;
    const no1 = form['no1'].value;
    const no2 = form['no2'].value;
    let no3 = '';
    if (form['no3']) no3 = form['no3'].value;
    const username = `${no1}${no2}${no3}`;
    const name = form['create-name'].value;
    const password = form['create-pass'].value;
    const passwordCfm = form['confirm-pass'].value;
    const myPassword = form['agentPass'].value;
    const credit = form['create-credit'] ? form['create-credit'].value : null;
    const copyDd = form['create-copy'].value;
    const status = form['create-status'].value;
    const mobile = form['create-mobile'].value;
    const remarks = form['create-remark'].value;

    if (password !== passwordCfm) {
      setLoading(false);
      toast.error('Password and confirm password does not match!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      document.getElementById('createBtn').disabled = false;
      return;
    }

    const requestBody = {
      prefix: prefix,
      username: username,
      name: name,
      password: password,
      mobile: mobile,
      copy: copyDd,
      status: status,
      remarks: remarks,
      credit: type === 'Member' ? null : credit,
      myPassword: myPassword,
      type: type === 'Member' ? null : type.toUpperCase(),
    };

    if (type === 'Member') {
      axiosInstance
        .post('agent/members', qs.stringify(requestBody))
        .then((res) => {
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          refresh(null, 1, down);
          setOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      axiosInstance
        .post('agent/agents', qs.stringify(requestBody))
        .then((res) => {
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          refresh(null, 1, down);
          // refreshing the agent and closing the modal
          setOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response && err.response.data.message === 'AG200') {
            toast.error('Invalid password!', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
          } else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    document.getElementById('createBtn').disabled = false;
  };

  const handleCopy = (e, val) => {
    let search = val;
    if (document.getElementById('create-prefix')) {
      search = document.getElementById('create-prefix').value;
    }
    // if (e) search = e.target.value;
    // console.log(search);
    if (type === 'Member') {
      // getting copy from
      axiosInstance
        .get('agent/members/copies', {
          params: {
            agent: search,
          },
        })
        .then((res) => {
          console.log(res.data);
          setCopy(res.data);
          var no1 = document.getElementById('no1');
          var no2 = document.getElementById('no2');
          var no3 = document.getElementById('no3');
          for (var i = 0; i < no1.length; i++) {
            for (var j = 0; j < no2.length; j++) {
              for (var k = 0; k < no3.length; k++) {
                let usr = down + no1[i].value + no2[j].value + no3[k].value;
                if (!res.data.find((el) => el.val === usr)) {
                  document.getElementById('no1').selectedIndex = i;
                  document.getElementById('no2').selectedIndex = j;
                  document.getElementById('no3').selectedIndex = k;
                  k = no3.length;
                  j = no2.length;
                  i = no1.length;
                }
              }
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      axiosInstance
        .get('agent/precreate', {
          params: {
            agent: search,
            type: type.toUpperCase(),
          },
        })
        .then((res) => {
          console.log(res.data);
          setMax(res.data.availableCredit);
          setCopy(res.data.list);
          var no1 = document.getElementById('no1');
          var no2 = document.getElementById('no2');
          for (var i = 0; i < no1.length; i++) {
            for (var j = 0; j < no2.length; j++) {
              let usr = (search ? search : '') + no1[i].value + no2[j].value;
              if (!res.data.list.find((el) => el.val === usr)) {
                document.getElementById('no1').selectedIndex = i;
                document.getElementById('no2').selectedIndex = j;
                j = no2.length;
                i = no1.length;
              }
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // closing the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form onSubmit={handleCreate} id="create-form">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  New {type}
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold ">Your Password</td>
                <td>
                  <input type="password" id="agentPass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Login Id</td>
                <td>
                  {type === 'Member' ? (
                    <>
                      <select name="" id="create-prefix" onChange={handleCopy}>
                        {down != null ? (
                          <option value={down}>{down}</option>
                        ) : (
                          <>
                            {ids.map((id, index) => (
                              <option value={id.val} key={index}>
                                {id.val}
                              </option>
                            ))}
                          </>
                        )}
                      </select>
                      <select name="" className="mx-2" id="no1">
                        {number2.map((option, index) => (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select name="" className="mx-2" id="no2">
                        {number2.map((option, index) => (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        ))}
                        <option value=""></option>
                      </select>
                      <select name="" className="mx-2" id="no3">
                        {number2.map((option, index) => (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        ))}
                        <option value=""></option>
                      </select>
                    </>
                  ) : (
                    <>
                      {type !== 'Vendor' ? (
                        <select
                          name=""
                          id="create-prefix"
                          onChange={handleCopy}
                        >
                          {down != null ? (
                            <option value={down}>{down}</option>
                          ) : (
                            <>
                              {ids.map((id, index) => (
                                <option value={id.val} key={index}>
                                  {id.val}
                                </option>
                              ))}
                            </>
                          )}
                        </select>
                      ) : (
                        <input type="hidden" id="create-prefix" value="" />
                      )}

                      <select name="" className="mx-2" id="no1">
                        {number.map((option, index) => (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select name="" className="mx-2" id="no2">
                        {number.map((option, index) => (
                          <option value={option} key={index}>
                            {option}
                          </option>
                        ))}
                        {/* {type !== 'Vendor' ? <option value=""></option> : null} */}
                      </select>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Name</td>
                <td>
                  <input type="text" id="create-name" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Password</td>
                <td>
                  <input type="password" id="create-pass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">
                  Confirm Password
                </td>
                <td>
                  <input type="password" id="confirm-pass" required />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Status</td>
                <td>
                  <select name="" id="create-status">
                    <option value={1}>Active</option>
                    <option value={0}>InActive</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Currency</td>
                <td>
                  <select name="" id="create-currency">
                    {currency.map((currency, i) => (
                      <option value="" key={i}>
                        {currency.val}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              {type !== 'Member' ? (
                <tr>
                  <td className="text-body font-weight-bold ">Given Credit</td>
                  <td>
                    <input
                      type="number"
                      max={max}
                      min={0}
                      placeholder="0.00"
                      id="create-credit"
                      required
                    />{' '}
                    {max != null ? <span>Max: {max}</span> : null}
                  </td>
                </tr>
              ) : null}
              <tr>
                <td className="text-body font-weight-bold ">Phone</td>
                <td>
                  <input type="tel" id="create-mobile" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea
                    name=""
                    id="create-remark"
                    cols="30"
                    rows="3"
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td className="text-body font-weight-bold ">Copy From</td>
                <td>
                  <select name="" id="create-copy">
                    <option value="">None</option>
                    {copy.map((cpy, index) => (
                      <option value={cpy.val} key={index}>
                        {cpy.val}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      id="createBtn"
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                      disabled={loading}
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Create;
