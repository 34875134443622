import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import DatePicker from 'react-datepicker';
import axiosInstance from '../../config';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router';

const BigWinner = ( {User}) => {
  const [user, setUser] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [newPlayer, setNewPlayer] = useState(null);
  const d = new Date();
  const history = useHistory();

  // handle Today
  const handleToday = () => {
    const date = new Date();
    axiosInstance
      .get('agent/bigWinnerReport', {
        params: {
          startDate: `${date.toLocaleDateString().replace(/\//g, '-')}`,
          endDate: `${date.toLocaleDateString().replace(/\//g, '-')}`,
          newPlayer: newPlayer,
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  };

  // handle yesterday
  const handleYesterday = () => {
    const date = new Date();
    const yesterday = new Date(date.setDate(date.getDate() - 1));
    axiosInstance
      .get('agent/bigWinnerReport', {
        params: {
          startDate: `${yesterday.toLocaleDateString().replace(/\//g, '-')}`,
          endDate: `${yesterday.toLocaleDateString().replace(/\//g, '-')}`,
          newPlayer: newPlayer,
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  };

  // handle form
  const handleForm = (e) => {
    e.preventDefault();
    const form = document.getElementById('form');
    const username = form['username'].value;
    axiosInstance
      .get('agent/bigWinnerReport', {
        params: {
          username: username !== '' ? username : null,
          startDate: '01-01-2019',
          endDate: '31-08-2021',
          newPlayer: newPlayer,
        },
      })
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (startDate != null && endDate != null) {
      let search = document.getElementById('member-id').value;
      axiosInstance
        .get('agent/bigWinnerReport', {
          params: {
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            username: search ? search : null,
            newPlayer: newPlayer,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const getToday = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        today.setDate(today.getDate() - minus);
        setStartDate(today);
        setEndDate(today);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getWeek = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let diff = today.getDay() - 1;
        if (diff < 0) diff += 7;
        today.setDate(today.getDate() - diff);
        if (minus > 0){
          today.setTime(today.getTime() - minus * 7 * 24*60*60*1000);
          today2.setTime(today.getTime() + 6 * 24*60*60*1000);
        }
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  const getMonth = (minus) => {
    axiosInstance
      .get('public/today')
      .then((res) => {
        setStartDate(null);
        setEndDate(null);
        let today = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        let today2 = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        if (minus > 0) {
          today.setMonth(today.getMonth() - minus);
          if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
          today2.setDate(minus - 1);
        }
        today.setDate(1);
        setStartDate(today);
        setEndDate(today2);
        setMaxDate(new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2)));
        let minSearchDate = new Date(res.data.substring(6),parseInt(res.data.substr(3,2))-1, res.data.substring(0,2));
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
    });
  }

  useEffect(() => {
    if (startDate != null && endDate != null) {
      handleChange(null);
    }
  }, [startDate, endDate, newPlayer]);

  useEffect(()=> {
    if (startDate == null || endDate == null) {
      axiosInstance.get('public/today').then((res) => {
        if (startDate == null)
          setStartDate(
            new Date(
              res.data.substring(6),
              parseInt(res.data.substr(3, 2)) - 1,
              res.data.substring(0, 2)
            )
          );
        if (endDate == null)
          setEndDate(
            new Date(
              res.data.substring(6),
              parseInt(res.data.substr(3, 2)) - 1,
              res.data.substring(0, 2)
            )
          );
        setMaxDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
        let minSearchDate = new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        );
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
      });
    }
  }, [history.location])

  return (
    <>
      <Navbar User={User}/>
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="bw-form">
          Start Date:
          <DatePicker
            selected={startDate}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <label htmlFor="member-id mx-2">Login ID: </label>
          <input type="text" id="member-id" className="mx-2" />
          <select
            name=""
            id="type"
            style={{ minWidth: '100px' }}
            className="py-1 "
            onChange={(newFlag) => setNewPlayer(newFlag.target.value)}
          >
            <option value="">All Players</option>
            <option value="Y">New Players</option>
            <option value="N">Old Players</option>
          </select>
          <input
            type="submit"
            value="Get Report"
            className="btn btn-sm btn-primary px-1 py-1 mx-1"
          />
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
        </form>
      </div>
      {/* table for big winner  */}
      {user !== null ? (
        <>
          <div className="table-responsive-sm mx-2">
            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Login ID</th>
                  <th>Currency</th>
                  <th>Turnover</th>
                  <th>Win</th>
                </tr>
              </thead>
              <tbody>
                {user.map((info, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      {info.name !== undefined ? info.name : ''}
                    </td>
                    <td className="text-center">{info.username}</td>
                    <td className="text-center">
                      {info.currency !== undefined ? info.currency : ''}
                    </td>
                    <td className="text-right">
                      <CurrencyFormat
                        value={info.turnover}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-right">
                      <CurrencyFormat
                        value={info.win}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BigWinner;
