import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BetList from '../../components/BetList';
import Navbar from '../../components/Navbar';
import axiosInstance from '../../config';
import moment from 'moment';
import { useEffect } from 'react';

export default function MissingTrx({ User }) {
  const [date, setDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [running, setRunning] = useState(null);
  const [game, setGame] = useState(null);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [bets, setBets] = useState([]);
  const [games, setGames] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const history = useHistory();

  const calculateBet = (report) => {
    let wager = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning += report[i].winning;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning);
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (value == null) value = 1;
    let betUrl = 'agent/listMissingTx';
    axiosInstance
      .get(betUrl, {
        params: {
          // startDate: date ? moment(date).format('DD-MM-YYYY') : null,
          running: running ? running : null,
          game: game ? game : null,
         //   pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        setBets(res.data);
        calculateBet(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    handleChange(null);
  }, [date]);

  useEffect(() => {
    if (date == null) {
      axiosInstance.get('public/today').then((res) => {
        let today = new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        );
        today.setDate(today.getDate());
        if (date == null) setDate(today);
        let minSearchDate = new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        );
        minSearchDate.setDate(minSearchDate.getDate() - 90);
        setSearchDate(minSearchDate);
      });
    }
    if (games.length === 0) {
      axiosInstance.get('agent/gameTypes').then((res) => {
        setGames(res.data);
      });
    }
  }, [history.location]);

  return (
    <>
      <Navbar User={User} />
      <div className='d-flex justify-content-end'>
        <button className='btn btn-sm btn-primary mr-3 mt-4' onClick={() => {
          window.location.reload()
        }}>Refresh</button>
      </div>
      {/* table */}
      <div className="table-responsive-sm mx-2">
        <BetList
          startDate={date}
          endDate={date}
          bets={bets}
          handleChange={handleChange}
          playerWager={playerWager}
          playerWinning={playerWinning}
          totalPage={totalPage}
          username={User.username}
        />
      </div>
    </>
  );
}
