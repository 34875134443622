import React from 'react';
import Utils from '../../utils';
import { useHistory } from 'react-router-dom';

const AdjustmentNavBar = ({ isAdjustmentNavbar, role }) => {
  const history = useHistory();
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light py-0 border-bottom ${
        isAdjustmentNavbar !== true ? 'd-none' : 'd-block'
      }`}
      id="bal-navbar"
    >
      <div>
        <div className="navbar-nav position-relative">
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/recalculateList' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/recalculateList')}
          >
            Listing
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/recalculate' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/recalculate')}
          >
            Create Adjustment
          </button>
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/writeoff' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/writeoff')}
          >
            Write Off
          </button>
        </div>
      </div>
    </nav>
  );
};

export default AdjustmentNavBar;
