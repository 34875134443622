import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AgentUtils from '../agentutils';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const CommWd = ({ depositModal, setDepositModal, username, type, refresh }) => {
  const classes = useStyles();
  const handleClose = () => {
    setDepositModal(false);
  };
  const [selected, setSelected] = useState(null);
  const [validPwd, setValidPwd] = useState(true);
  const [reportDate, setReportDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [loading, setLoading] = useState(false);
  // handle Deposit
  const handleDeposit = (e) => {
    e.preventDefault();
    setValidPwd(true);
    setLoading(true);
    let requestBody = {
      username: username,
      type: type, // WITHDRAW
      amount: e.target.deposit.value,
      password: document.getElementById('deposit-pwd').value,
      remarks: document.getElementById('deposit-remark').value,
      date: reportDate ? moment(reportDate).format('DD-MM-YYYY') : null,
    };
    axiosInstance
      .post('/agent/commTxns', qs.stringify(requestBody))
      .then((res) => {
        if (res) {
          console.log(res);
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setDepositModal(false);
          refresh(null);
          setLoading(false);
        }
        // update members
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (username != null && depositModal === true) {
      // console.log('type = '+type);
      let url = '/agent/commTxns?type=' + type + '&username=' + username;
      if (reportDate != null)
        url += '&date=' + moment(reportDate).format('DD-MM-YYYY');
      axiosInstance
        .get(url)
        .then((res) => {
          console.log(res);
          setSelected(res.data);
        })
        .catch((err) => console.log(err));
      if (reportDate == null) {
        axiosInstance
          .get('public/today')
          .then((res) => {
            setReportDate(
              new Date(
                res.data.substring(6),
                parseInt(res.data.substr(3, 2)) - 1,
                res.data.substring(0, 2)
              )
            );
            setMaxDate(
              new Date(
                res.data.substring(6),
                parseInt(res.data.substr(3, 2)) - 1,
                res.data.substring(0, 2)
              )
            );
            let minSearchDate = new Date(
              res.data.substring(6),
              parseInt(res.data.substr(3, 2)) - 1,
              res.data.substring(0, 2)
            );
            minSearchDate.setDate(minSearchDate.getDate() - 90);
            setSearchDate(minSearchDate);
          })
          .catch((err) => console.log(err));
      }
    } else if (depositModal === false) {
      setSelected([]);
      setReportDate(null);
      setSearchDate(null);
      setMaxDate(null);
    }
  }, [username, depositModal, reportDate]);

  return (
    <>
      <Modal
        open={depositModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle}
      >
        {selected != null ? (
          <div className={classes.Modal}>
            <form action="" onSubmit={handleDeposit}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      colSpan="2"
                      className="bg-primary text-light font-weight-bold"
                    >
                      {type}
                    </th>
                  </tr>
                </thead>
                <tbody className={classes.tableBody}>
                  <tr>
                    <td className="text-body font-weight-bold">
                      Your Password
                    </td>
                    <td>
                      <input type="password" id="deposit-pwd" required />
                      {validPwd === false ? (
                        <font color="red">Invalid password</font>
                      ) : null}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-body font-weight-bold">Login Id</td>
                    <td>{selected ? selected.username : null}</td>
                  </tr>
                  <tr>
                    <td className="text-body font-weight-bold">Name</td>
                    <td>{selected ? selected.name : null}</td>
                  </tr>
                  <tr>
                    <td className="text-body font-weight-bold">Beginning</td>
                    <td>
                      <CurrencyFormat
                        value={selected ? selected.beginning : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={
                          selected.beginning < 0 ? 'text-danger' : null
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-body font-weight-bold">Balance</td>
                    <td>
                      <CurrencyFormat
                        value={selected ? selected.balance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-body font-weight-bold">Amount</td>
                    <td>
                      <input
                        type="number"
                        id="deposit"
                        max={
                          selected.limit
                            ? selected.limit > 0
                              ? selected.limit
                              : 0
                            : 0
                        }
                        step={0.01}
                        required
                      />
                      {selected !== null ? (
                        <label htmlFor="deposit" className="ml-2">
                          {' '}
                          Max:{' '}
                          <CurrencyFormat
                            value={
                              selected.limit
                                ? selected.limit > 0
                                  ? selected.limit
                                  : 0
                                : 0
                            }
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </label>
                      ) : null}
                    </td>
                  </tr>
                  {AgentUtils.isVendorOrCompanyOrSubOf(
                    sessionStorage.getItem('me')
                  ) ? (
                    <tr>
                      <td className="text-body font-weight-bold">Date</td>
                      <td>
                        <DatePicker
                          selected={reportDate}
                          minDate={searchDate}
                          maxDate={maxDate}
                          onChange={(date) => setReportDate(date)}
                          className="mx-2"
                          dateFormat="d MMM yyyy"
                        />
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td className="text-body font-weight-bold ">Remark</td>
                    <td>
                      <textarea
                        name=""
                        id="deposit-remark"
                        cols="30"
                        rows="3"
                      ></textarea>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="2">
                      <div className="d-flex justify-content-end">
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-sm btn-primary mx-3 px-3 py-1"
                          disabled={loading}
                        />

                        <button
                          className="btn btn-sm btn-danger px-3 py-1"
                          onClick={() => {
                            setDepositModal(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        ) : null}
      </Modal>
    </>
  );
};

export default CommWd;
