import axiosInstance from '../../config';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';
import Cash from '../../components/Cash';
import DepWd from '../../components/DepWd';
import CommWd from '../../components/CommWd';
import CreditLogM from '../member/functions/CreditLogM';
import BetList from '../../components/BetList';
import Details from './Details';
import AgentUtils from '../../agentutils';
import Pagination from '@material-ui/lab/Pagination';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  borderInactive: {
    backgroundColor: '#bfbfbf',
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  borderSuspend: {
    backgroundColor: '#e1e1e1',
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  border: {
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const WinLose = ({ User, type, opt }) => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [cashModal, setCashModal] = useState(false);
  const [depositModal, setDepositModal] = useState(false);
  const [commModal, setCommModal] = useState(false);
  const [depositType, setDepositType] = useState(null);
  const [commType, setCommType] = useState(null);
  const [cashType, setCashType] = useState(false);
  const [selected, setSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [reports2, setReports2] = useState([]);
  const [detailUsername, setDetailUsername] = useState(null);
  const [details, setDetails] = useState([]);
  const [totalWl, setTotalWl] = useState(0);
  const [totalBeg, setTotalBeg] = useState(0);
  const [totalComm, setTotalComm] = useState(0);
  const [totalDep, setTotalDep] = useState(0);
  const [totalWd, setTotalWd] = useState(0);
  const [totalWo, setTotalWo] = useState(0);
  const [totalBal, setTotalBal] = useState(0);
  const [totalBalComm, setTotalBalComm] = useState(0);
  const [totalOutstanding, setTotalOutstanding] = useState(0);
  const [tab, setTab] = useState(0);
  const [bets, setBets] = useState([]); // outstanding
  const [transactionType, setTransactionType] = useState(null);
  const [transactions, setTransactions] = useState([]); // deposit/withdraw/netWL
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerTurnover, setPlayerTurnover] = useState(0);
  const [playerComm, setPlayerComm] = useState(0);
  const [playerComm2, setPlayerComm2] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [processing, setProcessing] = useState(false);
  const memberId = useRef('');
  const history = useHistory();

  const backToParent = () => {
    if (detailUsername != null) {
      setDetails([]);
      setDetailUsername(null);
    } else if (
      username != null &&
      username.length > 0 &&
      username !== sessionStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };

  const handleCredit = (usr, usrname) => {
    console.log(usrname.length);
    if (usrname.length > 8) {
      setTab(1);
    } else {
      setTab(2);
    }
  };

  const handleComm = (usr, usrname) => {
    console.log('comm cash log = ' + usrname.length);
    if (usrname.length > 8) {
      setTab(6);
    } else {
      setTab(7);
    }
  };

  const showMore = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/balanceReport/' + transactionType, {
        params: {
          username: selected,
          transactionType: 1,
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        let sumAmt = 0;
        for (let i = 0; i < res.data.content.length; i++) {
          sumAmt += res.data.content[i].amount;
        }
        // console.log(res.data.content);
        setTransactions(res.data.content);
        setTotalPage(res.data.totalPages);
        setTotalTransactions(sumAmt);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const showNetwl = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/balanceReport/netwl', {
        params: {
          username: selected,
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        setBets(res.data.content);
        calculateBet(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const showOutstanding = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/balanceReport/outstanding', {
        params: {
          username: selected,
          pageNo: isNaN(value) ? null : value - 1,
        },
      })
      .then((res) => {
        setBets(res.data.content);
        calculateBet(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  const calculateBet = (report) => {
    let wager = 0,
      turnover = 0,
      comm = 0,
      comm2 = 0,
      winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      turnover += report[i].turnover;
      comm += report[i].grossComm;
      comm2 += report[i].commission;
      if (report[i].winning) winning += report[i].winning;
    }
    setPlayerComm2(comm2);
    setPlayerWager(wager);
    setPlayerTurnover(turnover);
    setPlayerComm(comm);
    setPlayerWinning(winning + comm2);
  };

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const adjustAgent = (username) => {
    setProcessing(true);
    axiosInstance
      .get('agent/balReport/adjustAgent?username=' + username)
      .then((res) => {
        handleChange();
        console.log('success');
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (tab === 1 || tab === 2 || tab === 6 || tab === 7) {
      return;
    } else if (tab === 3) {
      showMore(null, 1);
      return;
    } else if (tab === 4) {
      showOutstanding(null, 1);
      return;
    } else if (tab === 5) {
      showNetwl(null, 1);
      return;
    }
    if (username != null && username.length > 8) return;
    if (detailUsername != null) {
      setReports([]);
      axiosInstance
        .get('agent/balanceReport/winLoseDetail', {
          params: {
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            username: detailUsername,
            tx: type === 'Comm' ? false : true,
          },
        })
        .then((res) => {
          setDetails(res.data);
          let sumWl = 0,
            sumComm = 0,
            sumDep = 0,
            sumWd = 0,
            sumBal = 0,
            sumBalComm = 0,
            sumWo = 0,
            sumBeg = 0;
          for (let i = 0; i < res.data.length; i++) {
            sumWl += res.data[i].netWinLose;
            if (res.data[i].beginning) sumBeg += res.data[i].beginning;
            sumComm += res.data[i].winCommission;
            sumDep += res.data[i].deposit;
            sumWd += res.data[i].withdraw;
            sumBal += res.data[i].balance;
            sumWo += res.data[i].writeoff;
            sumBalComm +=
              res.data[i].beginning +
              res.data[i].winCommission -
              res.data[i].withdraw;
          }
          setTotalWo(sumWo);
          setTotalWl(sumWl);
          setTotalBeg(sumBeg);
          setTotalComm(sumComm);
          setTotalDep(sumDep);
          setTotalWd(sumWd);
          setTotalBal(sumBal);
          setTotalBalComm(sumBalComm);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else {
      setLoading(true);
      let wlUrl = 'agent/balanceReport/winLose';
      if (opt) {
        wlUrl += '2';
      }
      setReports([]);
      axiosInstance
        .get(wlUrl, {
          params: {
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            agentUsername: username ? username : null,
            tx: type === 'Comm' ? false : true,
            pageNo: isNaN(value) ? null : value - 1,
          },
        })
        .then((res) => {
          setReports(res.data.content);
          let sumWl = 0,
            sumComm = 0,
            sumDep = 0,
            sumWd = 0,
            sumBal = 0,
            sumBalComm = 0,
            sumOutstanding = 0,
            sumWo = 0,
            sumBeg = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            if (res.data.content[i].beginning)
              sumBeg += res.data.content[i].beginning;
            sumWl += res.data.content[i].netWinLose;
            sumComm += res.data.content[i].winCommission;
            sumDep += res.data.content[i].deposit;
            sumWd += res.data.content[i].withdraw;
            if (res.data.content[i].writeoff)
              sumWo += res.data.content[i].writeoff;
            if (res.data.content[i].balance)
              sumBal += res.data.content[i].balance;
            if (res.data.content[i].outstanding)
              sumOutstanding += res.data.content[i].outstanding;
            sumBalComm +=
              res.data.content[i].beginning != null
                ? res.data.content[i].beginning +
                  res.data.content[i].winCommission +
                  res.data.content[i].withdraw
                : res.data.content[i].winCommission +
                  res.data.content[i].withdraw;
          }
          setTotalWo(sumWo);
          setTotalWl(sumWl);
          setTotalBeg(sumBeg);
          setTotalComm(sumComm);
          setTotalDep(sumDep);
          setTotalWd(sumWd);
          setTotalBal(sumBal);
          setTotalBalComm(sumBalComm);
          setTotalOutstanding(sumOutstanding);
          setTotalPage(res.data.totalPages);

          // if (User.agentType != null && User.agentType === 'COMPANY') {
          //   validateWL();
          // }
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const validateReport = async () => {
    let url =
      'agent/balReport/consolidated?startDate=' +
      moment(startDate).format('DD-MM-YYYY') +
      '&endDate=' +
      moment(endDate).format('DD-MM-YYYY') +
      '&username=';

      const batchSize = 5;

      const processBatch = async (batch) => {
        return Promise.all(batch.map(async (report) => {
            axiosInstance
            .get(url + report.username)
            .then((res) => {
              let balance = res.data.balance;
              let comm = res.data.comm;
              let pl = res.data.reportWin + res.data.reportComm;
              console.log('sum1 = ' + balance.netWinLose);
              console.log('sum2 = ' + comm.winCommission);
              console.log('sum3 = ' + pl);
              document.getElementById('valid3' + balance.username).innerHTML = (
                pl +
                balance.netWinLose +
                comm.winCommission
              ).toFixed(2);
            })
            .catch((err) => {
              console.log(err);
              if (err.response && err.response.status === 401) {
                history.push('/');
              }
            })
        }));
      };

      for (let i = 0; i < reports.length; i += batchSize) {
        const batch = reports.slice(i, i + batchSize);
        await processBatch(batch);
      }
      setLoading(false);

    // https://api.amarchen.com/agent/balReport/consolidated?username=aa&startDate=12-09-2021&endDate=12-09-2021
  };

  const validateWL = () => {
    console.log('validate WL');
    if (type === 'Comm') return;
    let wlUrl = 'agent/balanceReport/winLose';
    if (opt) {
      wlUrl += '2';
    }
    setReports2([]);

    let validStartDate = new Date();
    let validEndDate = new Date();
    // console.log('startDate = '+startDate);
    if (startDate != null) {
      validEndDate.setTime(startDate.getTime() - 24 * 60 * 60000);
    }
    let diffTime = Math.abs(endDate - startDate);
    // console.log('diffTime = '+diffTime);
    if (diffTime > 1209600000) diffTime = 1209600000;
    if (diffTime < 604800000) diffTime = 604800000;
    // console.log('diffTime = '+diffTime);
    // console.log('endDate = '+endDate);
    // console.log('startDate = '+startDate);

    // let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // console.log('diffDays = '+diffDays);
    // console.log('d = '+(validEndDate.getDate() - diffDays));
    validStartDate.setTime(validEndDate.getTime() - diffTime);

    // console.log('validStartDate = '+moment(validStartDate).format('DD-MM-YYYY'));
    // console.log('validEndDate = '+moment(validEndDate).format('DD-MM-YYYY'));
    setLoading2(true);

    axiosInstance
      .get(wlUrl, {
        params: {
          startDate: validStartDate
            ? moment(validStartDate).format('DD-MM-YYYY')
            : null,
          endDate: validEndDate
            ? moment(validEndDate).format('DD-MM-YYYY')
            : null,
          agentUsername: username ? username : null,
          tx: type === 'Comm' ? false : true,
        },
      })
      .then((res) => {
        setReports2(res.data.content);
        for (var i = 0; i < res.data.content.length; i++) {
          var num = parseFloat(
            document.getElementById('valid' + res.data.content[i].username)
              .innerText
          ).toFixed(2);
          // console.log(
          //   'num = '+parseFloat(document.getElementById('valid'+res.data.content[i].username).innerText).toFixed(2)
          // );
          if (num == res.data.content[i].balance.toFixed(2)) {
            document.getElementById(
              'valid2' + res.data.content[i].username
            ).innerHTML = '<font><strong>Valid</strong></font>';
          } else {
            document.getElementById(
              'valid2' + res.data.content[i].username
            ).innerHTML =
              '<font color="red"><strong>' +
              res.data.content[i].balance +
              ',' +
              document.getElementById('valid' + res.data.content[i].username)
                .innerText +
              '</strong></font>';
            // console.log('pf = '+document.getElementById('valid'+res.data.content[i].username).innerHTML);
            // console.log('bal = '+res.data.content[i].balance);
          }
        }
        // console.log(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(() => {
        setLoading2(false);
      });
  };

  useEffect(() => {
    if (startDate != null && endDate != null) handleChange(null);
  }, [username, startDate, endDate, detailUsername]);

  useEffect(() => {
    if (tab === 3) {
      showMore(null, 1);
    } else if (tab === 4) {
      showOutstanding(null, 1);
    } else if (tab === 5) {
      showNetwl(null, 1);
    } else if (tab === 0) {
      handleChange(null);
    } else {
      setTransactions([]);
      setBets([]);
    }
  }, [tab]);

  useEffect(() => {
    let usr = User.username;
    if (usr.indexOf('@') == -1) setUsername(usr);
    else {
      setUsername(usr.split('@')[1]);
    }
    axiosInstance.get('public/today').then((res) => {
      if (startDate == null)
        setStartDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      if (endDate == null)
        setEndDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  }, [history.location]);

  // UI component
  const renderDateSearch = (retBtn) => {
    return (
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={(e) => e.preventDefault()} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-1"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-1"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              handleChange(null);
            }}
          >
            Get Report
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
          {User.agentType != null && User.agentType === 'COMPANY' ? (
            <>
              <button
                className="btn btn-sm btn-danger px-3 py-1"
                onClick={() => {
                  validateReport();
                  validateWL();
                }}
              >
                Validate
              </button>{' '}
            </>
          ) : null}
          {retBtn ? (
            <button
              className="btn btn-sm btn-secondary px-3 py-1"
              onClick={() => {
                tab === 0 ? backToParent() : setTab(0);
              }}
            >
              Return
            </button>
          ) : null}
          {loading ? (
            <>
              <br />
              <img
                src={require('../../img/loadingAnimation.gif')}
                height={13}
                width={208}
                alt=""
              />
            </>
          ) : null}
          {loading2 ? (
            <>
              <br />
              <img
                src={require('../../img/loadingAnimation.gif')}
                height={13}
                width={208}
                alt=""
              />
            </>
          ) : null}
        </form>
      </div>
    );
  };

  return (
    <>
      <Navbar User={User} />
      {!processing && (
        <>
          {tab === 0 ? (
            <>
              {renderDateSearch(
                detailUsername != null ||
                  (username != null &&
                    username.length > 0 &&
                    username !== sessionStorage.getItem('me') &&
                    !sessionStorage.getItem('me').endsWith(username))
              )}
              {/* {detailUsername != null ||
          (username != null &&
            username.length > 0 &&
            username !== sessionStorage.getItem('me') &&
            !sessionStorage.getItem('me').endsWith(username)) ? (
            <button
              className="btn btn-sm btn-secondary px-3 py-1 mx-1"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
          ) : null} */}
              {detailUsername == null ? (
                <div className="table-responsive-sm mx-2">
                  <table className="table table-sm table-hover table-bordered">
                    <thead className="text-center thead-dark">
                      <tr>
                        <th colSpan={type !== 'Comm' ? 13 : 9}>
                          {username
                            ? ' ' + username
                            : ' ' + sessionStorage.getItem('me')}{' '}
                          {AgentUtils.getDownLevel(
                            username != null && username.length > 0
                              ? username
                              : sessionStorage.getItem('me')
                          )}{' '}
                          {type !== 'Comm' ? 'Balance' : 'Gross Comm'} Report [
                          {moment(startDate).format('DD/MMM/YYYY') +
                            ' - ' +
                            moment(endDate).format('DD/MMM/YYYY')}
                          ]
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Login ID</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Beginning</th>
                        {type !== 'Comm' ? (
                          <>
                            <th scope="col">Net W/L</th>
                            <th scope="col">Deposit</th>
                            <th scope="col">Withdraw</th>
                            {/* {username != null && username.length === 8? (
                          <th scope="col">Adjustment</th>
                        ) : null} */}
                            <th scope="col">Balance</th>
                          </>
                        ) : (
                          <>
                            <th scope="col">Commission</th>
                            <th scope="col">Withdraw</th>
                            <th scope="col">Balance</th>
                          </>
                        )}

                        <th scope="col">Status</th>
                        <th scope="col">Function</th>
                        {type !== 'Comm' ? (
                          <th scope="col">Outstanding</th>
                        ) : null}
                        {User.agentType != null &&
                        User.agentType === 'COMPANY' &&
                        type !== 'Comm' ? (
                          <>
                            <th scope="col">Valid Beginning</th>
                            <th scope="col">Valid Report</th>
                          </>
                        ) : null}
                      </tr>
                    </thead>
                    {reports.length > 0 ? (
                      <tbody>
                        {reports.map((info, index) => (
                          <tr
                            className={`${
                              info.status === true
                                ? info.suspendAt
                                  ? classes.borderSuspend
                                  : classes.border
                                : classes.borderInactive
                            }`}
                            key={index}
                          >
                            {/* <tr key={index}> */}
                            <td className="text-dark text-left">
                              {info.name}&nbsp;&nbsp;
                              {User.agentType != null &&
                              User.agentType === 'COMPANY'
                                ? [
                                    <button
                                      className={`${classes.pointer} text-primary p-0 btn-link btn`}
                                      disabled={processing}
                                      onClick={() => {
                                        adjustAgent(info.username);
                                      }}
                                    >
                                      Adjust
                                    </button>,
                                  ]
                                : null}
                            </td>
                            <td className="text-dark text-center">
                              {(info.username.length > 8 && type !== 'Comm') ||
                              (info.username.length === 8 &&
                                type === 'Comm') ? (
                                info.username
                              ) : (
                                <a
                                  className={`${classes.pointer} text-primary p-0`}
                                  onClick={() => {
                                    memberId.current = info.id;
                                    setUsername(info.username);
                                  }}
                                >
                                  {info.username}
                                </a>
                              )}
                            </td>
                            <td className="text-dark text-center">
                              {info.currency}
                            </td>

                            {type !== 'Comm' ? (
                              <>
                                <td
                                  className={`${
                                    info.beginning < 0 ? 'text-danger ' : ''
                                  }text-right`}
                                >
                                  <CurrencyFormat
                                    value={info.beginning ? info.beginning.toFixed(2) : 0}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td
                                  className={`${
                                    info.netWinLose < 0 ? 'text-danger ' : ''
                                  }text-right`}
                                >
                                  {info.countBet > 0 ? (
                                    <a
                                      className={`${classes.pointer} p-0 ${
                                        info.netWinLose < 0
                                          ? 'text-danger '
                                          : 'text-primary'
                                      }`}
                                      onClick={() => {
                                        setSelected(info.username);
                                        setTab(5);
                                      }}
                                    >
                                      <u>
                                        <CurrencyFormat
                                          value={
                                            info.netWinLose
                                              ? info.netWinLose.toFixed(2)
                                              : 0
                                          }
                                          decimalScale={2}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                        />
                                      </u>
                                    </a>
                                  ) : (
                                    '0.00'
                                  )}
                                </td>
                                <td className="text-right">
                                  {info.deposit === 0 ? (
                                    '0.00'
                                  ) : (
                                    <button
                                      className={`btn btn-sm btn-link p-0 ${
                                        info.deposit < 0
                                          ? 'text-danger '
                                          : 'text-primary'
                                      }`}
                                      onClick={() => {
                                        setSelected(info.username);
                                        setTransactionType('deposits');
                                        setTab(3);
                                      }}
                                    >
                                      <u>
                                        <CurrencyFormat
                                          value={
                                            info.deposit ? info.deposit : 0
                                          }
                                          decimalScale={2}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                        />
                                      </u>
                                    </button>
                                  )}
                                </td>
                                {/* <td
                            className={`${
                              info.deposit < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.deposit ? info.deposit : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td> */}
                                <td className="text-right">
                                  {info.withdraw === 0 ? (
                                    '0.00'
                                  ) : (
                                    <button
                                      className={`btn btn-sm btn-link p-0 ${
                                        info.withdraw < 0
                                          ? 'text-danger '
                                          : 'text-primary'
                                      }`}
                                      onClick={() => {
                                        setSelected(info.username);
                                        setTransactionType('withdraws');
                                        setTab(3);
                                      }}
                                    >
                                      <u>
                                        <CurrencyFormat
                                          value={
                                            info.withdraw ? info.withdraw : 0
                                          }
                                          decimalScale={2}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                        />
                                      </u>
                                    </button>
                                  )}
                                </td>
                                {/* {username != null && username.length === 8 ? (
                              <td className="text-right">
                                {info.writeoff === 0 ? (
                                  '0.00'
                                ) : (
                                  <CurrencyFormat
                                    value={info.writeoff ? info.writeoff : 0}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    className={info.writeoff < 0? 'text-danger' : null}
                                  />
                                )}
                              </td>
                            ) : null} */}
                                {/* <td
                            className={`${
                              info.withdraw < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.withdraw ? info.withdraw : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td> */}
                                <td
                                  className={`${
                                    info.balance < 0 ? 'text-danger ' : ''
                                  }text-right`}
                                >
                                  <CurrencyFormat
                                    value={
                                      info.balance != null
                                        ? info.balance.toFixed(2)
                                        : 0
                                    }
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                              </>
                            ) : (
                              <>
                                <td
                                  className={`${
                                    info.beginning < 0 ? 'text-danger ' : ''
                                  }text-right`}
                                >
                                  <CurrencyFormat
                                    value={info.beginning ? info.beginning : 0}
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td
                                  className={`${
                                    info.winCommission < 0 ? 'text-danger ' : ''
                                  }text-right`}
                                >
                                  <CurrencyFormat
                                    value={
                                      info.winCommission
                                        ? info.winCommission
                                        : 0
                                    }
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                                <td className="text-right">
                                  {info.withdraw === 0 ? (
                                    '0.00'
                                  ) : (
                                    <button
                                      className={`btn btn-sm btn-link p-0 ${
                                        info.withdraw < 0
                                          ? 'text-danger '
                                          : 'text-primary'
                                      }`}
                                      onClick={() => {
                                        setSelected(info.username);
                                        setTransactionType('comms');
                                        setTab(3);
                                      }}
                                    >
                                      <u>
                                        <CurrencyFormat
                                          value={
                                            info.withdraw ? info.withdraw : 0
                                          }
                                          decimalScale={2}
                                          displayType={'text'}
                                          thousandSeparator={true}
                                          fixedDecimalScale={true}
                                        />
                                      </u>
                                    </button>
                                  )}
                                </td>
                                {/* <td
                            className={`${
                              info.withdraw < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.withdraw ? info.withdraw : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td> */}
                                <td
                                  className={`${
                                    info.beginning +
                                      info.winCommission +
                                      info.withdraw <
                                    0
                                      ? 'text-danger '
                                      : ''
                                  }text-right`}
                                >
                                  <CurrencyFormat
                                    value={
                                      info.beginning +
                                      info.winCommission +
                                      info.withdraw
                                        ? info.beginning +
                                          info.winCommission +
                                          info.withdraw
                                        : 0
                                    }
                                    decimalScale={2}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                </td>
                              </>
                            )}

                            {info.status === true ? (
                              info.suspendAt ? (
                                <td className="text-danger text-center">
                                  Suspended
                                </td>
                              ) : (
                                <td className="text-dark text-center">
                                  Active
                                </td>
                              )
                            ) : (
                              <td className="text-danger text-center">
                                InActive
                              </td>
                            )}
                            <td>
                              {type !== 'Comm' ? (
                                <>
                                  {info.username.length <= 8 ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-link text-primary p-0"
                                        onClick={() => {
                                          setDetailUsername(info.username);
                                          // viewDetail(info.username);
                                        }}
                                      >
                                        Detail
                                      </button>
                                      &nbsp;|&nbsp;
                                      {User.privBalReport == null ||
                                      (User.privBalReport === true &&
                                        User.privBalReportDw === true) ? (
                                        <>
                                          <button
                                            className="btn btn-sm btn-link text-primary p-0"
                                            onClick={() => {
                                              setSelected(info.username);
                                              setCashType('Cash Out');
                                              setCashModal(true);
                                            }}
                                          >
                                            Deposit
                                          </button>
                                          &nbsp;|&nbsp;
                                          <button
                                            className="btn btn-sm btn-link text-primary p-0"
                                            onClick={() => {
                                              setSelected(info.username);
                                              setCashType('Cash In');
                                              setCashModal(true);
                                            }}
                                          >
                                            Withdraw
                                          </button>
                                          &nbsp;|&nbsp;
                                        </>
                                      ) : null}
                                    </>
                                  ) : User.privBalReport == null ||
                                    (User.privBalReport === true &&
                                      User.privBalReportDw === true) ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-link text-primary p-0"
                                        onClick={() => {
                                          setSelected(info.username);
                                          setDepositType('DEPOSIT');
                                          setDepositModal(true);
                                        }}
                                      >
                                        Deposit
                                      </button>
                                      &nbsp;|&nbsp;
                                      <button
                                        className="btn btn-sm btn-link text-primary p-0"
                                        onClick={() => {
                                          setSelected(info.username);
                                          setDepositType('WITHDRAW');
                                          setDepositModal(true);
                                        }}
                                      >
                                        Withdraw
                                      </button>
                                      &nbsp;|&nbsp;
                                    </>
                                  ) : null}
                                  <button
                                    className="btn btn-sm btn-link text-primary p-0"
                                    onClick={() => {
                                      memberId.current = info.id;
                                      handleCredit(info.id, info.username);
                                    }}
                                  >
                                    Cash log
                                  </button>
                                </>
                              ) : (
                                // gross comm
                                <>
                                  {info.username.length <= 8 ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-link text-primary p-0"
                                        onClick={() => {
                                          setDetailUsername(info.username);
                                        }}
                                      >
                                        Detail
                                      </button>
                                      &nbsp;|&nbsp;
                                      {User.privBalReport == null ||
                                      (User.privBalReport === true &&
                                        User.privBalReportDw === true) ? (
                                        <>
                                          <button
                                            className="btn btn-sm btn-link text-primary p-0"
                                            onClick={() => {
                                              setSelected(info.username);
                                              setCommType('TRANSFER_COMM');
                                              setCommModal(true);
                                            }}
                                          >
                                            Transfer
                                          </button>
                                          &nbsp;|&nbsp;
                                          <button
                                            className="btn btn-sm btn-link text-primary p-0"
                                            onClick={() => {
                                              setSelected(info.username);
                                              setCommType('WITHDRAW_COMM');
                                              setCommModal(true);
                                            }}
                                          >
                                            Withdraw
                                          </button>
                                          &nbsp;|&nbsp;
                                        </>
                                      ) : null}
                                    </>
                                  ) : User.privBalReport == null ||
                                    (User.privBalReport === true &&
                                      User.privBalReportDw === true) ? (
                                    <>
                                      <button
                                        className="btn btn-sm btn-link text-primary p-0"
                                        onClick={() => {
                                          setSelected(info.username);
                                          setCommType('TRANSFER_COMM');
                                          setCommModal(true);
                                        }}
                                      >
                                        Transfer
                                      </button>
                                      &nbsp;|&nbsp;
                                      <button
                                        className="btn btn-sm btn-link text-primary p-0"
                                        onClick={() => {
                                          setSelected(info.username);
                                          setCommType('WITHDRAW_COMM');
                                          setCommModal(true);
                                        }}
                                      >
                                        Withdraw
                                      </button>
                                      &nbsp;|&nbsp;
                                    </>
                                  ) : null}
                                  <button
                                    className="btn btn-sm btn-link text-primary p-0"
                                    onClick={() => {
                                      memberId.current = info.id;
                                      handleComm(info.id, info.username);
                                    }}
                                  >
                                    Cash log
                                  </button>
                                </>
                              )}
                            </td>
                            {type !== 'Comm' ? (
                              <td className="text-right">
                                {info.countOutstanding === 0 ? (
                                  '0.00'
                                ) : (
                                  <button
                                    className={`btn btn-sm btn-link p-0 ${
                                      info.outstanding < 0
                                        ? 'text-danger '
                                        : 'text-primary'
                                    }`}
                                    onClick={() => {
                                      setSelected(info.username);
                                      // setOutModal(true);
                                      setTab(4);
                                    }}
                                  >
                                    <u>
                                      <CurrencyFormat
                                        value={
                                          info.outstanding
                                            ? info.outstanding
                                            : 0
                                        }
                                        decimalScale={2}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                      />
                                    </u>
                                  </button>
                                )}
                              </td>
                            ) : null}
                            {User.agentType != null &&
                            User.agentType === 'COMPANY' &&
                            type !== 'Comm' ? (
                              <>
                                <td align="center">
                                  <span
                                    id={`valid${info.username}`}
                                    className="display-hidden"
                                  >
                                    {info.beginning}
                                  </span>
                                  <span id={`valid2${info.username}`}></span>
                                </td>
                                <td align="center">
                                  <span id={`valid3${info.username}`}></span>
                                </td>
                              </>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    ) : null}
                    {reports.length > 0 ? (
                      <tfoot>
                        <tr>
                          <td className="text-dark text-center" colSpan="3">
                            Total
                          </td>
                          {type !== 'Comm' ? (
                            <>
                              <td
                                className={`${
                                  totalBeg < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalBeg.toFixed(2)}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  totalWl < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalWl}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  totalDep < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalDep}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  totalWd < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalWd}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              {/* { username != null && username.length === 8? (
                        <td
                          className={`${
                            totalWo < 0 ? 'text-danger ' : ''
                          }text-right`}
                        >
                          <CurrencyFormat
                            value={totalWo}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        ) : null } */}
                              <td
                                className={`${
                                  totalBal < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalBal.toFixed(2)}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className="text-dark text-center"
                                colSpan="2"
                              ></td>
                              <td
                                className={`${
                                  totalOutstanding < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalOutstanding}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td
                                className={`${
                                  totalBeg < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalBeg.toFixed(2)}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  totalComm < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalComm}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  totalWd < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalWd}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  totalBalComm < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={totalBalComm}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className="text-dark text-center"
                                colSpan="3"
                              ></td>
                            </>
                          )}
                        </tr>
                      </tfoot>
                    ) : null}
                  </table>
                  {/* {totalPage && totalPage > 0? ( */}
                  <Pagination
                    count={totalPage}
                    onChange={handleChange}
                    className={classes.pagination}
                  />
                  {/* ) : null} */}
                </div>
              ) : null}
              {details.length > 0 ? (
                <div className="table-responsive-sm mx-2">
                  <table className="table table-sm table-hover table-bordered">
                    <thead className="text-center thead-dark">
                      <tr>
                        <th scope="col" colSpan={type !== 'Comm' ? 8 : 7}>
                          {detailUsername} Details [
                          {moment(startDate).format('DD/MMM/YYYY') +
                            ' - ' +
                            moment(endDate).format('DD/MMM/YYYY')}
                          ]
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Day</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Beginning</th>
                        {type !== 'Comm' ? (
                          <>
                            <th scope="col">Net W/L</th>
                            {/* <th scope="col">Gross Comm</th> */}
                            <th scope="col">Deposit</th>
                            <th scope="col">Withdraw</th>
                            <th scope="col">Balance</th>
                          </>
                        ) : (
                          <>
                            <th scope="col">Gross Comm</th>
                            <th scope="col">Withdraw</th>
                            <th scope="col">Balance</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {details.map((info, index) => (
                        <tr key={index}>
                          <td className="text-dark text-center">
                            {moment(info.reportDate).format('DD-MM-YYYY')}
                          </td>
                          <td className="text-dark text-center">
                            {moment(info.reportDate).format('ddd')}
                          </td>
                          <td className="text-dark text-center">
                            {info.currency}
                          </td>

                          {type !== 'Comm' ? (
                            <>
                              <td
                                className={`${
                                  info.beginning < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.beginning ? info.beginning.toFixed(2) : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.netWinLose < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.netWinLose ? info.netWinLose : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.deposit < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.deposit ? info.deposit : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.withdraw < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.withdraw ? info.withdraw : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.balance < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.balance ? info.balance : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                            </>
                          ) : (
                            <>
                              <td
                                className={`${
                                  info.beginning < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.beginning ? info.beginning.toFixed(2) : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.winCommission < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={
                                    info.winCommission ? info.winCommission : 0
                                  }
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.withdraw < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.withdraw ? info.withdraw : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                              <td
                                className={`${
                                  info.balance < 0 ? 'text-danger ' : ''
                                }text-right`}
                              >
                                <CurrencyFormat
                                  value={info.balance ? info.balance : 0}
                                  decimalScale={2}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  fixedDecimalScale={true}
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="text-dark text-center" colSpan="4">
                          Total
                        </td>
                        {type !== 'Comm' ? (
                          <>
                            <td
                              className={`${
                                totalWl < 0 ? 'text-danger ' : ''
                              }text-right`}
                            >
                              <CurrencyFormat
                                value={totalWl}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td
                              className={`${
                                totalDep < 0 ? 'text-danger ' : ''
                              }text-right`}
                            >
                              <CurrencyFormat
                                value={totalDep}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td
                              className={`${
                                totalWd < 0 ? 'text-danger ' : ''
                              }text-right`}
                            >
                              <CurrencyFormat
                                value={totalWd}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td
                              className={`${
                                totalComm < 0 ? 'text-danger ' : ''
                              }text-right`}
                            >
                              <CurrencyFormat
                                value={totalComm}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td
                              className={`${
                                totalWd < 0 ? 'text-danger ' : ''
                              }text-right`}
                            >
                              <CurrencyFormat
                                value={totalWd}
                                decimalScale={2}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                              />
                            </td>
                            <td></td>
                          </>
                        )}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ) : null}
              <Cash
                type={cashType}
                cashModal={cashModal}
                setCashModal={setCashModal}
                username={selected}
                refresh={handleChange}
              />
              <DepWd
                type={depositType}
                depositModal={depositModal}
                setDepositModal={setDepositModal}
                username={selected}
                refresh={handleChange}
              />
              <CommWd
                type={commType}
                depositModal={commModal}
                setDepositModal={setCommModal}
                username={selected}
                refresh={handleChange}
              />
            </>
          ) : tab === 1 ? (
            <>
              {renderDateSearch(true)}
              <CreditLogM
                startDate={startDate}
                endDate={endDate}
                id={memberId.current}
                setTab={setTab}
                tp={'members'}
                page={'cashLog'}
              />
            </>
          ) : tab === 2 ? (
            <>
              {renderDateSearch(true)}
              <CreditLogM
                startDate={startDate}
                endDate={endDate}
                id={memberId.current}
                setTab={setTab}
                tp={'agents'}
                page={'cashLog'}
              />
            </>
          ) : tab === 3 ? (
            <>
              {renderDateSearch(true)}
              {/* <button
            className="btn btn-sm btn-primary px-1 py-1 my-2 mx-2"
            onClick={() => {
              setTab(0);
            }}
          >
            Return
          </button> */}
              <Details
                transactions={transactions}
                handleChange={showMore}
                totalAmount={totalTransactions}
                totalPage={totalPage}
              />
            </>
          ) : tab === 4 ? (
            <>
              <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
                <button
                  className="btn btn-sm btn-secondary px-3 py-1"
                  onClick={() => {
                    setTab(0);
                  }}
                >
                  Return
                </button>
              </div>
              <div className="table-responsive-sm mx-2">
                <BetList
                  detailType={username}
                  startDate={startDate}
                  endDate={endDate}
                  username={User.username}
                  bets={bets}
                  handleChange={showOutstanding}
                  playerWager={playerWager}
                  totalTurnover={playerTurnover}
                  totalComm={playerComm}
                  agent={'Y'}
                  playerWinning={0}
                  totalPage={totalPage}
                  type={'OUTSTANDING'}
                />
              </div>
            </>
          ) : tab === 5 ? (
            <>
              <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
                <button
                  className="btn btn-sm btn-secondary px-3 py-1"
                  onClick={() => {
                    setTab(0);
                  }}
                >
                  Return
                </button>
              </div>
              <div className="table-responsive-sm mx-2">
                <BetList
                  detailType={username}
                  bets={bets}
                  startDate={startDate}
                  endDate={endDate}
                  username={User.username}
                  handleChange={showNetwl}
                  playerWager={playerWager}
                  totalTurnover={playerTurnover}
                  totalComm={playerComm2}
                  agent={'Y'}
                  playerWinning={playerWinning}
                  totalPage={totalPage}
                />
              </div>
            </>
          ) : tab === 6 ? (
            <>
              {renderDateSearch()}
              <CreditLogM
                startDate={startDate}
                endDate={endDate}
                id={memberId.current}
                setTab={setTab}
                tp={'members'}
                page={'grossComm'}
              />
            </>
          ) : tab === 7 ? (
            <>
              {renderDateSearch()}
              <CreditLogM
                startDate={startDate}
                endDate={endDate}
                id={memberId.current}
                setTab={setTab}
                tp={'agents'}
                page={'grossComm'}
              />
            </>
          ) : null}
        </>
      )}
      {processing && (
        <>
          <br />
          <img
            src={require('../../img/loadingAnimation.gif')}
            height={13}
            width={208}
            alt=""
          />
        </>
      )}
    </>
  );
};

export default WinLose;
