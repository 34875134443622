import axiosInstance from '../../config';
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';
import SecDw from '../../components/SecDw';
import AgentUtils from '../../agentutils';

const useStyles = makeStyles((theme) => ({
  border: {
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  borderInactive: {
    backgroundColor: '#e1e1e1',
    '& td': {
      borderBottom: '1px solid black !important',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const Security = ({ User }) => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [cashModal, setCashModal] = useState(false);
  const [cashType, setCashType] = useState(false);
  const [selected, setSelected] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [detailUsername, setDetailUsername] = useState(null);
  const [details, setDetails] = useState([]);
  const [totalDep, setTotalDep] = useState(0);
  const [totalBal, setTotalBal] = useState(0);
  const [tab, setTab] = useState(0);
  const memberId = useRef('');
  const history = useHistory();
  const backToParent = () => {
    if (detailUsername != null) {
      setDetails([]);
      setDetailUsername(null);
    } else if (
      username != null &&
      username.length > 0 &&
      username !== sessionStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (username != null && username.length > 8) return;
    if (detailUsername != null) {
      // setReports([]);
      axiosInstance
        .get('agent/balanceReport/security/' + detailUsername, {
          params: {
            start: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
            end: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          },
        })
        .then((res) => {
          setDetails(res.data.content);
          console.log('details len = ' + res.data.length);
          let sumDep = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            sumDep += res.data.content[i].amount;
          }
          setTotalDep(sumDep);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else {
      axiosInstance
        .get('agent/balanceReport/security', {
          params: {
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            username: username ? username : null,
          },
        })
        .then((res) => {
          setReports(res.data.content);
          let sumBal = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            sumBal += res.data.content[i].balance;
          }
          setTotalBal(sumBal);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  useEffect(() => {
    if (startDate != null && endDate != null) handleChange(null);
  }, [username, startDate, endDate, detailUsername]);

  useEffect(() => {
    if (tab === 0) {
      setDetailUsername(null);
      setDetails([]);
    }
  }, [tab]);

  useEffect(() => {
    let usr = User.username;
    if (usr.indexOf('@') == -1) setUsername(usr);
    else {
      setUsername(usr.split('@')[1]);
    }
    getMonth(0);
  }, [history.location]);

  // UI component
  const renderDateSearch = () => {
    return (
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
        </form>
      </div>
    );
  };

  return (
    <>
      <Navbar User={User} />
      {tab === 0 ? (
        <>
          {/* {renderDateSearch()} */}
          <div className="d-flex justify-content-between my-3 px-3 align-items-center "></div>
          {username != null &&
          username.length > 0 &&
          username !== sessionStorage.getItem('me') &&
          !sessionStorage.getItem('me').endsWith(username) ? (
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2 mx-2"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
          ) : null}
          {reports.length > 0 ? (
            <div className="table-responsive-sm mx-2">
              <table className="table table-sm table-hover table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" colSpan={7}>
                      {username ? username : sessionStorage.getItem('me')}{' '}
                      {AgentUtils.getDownLevel(
                        username != null && username.length > 0
                          ? username
                          : sessionStorage.getItem('me')
                      )}{' '}
                      Security
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Name</th>
                    <th scope="col">Login ID</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Status</th>
                    <th scope="col">Function</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((info, index) => (
                    <tr
                      className={`${
                        info.status === true
                          ? classes.border
                          : classes.borderInactive
                      }`}
                      key={index}
                    >
                      {/* <tr key={index}> */}
                      <td className="text-dark text-left">{index + 1}</td>
                      <td className="text-dark text-left">{info.name}</td>
                      <td className="text-dark text-center">
                        <a
                          className={`${classes.pointer} text-primary p-0`}
                          onClick={() => {
                            memberId.current = info.id;
                            setUsername(info.username);
                          }}
                        >
                          {info.username}
                        </a>
                      </td>
                      <td className="text-dark text-center">{info.currency}</td>
                      <td className={`text-right`}>
                        <CurrencyFormat
                          value={info.balance ? info.balance : 0}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          className={info.balance < 0 ? 'text-danger' : ''}
                        />
                      </td>
                      {info.status === true ? (
                        <td className="text-dark text-center">Active</td>
                      ) : (
                        <td className="text-danger text-center">InActive</td>
                      )}
                      <td>
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            setDetailUsername(info.username);
                            setTab(1);
                            // viewDetail(info.username);
                          }}
                        >
                          Detail
                        </button>
                        &nbsp;|&nbsp;
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            setSelected(info.username);
                            setCashType('deposit');
                            setCashModal(true);
                          }}
                        >
                          Deposit
                        </button>
                        &nbsp;|&nbsp;
                        <button
                          className="btn btn-sm btn-link text-primary p-0"
                          onClick={() => {
                            setSelected(info.username);
                            setCashType('withdraw');
                            setCashModal(true);
                          }}
                        >
                          Withdraw
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-weight-bold">
                    <td className="text-dark text-center" colSpan="4">
                      Total
                    </td>
                    <td
                      className={`${
                        totalBal < 0 ? 'text-danger ' : ''
                      }text-right`}
                    >
                      <CurrencyFormat
                        value={totalBal}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-dark text-center" colSpan="2"></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ) : null}

          <SecDw
            type={cashType}
            cashModal={cashModal}
            setCashModal={setCashModal}
            username={selected}
            refresh={handleChange}
          />
        </>
      ) : tab === 1 ? (
        <>
          {renderDateSearch()}
          <button
            className="btn btn-sm btn-primary px-1 py-1 my-2 mx-2"
            onClick={() => {
              setTab(0);
            }}
          >
            Return
          </button>

          <div className="table-responsive-sm mx-2">
            {detailUsername} Details
            <table className="table table-sm table-hover table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Name</th>
                  <th scope="col">Login ID</th>
                  <th scope="col">Operator</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Balance</th>
                  <th scope="col">Remark</th>
                </tr>
              </thead>
              {details.length > 0 ? (
                <tbody>
                  {details.map((info, index) => (
                    <tr key={index}>
                      <td>
                        {moment(info.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td>{info.name}</td>
                      <td>{info.username}</td>
                      <td>{info.operator}</td>
                      <td>{info.currency}</td>
                      <td>{info.transactionType}</td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={info.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          className={info.amount < 0 ? 'text-danger' : null}
                        />
                      </td>
                      <td>
                        <CurrencyFormat
                          value={info.balance}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          className={info.balance < 0 ? 'text-danger' : null}
                        />
                      </td>
                      <td>{info.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
              <tfoot>
                <tr className="font-weight-bold">
                  <td className="text-dark text-center" colSpan="6">
                    Total
                  </td>
                  <td
                    className={`${
                      totalDep < 0 ? 'text-danger ' : ''
                    }text-right`}
                  >
                    <CurrencyFormat
                      value={totalDep}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td colSpan="2"></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Security;
