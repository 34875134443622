import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { Link, useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Utils from '../../utils';

const Dashboard = ({User}) => {
  const [detail, setDetail] = useState(null);
  const [operationLog, setOperationLog] = useState();
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [totalWl, setTotalWl] = useState(0);
  const [totalDep, setTotalDep] = useState(0);
  const [totalWd, setTotalWd] = useState(0);
  const [tab, setTab] = useState(0);
  const [type, setType] = useState(null);
  const [totalComm, setTotalComm] = useState(0);
  const history = useHistory();
  const handleOpLog = (e) => {
    if (e != null) e.preventDefault();
    let url = 'agent/balanceReport/creditInfo';
    if (type === 'Comm') url = 'agent/balanceReport/commInfo';
    axiosInstance.get(url, {
        params: {
          startDate: startDate
            ? moment(startDate).format('DD-MM-YYYY')
            : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
        }
      })
      .then((res) => {
        setTransactions(res.data);
        let sumWl = 0,
          sumComm = 0,
          sumDep = 0,
          sumWd = 0;
        for (let i = 0; i < res.data.length; i++) {
          sumWl += res.data[i].netWinLose;
          sumComm += res.data[i].winCommission;
          sumDep += res.data[i].deposit;
          sumWd += res.data[i].withdraw;
        }
        setTotalWl(sumWl);
        setTotalComm(sumComm);
        setTotalDep(sumDep);
        setTotalWd(sumWd);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };
  useEffect(() => {
    axiosInstance
      .get('agent/dashboard')
      .then((res) => {
        setDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });

    // own op log
    axiosInstance
      .get('agent/agents/ownOperationLog', {
        params: {
          startDate: '19-11-2020',
          endDate: '19-11-2021',
          pageSize: '10',
        },
      })
      .then((res) => {
        setOperationLog(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getToday = (minus) => {
    axiosInstance.get('public/todayCal').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/todayCal').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/todayCal').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  useEffect(() => {
    axiosInstance.get('public/today').then((res) => {
      if (startDate == null)
        setStartDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            '01'
          )
        );
      if (endDate == null)
        setEndDate(
          new Date(
            res.data.substring(6),
            parseInt(res.data.substr(3, 2)) - 1,
            res.data.substring(0, 2)
          )
        );
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  }, [history.location]);

  useEffect(() => {
    if (startDate != null && endDate != null && tab === 1) handleOpLog();
  }, [startDate, endDate, tab]);

  return (
    <>
      <Navbar User={User} />

      {detail !== null && tab === 0 ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <h5>My Dashboard</h5>
          </div>

          <div className="row mt-3 px-3">
            {/* <div className="col-xs-6"> */}
            <div className="table-responsive-sm col-md-6">
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-blue">
                  <tr>
                    <th scope="col" colSpan={2}>
                      Credit Info &nbsp;&nbsp;&nbsp;
                      <Link
                        className="text-underline"
                        onClick={() => {
                          setType('tx');
                          setTab(1);
                          // handleOpLog(detail.id);
                        }}
                      >
                        <u>Detail</u>
                      </Link>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-dark text-center">Currency</td>
                    <td className="text-dark text-center">{detail.currency}</td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Beginning</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.beginning < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.beginning ? detail.creditSummary.beginning : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={detail.creditSummary.beginning < 0? 'text-danger': ''}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Net W/L</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.netWinLose < 0
                          ? ' text-danger'
                          : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.netWinLose}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={detail.creditSummary.netWinLose < 0? 'text-danger': ''}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Deposit</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.deposit < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.deposit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={detail.creditSummary.deposit < 0? 'text-danger': ''}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Withdraw</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.withdraw < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.withdraw}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={detail.creditSummary.withdraw < 0? 'text-danger': ''}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Gross Comm<br/>
                      <Link
                        className="text-dark text-underline"
                        onClick={() => {
                          setType('Comm');
                          setTab(1);
                          // handleOpLog(detail.id);
                        }}
                      >
                        <u>Detail</u>
                      </Link>
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.commissionBalance < 0
                          ? ' text-danger'
                          : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.commissionBalance}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Balance</td>
                    <td
                      className={`text-dark text-right${
                        detail.creditSummary.balance < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.creditSummary.balance}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={detail.creditSummary.balance < 0? 'text-danger': ''}
                      /> 
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Given Credit</td>
                    <td
                      className={`text-dark text-right${
                        detail.credit < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.credit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Available Credit</td>
                    <td
                      className={`text-dark text-right${
                        detail.availableBalance < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.availableCredit}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-responsive-sm col-md-6">
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-blue">
                  <tr>
                    <th scope="col" colSpan={5}>
                      Share Rate / Commission
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Type</th>
                    <th scope="col">Min Share</th>
                    <th scope="col">Max Share</th>
                    <th scope="col">Single Commission</th>
                    <th scope="col">Parlay Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {detail.stakeSettings.map((info, index) => (
                    <tr key={index}>
                      <td className="text-dark text-center">
                        Sport
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={info.minShare}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />{' '}
                        %
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={info.maxShare}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />{' '}
                        %
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={info.liveComm}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />{' '}
                        %
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={info.parlayComm}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />{' '}
                        %
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" colSpan={3}>
                      Setting
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">Min</th>
                    <th scope="col">Max</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-dark text-center">Single</td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.minSingle < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.minSingle}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.maxSingle < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.maxSingle}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Parlay</td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.minParlay < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.minParlay}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.maxParlay < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.maxParlay}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-dark text-center">Game Limit</td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.minParlay < 0 ? ' text-danger' : ''
                      }`}
                    >
                      &nbsp;
                    </td>
                    <td
                      className={`text-dark text-right${
                        detail.setting.maxTotal < 0 ? ' text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={detail.setting.maxTotal}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          {/* bottom row */}

          <h6 className="text-body px-4">
            Operation Log (<button className="btn btn-link" onClick={(e) => Utils.handleLinkClick(e, history, '/ownOpLog')} >Details</button>)
          </h6>

          <div className="row px-3">
            <div className="col-12">
              {operationLog !== undefined ? (
                <table className="table table-sm table-striped table-bordered">
                  <thead className="text-center thead-dark">
                    <tr>
                      <th>No.</th>
                      <th>Time</th>
                      <th>Username</th>
                      <th>IP</th>
                      <th>Activity Type</th>
                      <th>Error Code</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {operationLog.content.map((op, index) => (
                      <tr key={op.pubId} className="text-center ">
                        <td className="text-dark">{index + 1}</td>
                        <td className="text-dark">
                          {moment(op.createdAt).format('DD MMM h:mm a')}
                        </td>
                        <td className="text-dark">{op.username}</td>
                        <td className="text-dark">{op.ip}</td>
                        <td className="text-dark">{op.activityType}</td>
                        <td className="text-dark">{op.errorCode}</td>
                        <td className="text-dark">{op.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          </div>
        </>
      ) : tab === 1? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form action="" onSubmit={handleOpLog} id="form">
              Start Date:
              <DatePicker
                selected={startDate ? startDate : new Date()}
                minDate={searchDate}
                onChange={(date) => setStartDate(date)}
                className="mx-2"
                dateFormat="d MMM yyyy"
              />
              End Date:
              <DatePicker
                selected={endDate ? endDate : new Date()}
                onChange={(date) => setEndDate(date)}
                className="mx-2"
                dateFormat="d MMM yyyy"
                minDate={startDate}
                maxDate={maxDate}
              />
              <button
                className="btn btn-sm btn-primary px-1 py-1"
                onClick={() => {
                  getToday(0);
                }}
              >
                Today
              </button>{' '}
              <button
                className="btn btn-sm btn-primary px-1 py-1"
                onClick={() => {
                  getToday(1);
                }}
              >
                Yesterday
              </button>{' '}
              <button
                className="btn btn-sm btn-primary px-1 py-1"
                onClick={() => {
                  getWeek(0);
                }}
              >
                This Week
              </button>{' '}
              <button
                className="btn btn-sm btn-primary px-1 py-1"
                onClick={() => {
                  getWeek(1);
                }}
              >
                Last Week
              </button>{' '}
              <button
                className="btn btn-sm btn-primary px-1 py-1"
                onClick={() => {
                  getMonth(0);
                }}
              >
                This Month
              </button>{' '}
              <button
                className="btn btn-sm btn-primary px-1 py-1"
                onClick={() => {
                  getMonth(1);
                }}
              >
                Last Month
              </button>{' '}
            </form>
          </div>
          <button
              className="btn btn-sm btn-primary px-1 py-1 my-2 mx-2"
              onClick={() => {
                setTab(0);
              }}
            >
              Return
            </button>
          <div className="table-responsive-sm mx-2">
            <table className="table table-sm table-hover table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Day</th>
                  <th scope="col">Currency</th>
                  <th scope="col">Beginning</th>
                  {type !== 'Comm' ? (
                    <>
                      <th scope="col">Net W/L</th>
                      <th scope="col">Deposit</th>
                      <th scope="col">Withdraw</th>
                      <th scope="col">Balance</th>
                    </>
                  ) : (
                    <>
                      <th scope="col">Gross Comm</th>
                      <th scope="col">Withdraw</th>
                      <th scope="col">Balance</th>
                    </>
                  )}
              </tr>
              </thead>
              <tbody>
                {transactions.map((info, index) => (
                  <tr key={index}>
                    <td className="text-dark text-center">
                      {moment(info.reportDate).format('DD-MM-YYYY')}
                    </td>
                    <td className="text-dark text-center">
                      {moment(info.reportDate).format('ddd')}
                    </td>
                    <td className="text-dark text-center">{info.currency}</td>
                    
                    {type !== 'Comm' ? (
                        <>
                          <td
                            className={`${
                              info.beginning < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.beginning ? info.beginning : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              info.netWinLose < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.netWinLose ? info.netWinLose : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              info.deposit < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.deposit ? info.deposit : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              info.withdraw < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.withdraw ? info.withdraw : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              info.balance < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.balance ? info.balance : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            className={`${
                              info.beginning * -1 < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.beginning ? info.beginning * -1 : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              info.winCommission * -1 < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={
                                info.winCommission ? info.winCommission * -1 : 0
                              }
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              info.withdraw * -1 < 0 ? 'text-danger ' : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={info.withdraw ? info.withdraw * -1 : 0}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td
                            className={`${
                              (info.beginning +
                                info.winCommission +
                                info.withdraw) * -1 <
                              0
                                ? 'text-danger '
                                : ''
                            }text-right`}
                          >
                            <CurrencyFormat
                              value={
                                info.beginning +
                                info.winCommission +
                                info.withdraw
                                  ? (info.beginning +
                                    info.winCommission +
                                    info.withdraw) * -1
                                  : 0
                              }
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      )}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-dark text-center" colSpan="4">
                    Total
                  </td>
                  {type !== 'Comm' ? (
                      <>
                        <td
                          className={`${
                            totalWl < 0 ? 'text-danger ' : ''
                          }text-right`}
                        >
                          <CurrencyFormat
                            value={totalWl}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`${
                            totalDep < 0 ? 'text-danger ' : ''
                          }text-right`}
                        >
                          <CurrencyFormat
                            value={totalDep}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`${
                            totalWd < 0 ? 'text-danger ' : ''
                          }text-right`}
                        >
                          <CurrencyFormat
                            value={totalWd}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td></td>
                      </>
                    ) : (
                      <>
                        <td
                          className={`${
                            totalComm * -1 < 0 ? 'text-danger ' : ''
                          }text-right`}
                        >
                          <CurrencyFormat
                            value={totalComm * -1}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td
                          className={`${
                            totalWd * -1 < 0 ? 'text-danger ' : ''
                          }text-right`}
                        >
                          <CurrencyFormat
                            value={totalWd * -1}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            fixedDecimalScale={true}
                          />
                        </td>
                        <td></td>
                      </>
                    )}
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Dashboard;
