import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../../config';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

import BetInfo from './BetInfo';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const CreditLogM = ({ User, startDate, endDate, id, setTab, page, tp }) => {
  const classes = useStyles();
  const [totalPage, setTotalPage] = useState(null);
  const [credit, setCredit] = useState(null);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);

  // modal for BetInfo
  const [betInfoModal, setBetInfoModal] = useState(false);
  const reference = useRef('');
  const txType = (transaction) => {
    return (
      transaction.transactionType.charAt(0).toUpperCase() +
      transaction.transactionType.slice(1).toLowerCase().replace('_', ' ')
    );
  };
  const handleForm = (e, value, type) => {
    if (e != null) e.preventDefault();
    if (startDate != null && endDate != null) {
      axiosInstance
        .get(`/agent/${tp}/${id}/${type}`, {
          params: {
            startDate: moment(startDate).format('DD-MM-YYYY'),
            endDate: moment(endDate).format('DD-MM-YYYY'),
            pageNo: value - 1,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setCredit(res.data.content);
          setTotalPage(res.data.totalPages);
          let sumDeb = 0,
            sumCred = 0;
          for (let i = 0; i < res.data.content.length; i++) {
            if (res.data.content[i].debit) sumDeb += res.data.content[i].debit;
            if (res.data.content[i].credit)
              sumCred += res.data.content[i].credit;
          }
          setTotalCredit(sumCred);
          setTotalDebit(sumDeb);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    console.log(startDate, endDate);
    if (page === 'grossComm') {
      handleForm(null, 1, 'grossComm/cashLog');
    } else if (page !== 'cashLog') {
      handleForm(null, 1, 'creditLog');
    } else {
      handleForm(null, 1, 'cashLog');
    }
  }, [startDate, endDate]);

  return (
    <>
      {/* <Navbar User={User} /> */}
      {credit !== null ? (
        <>
          <div className="table-responsive-sm mx-2">
            {/* <button
              className="btn btn-sm btn-primary px-1 py-1 my-2"
              onClick={() => {
                setTab(0);
              }}
            >
              Return
            </button> */}
            <table className="table table-sm table-striped table-bordered">
              <thead className="text-center thead-dark">
                <tr>
                  <th>No.</th>
                  <th>Time</th>
                  <th scope="col">Name</th>
                  <th scope="col">Login ID</th>
                  <th scope="col">Operator</th>
                  <th>Currency</th>
                  <th>Type</th>
                  {/* <th>Beginning</th> */}
                  <th>Amount</th>
                  <th>Remark</th>
                </tr>
              </thead>
              {credit.length > 0 ? (
                <tbody className="text-center">
                  {credit.map((cred, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(cred.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td>{cred.accountName}</td>
                      <td>{cred.accountLoginUserName}</td>
                      <td>{cred.operator}</td>
                      <td>{cred.currencyCode}</td>
                      <td>
                        {cred.transactionType === 'BET' ||
                        cred.transactionType === 'BET_RESULT' ? (
                          <>
                            <button
                              className="btn btn-link text-primary p-0 "
                              onClick={() => {
                                reference.current = cred.remarks;
                                setBetInfoModal(true);
                              }}
                            >
                              {txType(cred)}
                            </button>
                          </>
                        ) : (
                          txType(cred)
                        )}
                      </td>
                      <td className="text-right">
                        <CurrencyFormat
                          value={cred.amount ? cred.amount : 0}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                          className={cred.amount < 0 ? 'text-danger' : null}
                        />
                      </td>
                      <td>{cred.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
              <tfoot>
                <tr>
                  <td className="text-dark text-center" colSpan="6">
                    Total
                  </td>
                  <td className="text-right">
                    <CurrencyFormat
                      value={totalCredit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td
                    className={`${
                      totalDebit > 0 ? 'text-danger ' : ''
                    }text-right`}
                  >
                    <CurrencyFormat
                      value={totalDebit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className="text-dark text-center" colSpan="2"></td>
                </tr>
              </tfoot>
            </table>
            <Pagination
              count={totalPage}
              onChange={handleForm}
              className={classes.pagination}
            />
          </div>
        </>
      ) : null}

      {/* BetInfo modal */}
      <BetInfo
        setBetInfoModal={setBetInfoModal}
        betInfoModal={betInfoModal}
        reference={reference.current}
      />
    </>
  );
};

export default CreditLogM;
