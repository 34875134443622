import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import CurrencyFormat from 'react-currency-format';
import '../styles/styles.css';
import BetDetail from './BetDetail';
import axiosInstance from '../config';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import AgentUtils from '../agentutils';
import { LaptopWindows } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const BetList = ({
  startDate,
  endDate,
  matchId,
  detailType,
  bets,
  viewDetail,
  handleChange,
  username,
  playerWager,
  playerWinning,
  totalPage,
  type,
  agent,
  totalTurnover,
  totalComm,
  searchTO,
  searchAp,
  searchCp,
  searchMp,
  searchSp,
  searchVp,
  producerPubId
}) => {
  const classes = useStyles();
  const [isUserSearch, setIsUserSearch] = useState(false);
  const [grandTotal, setGrandTotal] = useState();
  const userSearchRef = useRef(null);
  const history = useHistory();

  const toggleUserSearch = () => {
    setIsUserSearch(!isUserSearch);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsUserSearch(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(userSearchRef);

  const getGrandTotal = () => {
    axiosInstance
      .get('/agent/wlDetails/bets/grandTotal', {
        params: {
          username: matchId != null ? 'COMPANY' : detailType,
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          wc: true,
          mtc:
            document.getElementById('matchDateCb') &&
            document.getElementById('matchDateCb').checked
              ? 'Y'
              : 'N',
          matchId: matchId,
          game: matchId != null ? detailType : null,
          gameCategoryFilter: document.getElementById('gmStsFilter')
            ? document.getElementById('gmStsFilter').value
            : '',
          searchPlayer: document.getElementById('username')
            ? document.getElementById('username').value
            : null,
          searchWinLose: document.getElementById('resultvalue')
            ? document.getElementById('resultvalue').value
            : null,
          type: document.getElementById('searchType')
            ? document.getElementById('searchType').value
            : null,
          agent: searchAp ? searchAp : null,
          master: searchTO && searchTO.length != 6 && searchMp ? searchMp : null,
          senior: searchTO && searchTO.length != 4 && searchSp ? searchSp : null,
          vendor: searchTO && searchTO.length != 2 && searchVp ? searchVp : null,
          company: searchCp ? searchCp : null,
          searchProducerPubId: producerPubId ? producerPubId : null,
        },
      })
      .then((res) => {
        setGrandTotal(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    getGrandTotal();
  }, [startDate, endDate , handleChange]);

  useEffect(() => {
    getGrandTotal();
  }, []);

  return (
    <>
      {viewDetail && AgentUtils.isCompanyOrCompanySub(username) && (
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            viewDetail();
          }}
        >
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            className="mx-2"
            id="username"
            placeholder="username"
            aria-label="username"
            aria-describedby="button-addon2"
          />

          <label htmlFor="resultvalue ">Result:</label>
          <select
            name=""
            id="resultvalue"
            onChange={viewDetail}
            className="mx-2"
          >
            <option value="">All</option>
            <option value="WIN">Win</option>
            <option value="LOSE">Lose</option>
            <option value="DRAW">Draw</option>
            <option value="PENDING">Pending</option>
          </select>

          <button
            className="btn btn-sm btn-primary px-1 py-1"
            type="submit"
            id="button-addon2"
            onClick={viewDetail}
          >
            Search
          </button>
        </form>
      )}

      <table className="table table-sm table-striped table-bordered">
        <thead className="text-center thead-dark">
          <tr>
            <th scope="col">No</th>
            <th scope="col" className="">
              Username
            </th>
            <th scope="col">Date</th>
            <th scope="col">Reference</th>
            <th scope="col">Bet Type</th>
            <th scope="col">Bet Details</th>
            <th scope="col">Score</th>
            <th scope="col">Amount</th>
            {agent === 'Y' ? (
              <>
                <th scope="col">Turnover</th>
                <th scope="col">Comm</th>
                <th scope="col">Net Amt</th>
              </>
            ) : null}
            {type !== 'OUTSTANDING' ? (
              <>
                <th scope="col">Result</th>
                <th scope="col">Winning</th>
              </>
            ) : null}
            {window.location.pathname === '/opsMissingTrx' && (
              <th scope="col">Action</th>
            )}
          </tr>
        </thead>
        {bets && bets.length > 0 ? (
          <tbody>
            {bets.map((bet, index) => (
              <BetDetail bet={bet} index={index} type={type} agent={agent} />
            ))}
          </tbody>
        ) : <p className='"font-weight-bold'>No Data to Show</p>}
        {window.location.pathname !== '/opsMissingTrx' && (
          <tfoot className="font-weight-bold">
            <tr>
              <td className="text-center" colSpan={7}>
                Total
              </td>
              <td className={`text-right`}>
                <CurrencyFormat
                  value={playerWager}
                  decimalScale={2}
                  displayType={'text'}
                  thousandSeparator={true}
                  fixedDecimalScale={true}
                />
              </td>
              {agent === 'Y' ? (
                <>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={totalTurnover}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={totalComm}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                  <td className={`text-right`}>
                    <CurrencyFormat
                      value={totalTurnover - totalComm}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </>
              ) : null}
              {type !== 'OUTSTANDING' ? (
                <>
                  <td>&nbsp;</td>
                  <td
                    className={`text-right ${
                      playerWinning < 0 ? 'text-danger' : ''
                    }`}
                  >
                    <CurrencyFormat
                      value={playerWinning}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </>
              ) : null}
            </tr>
            {grandTotal && (
              <tr>
                <td className="text-center" colSpan={7}>
                  Grand Total
                </td>
                <td className="text-right">
                  <CurrencyFormat
                    value={grandTotal.totalAmount}
                    decimalScale={2}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale={true}
                  />
                </td>
                {agent === 'Y' ? (
                  <>
                    <td className="text-right">
                      {' '}
                      <CurrencyFormat
                        value={grandTotal.totalTurnover}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td className="text-right">
                      <CurrencyFormat
                        value={grandTotal.totalCommission}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                    <td
                      className={`text-right ${
                        grandTotal.totalNetAmount < 0 ? 'text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={grandTotal.totalNetAmount}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </>
                ) : null}

                {type !== 'OUTSTANDING' ? (
                  <>
                    <td className="text-right"></td>
                    <td
                      className={`text-right ${
                        grandTotal.totalWinning < 0 ? 'text-danger' : ''
                      }`}
                    >
                      <CurrencyFormat
                        value={grandTotal.totalWinning}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                      />
                    </td>
                  </>
                ) : null}
                {window.location.pathname === '/opsMissingTrx' && (
                  <td className=""></td>
                )}
              </tr>
            )}
          </tfoot>
        )}
      </table>
      {totalPage && totalPage > 0 ? (
        <Pagination
          count={totalPage}
          onChange={handleChange}
          className={classes.pagination}
        />
      ) : null}
    </>
  );
};

export default BetList;
