import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));
const Setting = ({ settingModal, setSettingModal, id, username }) => {
  const classes = useStyles();
  const [setting, setSetting] = useState(null);
  const [minSingleLimit, setMinSingleLimit] = useState(null);
  const [minSingleMessage, setMinSingleMessage] = useState(null);
  const [maxSingleLimit, setMaxSingleLimit] = useState(null);
  const [maxSingleMessage, setMaxSingleMessage] = useState(null);
  const [minParlayLimit, setMinParlayLimit] = useState(null);
  const [minParlayMessage, setMinParlayMessage] = useState(null);
  const [maxParlayLimit, setMaxParlayLimit] = useState(null);
  const [maxParlayMessage, setMaxParlayMessage] = useState(null);
  const [minTotalLimit, setMinTotalLimit] = useState(null);
  const [maxTotalLimit, setMaxTotalLimit] = useState(null);
  const [totalMessage, setTotalMessage] = useState(null);
  const [minSingleCommission, setMinSingleCommission] = useState(null);
  const [maxSingleCommission, setMaxSingleCommission] = useState(null);
  const [minParlayCommission, setMinParlayCommission] = useState(null);
  const [maxParlayCommission, setMaxParlayCommission] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setSettingModal(false);
  };

  const saveComm = () => {
    const form = document.getElementById('settingForm');
    const single = form['singleComm'].value;
    const parlay = form['parlayComm'].value;
    let errorMessage = '';
    if (
      parseFloat(single) < minSingleCommission ||
      parseFloat(single) > parseFloat(maxSingleCommission)
    ) {
      errorMessage += ' single commission,';
    }
    if (
      parseFloat(parlay) < minParlayCommission ||
      parseFloat(parlay) > parseFloat(maxParlayCommission)
    ) {
      errorMessage += ' parlay commission,';
    }
    if (errorMessage.length > 0) {
      toast.error('Invalid' + errorMessage.slice(0, -1) + ' value!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return false;
    }
    const requestBody = {
      single: single,
      parlay: parlay,
    };
    let url = '/agent/agents';
    if (username.length > 8) url = '/agent/members';
    axiosInstance
      .post(`${url}/${id}/commission`, qs.stringify(requestBody))
      .then((res) => {
        console.log(res);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const saveLimit = () => {
    setLoading(true);
    const form = document.getElementById('settingForm');
    const minSingle = form['minSingle'].value;
    const maxSingle = form['maxSingle'].value;
    const minParlay = form['minParlay'].value;
    const maxParlay = form['maxParlay'].value;
    const maxTotal = form['maxTotal'].value;
    let errorMessage = '';
    if (
      parseFloat(minSingle) < minSingleLimit ||
      parseFloat(minSingle) > parseFloat(maxSingle) ||
      parseFloat(minSingle) > maxSingleLimit
    ) {
      errorMessage += ' min single,';
      setMinSingleMessage('Invalid min single');
    } else setMinSingleMessage(null);
    if (
      parseFloat(maxSingle) > maxSingleLimit ||
      parseFloat(minSingle) > parseFloat(maxSingle) ||
      parseFloat(maxSingle) < minSingleLimit
    ) {
      errorMessage += ' max single,';
      setMaxSingleMessage('Invalid max single');
    } else setMaxSingleMessage(null);
    if (
      parseFloat(minParlay) < minParlayLimit ||
      parseFloat(minParlay) > parseFloat(maxParlay) ||
      parseFloat(minParlay) > maxParlayLimit
    ) {
      errorMessage += ' min parlay,';
      setMinParlayMessage('Invalid min parlay');
    } else setMinParlayMessage(null);
    if (
      parseFloat(maxParlay) > maxParlayLimit ||
      parseFloat(minParlay) > parseFloat(maxParlay) ||
      parseFloat(maxParlay) < minParlayLimit
    ) {
      errorMessage += ' max parlay,';
      setMaxParlayMessage('Invalid max parlay');
    } else setMaxParlayMessage(null);
    if (
      parseFloat(maxTotal) > maxTotalLimit ||
      parseFloat(maxTotal) < minTotalLimit
    ) {
      errorMessage += ' game limit,';
      setTotalMessage('Invalid game limit');
    } else setTotalMessage(null);

    if (errorMessage.length > 0) {
      toast.error('Invalid' + errorMessage.slice(0, -1) + ' value!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      return false;
    }

    const requestBody = {
      minSingle: minSingle,
      maxSingle: maxSingle,
      minParlay: minParlay,
      maxParlay: maxParlay,
      maxTotal: maxTotal,
    };
    let url = '/agent/agents';
    if (username.length > 8) url = '/agent/members';
    axiosInstance
      .post(`${url}/${id}/setting`, qs.stringify(requestBody))
      .then((res) => {
        console.log(res);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // console.log('use effect setting');
    setMinSingleMessage(null);
    setMaxSingleMessage(null);
    setMinParlayMessage(null);
    setMaxParlayMessage(null);
    setTotalMessage(null);
    if (settingModal === true) {
      let url = '/agent/agents';
      if (username.length > 8) url = '/agent/members';
      axiosInstance
        .get(`${url}/${id}/setting`)
        .then((res) => {
          setSetting(res.data);
          setMinSingleLimit(res.data.minSingleLimit);
          setMaxSingleLimit(res.data.maxSingleLimit);
          setMinParlayLimit(res.data.minParlayLimit);
          setMaxParlayLimit(res.data.maxParlayLimit);
          setMinTotalLimit(res.data.minTotalLimit);
          setMaxTotalLimit(res.data.maxTotalLimit);
          setMinSingleCommission(res.data.minSingleCommission);
          setMaxSingleCommission(res.data.maxSingleCommission);
          setMinParlayCommission(res.data.minParlayCommission);
          setMaxParlayCommission(res.data.maxParlayCommission);
          const form = document.getElementById('settingForm');
          console.log('form = ' + form);
          form['minSingle'].value = res.data.minSingle;
          form['minParlay'].value = res.data.minParlay;
          form['maxSingle'].value = res.data.maxSingle;
          form['maxParlay'].value = res.data.maxParlay;
          form['maxTotal'].value = res.data.maxTotal;
          form['singleComm'].value = res.data.singleCommission;
          form['parlayComm'].value = res.data.parlayCommission;
        })
        .catch((err) => console.log(err));
    }
  }, [settingModal, id]);

  return (
    <Modal
      open={settingModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
          id="settingForm"
        >
          {setting != null ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colSpan="4"
                    className="bg-primary text-light font-weight-bold"
                  >
                    Setting for {username}
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td colSpan="4" className="font-weight-bold">
                    Bet Limit
                  </td>
                </tr>
                <tr>
                  <td rowSpan="2" className="text-center align-middle">
                    Single
                  </td>
                  <td className={`${minSingleMessage ? 'text-danger' : ''}`}>
                    Min
                  </td>
                  <td>
                    <input type="text" placeholder="0.00" id="minSingle" /> min:{' '}
                    {minSingleLimit}
                  </td>
                  <td rowSpan="5" className="text-center align-middle">
                    <button
                      className="btn btn-sm btn-primary px-3 py-1"
                      onClick={() => {
                        saveLimit();
                      }}
                      disabled={loading}
                    >
                      Save
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className={`${maxSingleMessage ? 'text-danger' : ''}`}>
                    Max
                  </td>
                  <td>
                    <input type="text" placeholder="0.00" id="maxSingle" /> max:{' '}
                    {maxSingleLimit}
                  </td>
                </tr>
                <tr>
                  <td rowSpan="2" className="text-center align-middle">
                    Parlay
                  </td>
                  <td className={`${minParlayMessage ? 'text-danger' : ''}`}>
                    Min
                  </td>
                  <td>
                    <input type="text" placeholder="0.00" id="minParlay" /> min:{' '}
                    {minParlayLimit}
                  </td>
                </tr>
                <tr>
                  <td className={`${maxParlayMessage ? 'text-danger' : ''}`}>
                    Max
                  </td>
                  <td>
                    <input type="text" placeholder="0.00" id="maxParlay" /> max:{' '}
                    {maxParlayLimit}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className={`${totalMessage ? 'text-danger' : ''}`}
                  >
                    Game Limit
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="0.00"
                      id="maxTotal"
                      min={setting.minTotalLimit}
                      max={setting.maxTotalLimit}
                    />{' '}
                    min: {setting.minTotalLimit}, max: {setting.maxTotalLimit}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className="font-weight-bold">
                    Commission
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className={`${totalMessage ? 'text-danger' : ''}`}
                  >
                    Single
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="0.00"
                      id="singleComm"
                      min={setting.minSingleCommission}
                      max={setting.maxSingleCommission}
                      step="0.05"
                    />{' '}
                    min: {setting.minSingleCommission} max:{' '}
                    {setting.maxSingleCommission}{' '}
                  </td>
                  <td rowSpan="2" className="text-center align-middle">
                    <button
                      className="btn btn-sm btn-primary px-3 py-1"
                      onClick={() => {
                        saveComm();
                      }}
                      disabled={loading}
                    >
                      Save
                    </button>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="2"
                    className={`${totalMessage ? 'text-danger' : ''}`}
                  >
                    Parlay
                  </td>
                  <td>
                    <input
                      type="number"
                      placeholder="0.00"
                      id="parlayComm"
                      min={setting.minParlayCommission}
                      max={setting.maxParlayCommission}
                      step="0.05"
                    />{' '}
                    min: {setting.minParlayCommission} max:{' '}
                    {setting.maxParlayCommission}{' '}
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-sm btn-danger px-3 py-1"
                        onClick={() => {
                          setSettingModal(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </form>
      </div>
    </Modal>
  );
};

export default Setting;
