import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import AgentUtils from '../agentutils';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Cash = ({ cashModal, setCashModal, type, username, refresh }) => {
  const classes = useStyles();
  const [validPwd, setValidPwd] = useState(true);
  const [selected, setSelected] = useState([]);
  const [reportDate, setReportDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleClose = () => {
    setCashModal(false);
  };

  // handle Withdraw
  const handleCash = (e) => {
    e.preventDefault();
    setLoading(true);
    const requestBody = {
      username: username,
      type: type === 'Cash In' ? 'CASHIN' : 'CASHOUT',
      amount: e.target.withdraw.value,
      password: e.target.pwd.value,
      // temporary removed
      date: reportDate ? moment(reportDate).format('DD-MM-YYYY') : null,
    };
    axiosInstance
      .post('/agent/agentTxns', qs.stringify(requestBody))
      .then((res) => {
        if (res) {
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setCashModal(false);
          refresh(null);
          console.log(res);
        }
      })
      .catch((err) => {
        console.log('Err: ' + err);
        console.log('Err: ' + err.response);
        if (err.response && err.response.data.message === 'AG200') {
          setValidPwd(false);
        } else if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(()=>{
        setLoading(false);
      });
  };

  useEffect(() => {
    if (username != null && cashModal === true) {
      let suffix = '&type=';
      if (type === 'Cash In') suffix += 'CASHIN';
      else suffix += 'CASHOUT';
      if (reportDate != null)
        suffix += '&date=' + moment(reportDate).format('DD-MM-YYYY');
      axiosInstance
        .get('/agent/agentTxns?username=' + username + suffix)
        .then((res) => {
          console.log(res);
          setSelected(res.data);
        })
        .catch((err) => console.log(err));
      if (reportDate == null) {
        axiosInstance
          .get('public/today')
          .then((res) => {
            // temporary removed
            setReportDate(
              new Date(
                res.data.substring(6),
                parseInt(res.data.substr(3, 2)) - 1,
                res.data.substring(0, 2)
              )
            );
            setMaxDate(
              new Date(
                res.data.substring(6),
                parseInt(res.data.substr(3, 2)) - 1,
                res.data.substring(0, 2)
              )
            );
            let minSearchDate = new Date(
              res.data.substring(6),
              parseInt(res.data.substr(3, 2)) - 1,
              res.data.substring(0, 2)
            );
            minSearchDate.setDate(minSearchDate.getDate() - 90);
            setSearchDate(minSearchDate);
          })
          .catch((err) => console.log(err));
      }
    } else if (cashModal === false) {
      setSelected([]);
      setReportDate(null);
      setSearchDate(null);
      setMaxDate(null);
    }
  }, [username, cashModal, reportDate]);

  return (
    <Modal
      open={cashModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleCash}>
          {username != null ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="bg-primary text-light font-weight-bold"
                  >
                    {type} for {username}
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                <tr>
                  <td className="text-body font-weight-bold">Your Password</td>
                  <td>
                    <input type="password" id="pwd" required />{' '}
                    {validPwd === false ? (
                      <font color="red">Invalid password</font>
                    ) : null}
                  </td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Login Id</td>
                  <td>{username}</td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Name</td>
                  <td>{selected ? selected.name : null}</td>
                </tr>
                {/* <tr>
                  <td className="text-body font-weight-bold">Beginning</td>
                  <td>
                    {username.length > 8 ? (
                      <CurrencyFormat
                        value={selected ? selected.beginning : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={
                          selected.beginning < 0 ? 'text-danger' : null
                        }
                      />
                    ) : (
                      <CurrencyFormat
                        value={selected ? selected.beginning * -1 : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={
                          selected.beginning * -1 < 0 ? 'text-danger' : null
                        }
                      />
                    )}
                  </td>
                </tr> */}
                <tr>
                  <td className="text-body font-weight-bold">Balance</td>
                  <td>
                    {username.length > 8 ? (
                      <CurrencyFormat
                        value={selected ? selected.balance : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={selected.balance < 0 ? 'text-danger' : null}
                      />
                    ) : (
                      <CurrencyFormat
                        value={selected ? selected.balance * -1 : 0}
                        decimalScale={2}
                        displayType={'text'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        className={selected.balance > 0 ? 'text-danger' : null}
                      />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="text-body font-weight-bold">Amount</td>
                  <td>
                    <input
                      type="number"
                      step={0.01}
                      id="withdraw"
                      required
                      max={selected.limit < 0 ? 0 : selected.limit}
                    />{' '}
                    Max:
                    <CurrencyFormat
                      value={selected.limit < 0 ? 0 : selected.limit}
                      decimalScale={2}
                      displayType={'text'}
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                {AgentUtils.isVendorOrCompanyOrSubOf(
                  sessionStorage.getItem('me')
                ) ? (
                  <tr>
                    <td className="text-body font-weight-bold">Date</td>
                    <td>
                      <DatePicker
                        selected={reportDate}
                        minDate={searchDate}
                        maxDate={maxDate}
                        onChange={(date) => setReportDate(date)}
                        className="mx-2"
                        dateFormat="d MMM yyyy"
                      />
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td className="text-body font-weight-bold ">Remark</td>
                  <td>
                    <textarea
                      name=""
                      id="withdraw-remark"
                      cols="30"
                      rows="3"
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-end">
                      <input
                        type="submit"
                        value="Submit"
                        className="btn btn-sm btn-primary mx-3 px-3 py-1"
                        disabled={loading}
                      />

                      <button
                        className="btn btn-sm btn-danger px-3 py-1"
                        onClick={() => {
                          setCashModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </form>
      </div>
    </Modal>
  );
};

export default Cash;
