import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../../config';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const Edit = ({
  editModal,
  setEditModal,
  down,
  username,
  id,
  setMember,
  pageNo,
  handleMemberSearch,
}) => {
  const classes = useStyles();
  const [validPwd, setValidPwd] = useState(true);
  const [copy, setCopy] = useState();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setEditModal(false);
  };

  useEffect(() => {
    if (id != null && editModal === true) {
      setValidPwd(true);

      axiosInstance
        .get('agent/members/copies', {
          params: {
            agent: username.substring(0, 8),
          },
        })
        .then((resp) => {
          console.log(resp.data);
          setCopy(resp.data);
        })
        .catch((err) => console.log(err));

      axiosInstance
        .get(`/agent/members/${id}`)
        .then((res) => {
          console.log(res.data);
          const form = document.getElementById('editForm');
          form['name'].value = res.data.name !== undefined ? res.data.name : '';
          form['mobile'].value =
            res.data.mobile !== undefined ? res.data.mobile : '';
          // form['credit'].value = res.data.credit;
          form['remark'].value =
            res.data.remarks !== undefined ? res.data.remarks : '';
          if (res.data.status === true) {
            if (res.data.suspendAt) form['status'].value = 2;
            else form['status'].value = 1;
          } else form['status'].value = 0;
        })
        .catch((err) => console.log(err));
    }
  }, [id, editModal]);

  const handleEdit = (e) => {
    e.preventDefault();
    setLoading(true);
    const form = document.getElementById('editForm');
    const pwd = form['pwd'].value;
    const name = form['name'].value;
    const mobile = form['mobile'].value;
    // const credit = form['credit'].value;
    const copyFrom = form['copyFrom'].value;
    const remark = form['remark'].value;
    const status =
      form['status'].value === '1'
        ? true
        : form['status'].value === '0'
        ? false
        : null;
    const suspend = form['status'].value === '2' ? true : null;

    const requestBody = {
      password: pwd,
      name: name,
      mobile: mobile !== '' ? mobile : null,
      copy: copyFrom !== '' ? copyFrom : null,
      status: status,
      remarks: remark !== '' ? remark : null,
      suspend: suspend,
      // credit: credit !== '' ? credit : null,
    };

    axiosInstance
      .patch(`/agent/members/${id}`, qs.stringify(requestBody))
      .then((res) => {
        if (handleMemberSearch) handleMemberSearch();
        setEditModal(false);
        toast.success('Success!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data.message === 'AG200') {
          setValidPwd(false);
        } else if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      open={editModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleEdit} id="editForm">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  Edit Member
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Your Password</td>
                <td>
                  <input type="password" id="pwd" required />
                  {validPwd === false ? (
                    <font color="red">Invalid password</font>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Username:</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Name:</td>
                <td>
                  <input type="text" id="name" />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Status</td>
                <td>
                  <select name="" id="status">
                    <option value={1}>Active</option>
                    <option value={2}>Suspended</option>
                    <option value={0}>InActive</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Mobile</td>
                <td>
                  <input type="tel" id="mobile" />
                </td>
              </tr>
              {/* {
                <tr>
                  <td className="text-body font-weight-bold">Credit</td>
                  <td>
                    <input
                      type="number"
                      placeholder="0.00"
                      id="credit"
                      min={0}
                      className="mr-1"
                    />
                  </td>
                </tr>
              } */}
              {/* <tr>
                <td className="text-body font-weight-bold">Credit</td>
                <td>
                  <input type="number" placeholder="0.00" id="credit" min={0} />
                </td>
              </tr> */}
              <tr>
                <td className="text-body font-weight-bold ">Copy From</td>
                <td>
                  <select name="" id="copyFrom">
                    <option value="">None</option>
                    {copy !== undefined ? (
                      <>
                        {copy.map((cpy, index) =>
                          cpy.val !== username ? (
                            <option value={cpy.val} key={index}>
                              {cpy.val}
                            </option>
                          ) : null
                        )}
                      </>
                    ) : null}
                  </select>
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea name="" id="remark" cols="30" rows="3"></textarea>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                      disabled={loading}
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setEditModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default Edit;
