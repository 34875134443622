import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import CurrencyFormat from 'react-currency-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const WriteoffModal = ({ cashModal, setCashModal, refresh }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => {
    setCashModal(false);
  };

  // handle Withdraw
  const handleWriteoff = (e) => {
    e.preventDefault();
    const requestBody = {
      username: e.target.loginId.value,
      balance: e.target.balance.value,
      reportBalance: e.target.reportBalance.value,
      remarks: e.target.remarks.value,
    };
    axiosInstance
      .post('/agent/balReport/player/writeoff', qs.stringify(requestBody))
      .then((res) => {
        if (res) {
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setCashModal(false);
          refresh(null);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  return (
    <Modal
      open={cashModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" onSubmit={handleWriteoff}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  Write Off
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Login Id</td>
                <td>
                  <input
                    type="text"
                    id="loginId"
                    required
                  />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Balance</td>
                <td>
                  <input
                    type="number"
                    id="balance"
                    required
                    defaultValue={0}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Report Balance</td>
                <td>
                  <input
                    type="number"
                    id="reportBalance"
                    required
                    defaultValue={0}
                  />
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Remark</td>
                <td>
                  <textarea
                    name=""
                    id="remarks"
                    cols="30"
                    rows="3"
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setCashModal(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          {/* ) : null } */}
        </form>
      </div>
    </Modal>
  );
};

export default WriteoffModal;
