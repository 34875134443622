import axiosInstance from '../../config';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import { Link } from '@material-ui/core';

const Login = () => {
  const history = useHistory();
  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      axiosInstance
        .get('agent/me')
        .then((res) => {
          if (res) {
            history.push(`/dashboard`);
          }
        })
        .catch((err) => {
          console.log(err);
          sessionStorage.clear();
        });
    }
  }, [history]);

  const backToOldAgent = (e) => {
    e.preventDefault();
    console.log('old url = ' + process.env.REACT_APP_OLD_AGENT_URL);
    window.open(process.env.REACT_APP_OLD_AGENT_URL, '_blank');
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const loginForm = document.getElementById('loginForm');
    const password = loginForm['password'].value;
    const username = loginForm['username'].value;
    // getting token and role and saving them in localStorage
    const data = {
      username: username,
      password: password,
    };
    axiosInstance
      .post('/authenticate', data)
      .then((res) => {
        if (
          res.data.role === 'AGENT' ||
          res.data.role === 'MASTER' ||
          res.data.role === 'SENIOR' ||
          res.data.role === 'VENDOR' ||
          res.data.role === 'COMPANY' ||
          res.data.role === 'SUBAGENT'
        ) {
          sessionStorage.setItem('me', username.toUpperCase().trim());
          sessionStorage.setItem('token', res.data.jwttoken);
          sessionStorage.setItem('role', res.data.role);
          setInterval(() => {
            axiosInstance
              .post('token/renew')
              .then((res) => {
                sessionStorage.setItem('token', res.data.jwttoken);
              })
              .catch((err) => console.log(err));
          }, 600000);
          history.push(`/dashboard`);
        } else {
          toast.error(
            'Error: Unauthorised login. Your account will be investigated',
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            }
          );
          axiosInstance
            .post('/token/invalidate')
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        className={
          window.location.hostname.toLowerCase().indexOf('a28i.com') !== -1 ||
          window.location.hostname.toLowerCase().indexOf('ag28i.com') !== -1 ||
          window.location.hostname.toLowerCase().indexOf('yeskh65.net') !== -1 ||
          window.location.hostname.toLowerCase().indexOf('yeskh65.com') !== -1 ||
          window.location.hostname.toLowerCase().indexOf('28play.net') !== -1 
            ? 'login-bg'
            : 'login-background'
        }
      />
      &nbsp;
      <p />
      &nbsp;
      <p />
      &nbsp;
      <p />
      &nbsp;
      <p />
      <div
        className="card mx-auto my-auto p-3 login-form"
        style={{
          position: 'relative',
          width: '80%',
          maxWidth: '450px',
          background: 'rgba(255,255,255,0.85)',
          boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
          borderRadius: '20px',
          border: '1px solid rgba( 255, 255, 255, 0.18 )',
        }}
      >
        <h4 className="card-title mb-4 text-center">Agent Platform</h4>
        <div className="card-body" style={{ padding: '0' }}>
          <form onSubmit={handleLogin} id="loginForm">
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <PersonIcon />
                  </span>
                </div>
                <input
                  placeholder="Username"
                  type="text"
                  className="form-control"
                  id="username"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <LockIcon />
                  </span>
                </div>
                <input
                  placeholder="Password"
                  type="password"
                  className="form-control"
                  id="password"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn btn-sm btn-primary mt-3 mx-auto"
              style={{
                width: '100%',
                height: '2.4rem',
              }}
            >
              Log In
            </button>
            &nbsp;
            <p />
            <center>
              <Link onClick={backToOldAgent}>Vist Old AGENT</Link>
              &nbsp;
              <p />
              Copyright &copy; 2021. All Rights Reserved.
            </center>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
