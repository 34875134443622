import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import { toast } from 'react-toastify';
import qs from 'querystring';

const SystemSettings = ({User}) => {
  const history = useHistory();

  const [settings, setSettings] = useState([]);

  const updateSystemSetting = (mySettingName) => {

    const requestBody = {
      name: mySettingName,
      value: document.getElementById("settingStatus" + mySettingName).value,
    };

    axiosInstance
      .post('agent/settings', qs.stringify(requestBody))
      .then((res) => {
        if (res.status === 200){
          console.log('Setting update :', res);
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  }

  const handleChange = (event) => {
    event && event.preventDefault();

    axiosInstance
      .get('agent/settings', null)
      .then((res) => {
        setSettings(res.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    handleChange(null);
  }, []);

  return (
    <>
      <Navbar User={User} />

      <form action="" onSubmit={handleChange}>
       <div className="table-responsive-sm mx-2">
        <table className="table table-sm table-hover table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th
                colSpan="4"
                className="font-weight-bold"
              >
                System Settings
              </th>
            </tr>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Value</th>
              <th>Function</th>
            </tr>
          </thead>
          <tbody>
            {settings.map((info, index) => (
              <tr key={index}>
                <td
                  className="text-dark"
                >
                  {info.setting}
                </td>
                <td className="text-dark">
                  {info.description ? info.desription: 
                    info.setting === 'ALLOW_CREATE_AGENT' ? 'Allow agent users to create downline agents' :
                    info.setting === 'ALLOW_CREATE_USER' ? 'Allow agent users to create members' :
                    info.setting === 'ALLOW_DEL_NO_BET' ? 'Allow delete agents/members as long as no bet/deposit' : 
                    info.setting === 'CREATE_DEFAULT_PLAYER' ? 'Auto creation no  of players whenever an agent account is created' : '' }
                </td>
                <td className="pl-3">
                  {info.status === 'TRUE' || info.status === 'FALSE' ? (
                    <select id={"settingStatus" + info.setting} defaultValue={info.status}>
                      <option value="TRUE">Yes</option>
                      <option value="FALSE">No</option>
                    </select>
                  ): (
                    <input
                      type="text"
                      id={"settingStatus" + info.setting}
                      defaultValue={info.status}
                    />
                  )}
                  
                </td>
                <td className="text-center">
                  <button
                    className="btn btn-sm btn-primary px-3 py-1"
                    onClick={() => {
                      updateSystemSetting(info.setting);
                    }}
                  >
                  Save
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
       </div>
      </form>
    </>
  );
};

export default SystemSettings;
