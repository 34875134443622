import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../config';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const OperationLog = ({ User, id, setTab }) => {
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [OperationLog, setOperationLog] = useState(null);
  const handleForm = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get(`/agent/agents/${id}/operationLog`, {
        params: {
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pageNo: value - 1,
          pageSize: 30,
        },
      })
      .then((res) => {
        console.log(res.data);
        setOperationLog(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (id != null) {
      axiosInstance
        .get(`/agent/agents/${id}`, {
          params: {
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          },
        })
        .then((res) => {
          setUsername(res.data.username);
        })
        .catch((err) => console.log(err));
    }
    handleForm(null, 1);
  }, [startDate, endDate]);

  return (
    <>
      {OperationLog !== null ? (
        <>
          <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
            <form action="" onSubmit={handleForm} id="form">
              <div className="text-dark d-flex align-items-center">
                <strong>Start Date:</strong>
                <DatePicker
                  selected={startDate ? startDate : new Date()}
                  onChange={(date) => setStartDate(date)}
                  className="mx-2"
                  dateFormat="d MMM yyyy"
                />
                <div>
                  <strong>End Date:</strong>
                  <DatePicker
                    selected={endDate ? endDate : new Date()}
                    onChange={(date) => setEndDate(date)}
                    className="mx-2"
                    dateFormat="d MMM yyyy"
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="table-responsive-sm mx-2">
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2"
              onClick={() => {
                setTab(0);
              }}
            >
              Return
            </button>
            <table className="table table-sm table-striped table-bordered ">
              <thead className="text-center thead-dark">
                <tr>
                  <th>No.</th>
                  <th>Time</th>
                  <th>Username</th>
                  <th>IP</th>
                  <th>Activity Type</th>
                  <th>Error Code</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {OperationLog.map((op, index) => (
                  <tr key={op.pubId} className="text-center ">
                    <td className="text-secondary">{index + 1}</td>
                    <td className="text-secondary">
                      {moment(op.createdAt).format('DD MMM h:mm a')}
                    </td>
                    <td className="text-secondary">{op.username}</td>
                    <td className="text-secondary">{op.ip}</td>
                    <td className="text-secondary">{op.activityType}</td>
                    <td className="text-secondary">{op.errorCode}</td>
                    <td className="text-secondary">{op.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              count={totalPage}
              onChange={handleForm}
              className={classes.pagination}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default OperationLog;
