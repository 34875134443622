import React, { useState, useEffect } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../../config';
import qs from 'querystring';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AgentUtils from '../../../agentutils';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const CopySetting = ({
  copyModal,
  setCopyModal,
  down,
  username,
  id,
  setMember,
  handleMemberSearch,
  type,
}) => {
  const classes = useStyles();
  const [validPwd, setValidPwd] = useState(true);
  const [errMessage, setErrMessage] = useState(null);
  const [copy, setCopy] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const handleClose = () => {
    setCopyModal(false);
  };

  const handleCopySetting = (e) => {
    e.preventDefault();
    setLoading(true);
    const form = document.getElementById('copySettingForm');
    const pwd = form['pwd'].value;
    const copyFrom = form['copyFrom'].value;
    if (copyFrom.trim().length === 0) {
      setErrMessage('Copy from must not be empty');
      setLoading(false);
    } else {
      const requestBody = {
        password: pwd,
        copy: copyFrom,
      };
      let url = 'members';
      if (type === 'AGENT') url = 'agents';
      axiosInstance
        .patch(`/agent/${url}/${id}`, qs.stringify(requestBody))
        .then((res) => {
          console.log(res);
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });

          // if (down != null) {
          // axiosInstance
          //   .get('/agent/members', {
          //     params: {
          //       agent: down != null ? down : null,
          //     },
          //   })
          //   .then((resp) => {
          //     toast.success('Success!', {
          //       position: 'top-right',
          //       autoClose: 3000,
          //       hideProgressBar: false,
          //       closeOnClick: true,
          //       pauseOnHover: true,
          //     });
          //     setMember(resp.data.content);
          //     setCopyModal(false);
          //   });
          // }
          // update members
          if (handleMemberSearch) handleMemberSearch();
          setCopyModal(false);
        })
        .catch((err) => {
          if (err.response && err.response.data.message === 'AG200') {
            setValidPwd(false);
          } else if (err.response && err.response.status === 401) {
            history.push('/');
          }
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (id != null && copyModal === true) {
      setErrMessage(null);
      let url = 'agent/members/copies';
      if (type === 'AGENT') url = 'agent/copies';
      axiosInstance
        .get(url, {
          params: {
            type:
              type === 'AGENT'
                ? username.length === 2
                  ? 'VENDOR'
                  : username.length === 4
                  ? 'SENIOR'
                  : username.length === 6
                  ? 'MASTER'
                  : 'AGENT'
                : null,
            agent:
              type === 'AGENT'
                ? username.slice(0, -2)
                : username.substring(0, 8),
          },
        })
        .then((resp) => {
          console.log(resp.data);
          setCopy(resp.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, copyModal]);

  return (
    <Modal
      open={copyModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
        <form action="" id="copySettingForm" onSubmit={handleCopySetting}>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th
                  colSpan="2"
                  className="bg-primary text-light font-weight-bold"
                >
                  CopySetting
                </th>
              </tr>
            </thead>
            <tbody className={classes.tableBody}>
              <tr>
                <td className="text-body font-weight-bold">Your Password</td>
                <td>
                  <input type="password" id="pwd" required />
                  {validPwd === false ? (
                    <font color="red">Invalid password</font>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold">Login Id</td>
                <td>{username}</td>
              </tr>
              <tr>
                <td className="text-body font-weight-bold ">Copy From</td>
                <td>
                  <select name="" id="copyFrom">
                    <option value="">None</option>
                    {copy !== null ? (
                      <>
                        {copy.map((cpy, index) =>
                          username != cpy.val ? (
                            <option value={cpy.val} key={index}>
                              {cpy.val}
                            </option>
                          ) : null
                        )}
                      </>
                    ) : null}
                  </select>
                  {errMessage != null ? (
                    <font color="red">{errMessage}</font>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <div className="d-flex justify-content-end">
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-sm btn-primary mx-3 px-3 py-1"
                      disabled={loading}
                    />

                    <button
                      className="btn btn-sm btn-danger px-3 py-1"
                      onClick={() => {
                        setCopyModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </Modal>
  );
};

export default CopySetting;
