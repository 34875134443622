import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  reject: {
    textDecoration: 'line-through',
  },
  compName: {
    color: '#0E2336',
    fontWeight: 'bold',
  },
  teams: {
    color: '#323030',
  },
  strong: {
    color: '#DC252F',
  },
  handiCap: {
    color: '#1452CC ',
    fontWeight: 'bold',
  },
  running: {
    color: '#E91F1F',
    fontSize: '11px',
    fontWeight: 'bold',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const ProfitLoss = ({ User }) => {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [level, setLevel] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [bets, setBets] = useState([]);
  const [totalPage, setTotalPage] = useState(null);
  const history = useHistory();
  const backToParent = () => {
    if (
      username != null &&
      username.length > 0 &&
      username !== sessionStorage.getItem('me')
    ) {
      if (username.length > 8) setUsername(username.substring(0, 8));
      else setUsername(username.substring(0, username.length - 2));
    }
  };
  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (username == null || username.length <= 8) {
      axiosInstance
        .get('agent/outstandingReport', {
          params: {
            username: username ? username : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          },
        })
        .then((res) => {
          setReports(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else {
      if (value == null) value = 1;
      axiosInstance
        .get('agent/outstandingReport/playerDetails', {
          params: {
            username: username ? username : null,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            pageNo: isNaN(value) ? null : value - 1,

            pageSize: 10,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  useEffect(() => {
    if (username == null) {
      setUsername(sessionStorage.getItem('me'));
      setLevel(sessionStorage.getItem('role'));
    }
    handleChange(null);
    console.log(username);
  }, [username, startDate, endDate]);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
          />
        </form>
      </div>
      {reports !== null ? (
        <div className="table-responsive-sm">
          {username != null &&
          username.length > 0 &&
          username !== sessionStorage.getItem('me') ? (
            <button
              className="btn btn-link text-primary p-0 "
              onClick={() => {
                backToParent();
              }}
            >
              Back
            </button>
          ) : null}
          {username == null || username.length <= 8 ? (
            <>
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Login ID</th>
                    <th scope="col">Wager</th>
                    <th scope="col">Turn Over</th>
                    <th scope="col">Agent</th>
                    {level === 'COMPANY' ||
                    level === 'VENDOR' ||
                    level === 'SENIOR' ||
                    level === 'MASTER' ? (
                      <th scope="col">Master</th>
                    ) : null}
                    {level === 'COMPANY' ||
                    level === 'VENDOR' ||
                    level === 'SENIOR' ? (
                      <>
                        <th scope="col">Senior</th>
                      </>
                    ) : null}
                    {level === 'COMPANY' || level === 'VENDOR' ? (
                      <>
                        <th scope="col">Vendor</th>
                      </>
                    ) : null}
                    {level === 'COMPANY' ? (
                      <>
                        <th scope="col">Company</th>
                      </>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {reports.map((info, index) => (
                    <tr key={index}>
                      <td className="text-dark text-center">{info.name}</td>
                      <td className="text-dark text-center">
                        <button
                          className="btn btn-link text-primary p-0 "
                          onClick={() => {
                            setUsername(info.username);
                            handleChange(null);
                          }}
                        >
                          {info.username}
                        </button>
                      </td>
                      <td className="text-dark text-center">{info.wager}</td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={info.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      {/* <td className="text-dark text-right"><CurrencyFormat value={info.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td> */}
                      {/* <td className="text-dark text-right"><CurrencyFormat value={info.amount} decimalScale={2} displayType={'text'} thousandSeparator={true} fixedDecimalScale={true}/></td> */}
                      {level === 'COMPANY' ||
                      level === 'VENDOR' ||
                      level === 'SENIOR' ||
                      level === 'MASTER' ? (
                        <>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.agentContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.masterContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' ||
                      level === 'VENDOR' ||
                      level === 'SENIOR' ? (
                        <>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.seniorContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' || level === 'VENDOR' ? (
                        <>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.vendorContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                      {level === 'COMPANY' ? (
                        <>
                          <td className="text-dark text-right">
                            <CurrencyFormat
                              value={info.companyContra}
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                          </td>
                        </>
                      ) : null}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Username</th>
                    <th scope="col">Date</th>
                    <th scope="col">Reference</th>
                    <th scope="col">Bet Type</th>
                    <th scope="col">Bet Details</th>
                    <th scope="col">Amount</th>
                  </tr>
                  {bets.map((bet, index) => (
                    <tr className={classes.border} key={index}>
                      <td className="text-dark">{index + 1}</td>
                      <td className="text-dark text-left">{username}</td>
                      <td className="text-dark text-left">
                        {moment(bet.created).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td className="text-dark text-left">{bet.reference}</td>
                      <td className="text-dark text-left">
                        {bet.market === 'PARLAY' ? (
                          bet.type
                        ) : (
                          <>
                            {bet.sport} {bet.market}
                            <br />
                            {bet.game}
                          </>
                        )}
                      </td>
                      <td className="text-dark text-left">
                        {bet.market === 'PARLAY' ? (
                          <>
                            Mix Parlay @ {bet.odds.toFixed(2)}
                            <table
                              className="table table-sm table-hover"
                              width="300"
                            >
                              {bet.parlays.map((parlay) => (
                                <>
                                  <tr>
                                    <td width="200">
                                      <span
                                        className={`${classes.compName} ${
                                          parlay.status === 'REJECTED'
                                            ? classes.reject
                                            : null
                                        }`}
                                      >
                                        {parlay.comp}
                                      </span>
                                      <br />
                                      <span
                                        className={`${classes.teams} ${
                                          parlay.status === 'REJECTED'
                                            ? classes.reject
                                            : null
                                        }`}
                                      >
                                        <span
                                          className={
                                            parlay.high === 1
                                              ? classes.strong
                                              : null
                                          }
                                        >
                                          {parlay.home} vs{' '}
                                        </span>
                                        <span
                                          className={
                                            parlay.high === 2
                                              ? classes.strong
                                              : null
                                          }
                                        >
                                          {parlay.away}
                                        </span>
                                      </span>
                                      <br />
                                      {parlay.market === 'LIVE' ? (
                                        <>
                                          <span className={classes.running}>
                                            (Running {parlay.homeGoal}-
                                            {parlay.awayGoal})
                                          </span>
                                          <br />
                                        </>
                                      ) : null}
                                      <span
                                        className={`${classes.handiCap} ${
                                          parlay.status === 'REJECTED'
                                            ? classes.reject
                                            : null
                                        }`}
                                      >
                                        <span className={classes.compName}>
                                          {parlay.desc}{' '}
                                          {parlay.handicap !== '' ? (
                                            <span className={classes.handiCap}>
                                              ({parlay.handicap}){' '}
                                            </span>
                                          ) : null}
                                          <span>
                                            @ {parlay.odds.toFixed(2)}
                                          </span>
                                        </span>
                                      </span>
                                    </td>
                                    <td width="50">
                                      {parlay.ftHome}-{parlay.ftAway} (
                                      {parlay.fhHome}-{parlay.fhAway})
                                    </td>
                                    <td width="50">
                                      {parlay.result.charAt(0).toUpperCase() +
                                        parlay.result
                                          .slice(1)
                                          .toLowerCase()
                                          .replace('_', ' ')}
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </table>
                          </>
                        ) : (
                          <>
                            <span
                              className={`${classes.compName} ${
                                bet.status === 'REJECTED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              {bet.comp}
                            </span>
                            <br />
                            <span
                              className={`${classes.teams} ${
                                bet.status === 'REJECTED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              <span
                                className={
                                  bet.high === 1 ? classes.strong : null
                                }
                              >
                                {bet.home} vs{' '}
                              </span>
                              <span
                                className={
                                  bet.high === 2 ? classes.strong : null
                                }
                              >
                                {bet.away}
                              </span>
                            </span>
                            <br />
                            {bet.market === 'LIVE' ? (
                              <>
                                <span className={classes.running}>
                                  (Running {bet.homeGoal}-{bet.awayGoal})
                                </span>
                                <br />
                              </>
                            ) : null}
                            <span
                              className={`${classes.handiCap} ${
                                bet.status === 'REJECTED'
                                  ? classes.reject
                                  : null
                              }`}
                            >
                              <span className={classes.compName}>
                                {bet.desc}{' '}
                                {bet.handicap !== '' ? (
                                  <span className={classes.handiCap}>
                                    ({bet.handicap}){' '}
                                  </span>
                                ) : null}
                                <span>@ {bet.odds.toFixed(2)}</span>
                              </span>
                            </span>
                          </>
                        )}
                      </td>
                      <td className="text-dark text-right">
                        <CurrencyFormat
                          value={bet.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                  ))}
                </thead>
              </table>
              <Pagination
                count={totalPage}
                onChange={handleChange}
                className={classes.pagination}
              />
            </>
          )}
        </div>
      ) : null}
    </>
  );
};

export default ProfitLoss;
