import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../config';
import qs from 'querystring';
import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { useHistory } from 'react-router';
import AgentUtils from '../agentutils';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const DepWd = ({ depositModal, setDepositModal, username, type, refresh }) => {
  const classes = useStyles();
  const history = useHistory();
  const handleClose = () => {
    setDepositModal(false);
  };
  const [stop, setStop] = useState(false);
  const [selected, setSelected] = useState(null);
  const [validPwd, setValidPwd] = useState(true);
  const [reportDate, setReportDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [searchDate, setSearchDate] = useState(null);
  const [loading, setLoading] = useState(false);

  // handle Deposit
  const handleDeposit = (e) => {
    setLoading(true);
    setValidPwd(true);
    e.preventDefault();
    let requestBody = {
      username: username,
      type: type, // WITHDRAW
      amount: e.target.deposit.value,
      password: document.getElementById('deposit-pwd').value,
      remarks: document.getElementById('deposit-remark').value,
      // temporary removed
      date: reportDate ? moment(reportDate).format('DD-MM-YYYY') : null,
      // date: null,
    };
    if (type == 'WITHDRAW') {
      requestBody = {
        username: username,
        type: type, // WITHDRAW
        amount: e.target.deposit.value,
        password: document.getElementById('deposit-pwd').value,
        remarks: document.getElementById('deposit-remark').value,
        date: reportDate ? moment(reportDate).format('DD-MM-YYYY') : null,
        // otp: document.getElementById('otp').value,
      };
    }
    axiosInstance
      .post('/agent/txns', qs.stringify(requestBody))
      .then((res) => {
        if (res) {
          console.log(res);
          toast.success('Success!', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setDepositModal(false);
          refresh(null);
        }
        // update members
      })
      .catch((err) => {
        if (err.response && err.response.data.message === 'AG200') {
          setValidPwd(false);
        } else if (err.response && err.response.data.message === 'ABR001') {
          setStop(true);
        } else if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(()=>{
        setLoading(false);
      });
  };

  useEffect(() => {
    if (username != null && depositModal === true) {
      let urlSuffix = '';
      // temporary removed
      // if (reportDate != null)
      //   urlSuffix = '&date=' + moment(reportDate).format('DD-MM-YYYY');
      axiosInstance
        .get('/agent/txns?username=' + username + '&type=' + type + urlSuffix)
        .then((res) => {
          console.log(res);
          setSelected(res.data);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.data.message === 'ABR001') {
            setStop(true);
          }
        });
      if (
        reportDate == null &&
        AgentUtils.isVendorOrCompanyOrSubOf(sessionStorage.getItem('me'))
      ) {
        axiosInstance
          .get('public/today')
          .then((res) => {
            // temporary removed
            setReportDate(
              new Date(
                res.data.substring(6),
                parseInt(res.data.substr(3, 2)) - 1,
                res.data.substring(0, 2)
              )
            );
            setMaxDate(
              new Date(
                res.data.substring(6),
                parseInt(res.data.substr(3, 2)) - 1,
                res.data.substring(0, 2)
              )
            );
            let minSearchDate = new Date(
              res.data.substring(6),
              parseInt(res.data.substr(3, 2)) - 1,
              res.data.substring(0, 2)
            );
            minSearchDate.setDate(minSearchDate.getDate() - 90);
            setSearchDate(minSearchDate);
          })
          .catch((err) => console.log(err));
      }
    } else {
      setReportDate(null);
    }
  }, [username, depositModal, reportDate]);

  return (
    <>
      <Modal
        open={depositModal}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modalStyle}
      >
        <div className={classes.Modal}>
          <form action="" onSubmit={handleDeposit}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className="bg-primary text-light font-weight-bold"
                  >
                    {type}
                  </th>
                </tr>
              </thead>
              <tbody className={classes.tableBody}>
                {stop ? (
                  <tr>
                    <td
                      colSpan={2}
                      className="text-body font-weight-bold text-danger"
                    >
                      Withdrawal is paused. Please try again later!
                    </td>
                  </tr>
                ) : (
                  <>
                    <tr>
                      <td className="text-body font-weight-bold">
                        Your Password
                      </td>
                      <td>
                        <input type="password" id="deposit-pwd" required />
                        {validPwd === false ? (
                          <font color="red">Invalid password</font>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold">Login Id</td>
                      <td>{selected ? selected.username : null}</td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold">Name</td>
                      <td>{selected ? selected.name : null}</td>
                    </tr>
                    {/* <tr>
                      <td className="text-body font-weight-bold">Beginning</td>
                      <td>
                        <CurrencyFormat
                          value={selected ? selected.beginning : 0}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr> */}
                    <tr>
                      <td className="text-body font-weight-bold">Balance</td>
                      <td>
                        <CurrencyFormat
                          value={selected ? selected.balance : 0}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-body font-weight-bold">Amount</td>
                      <td>
                        <input
                          type="number"
                          id="deposit"
                          step={0.01}
                          min={0}
                          max={
                            selected
                              ? selected.limit < 0
                                ? 0
                                : selected.limit
                              : 0
                          }
                          required
                        />
                        {selected !== null ? (
                          <label htmlFor="deposit" className="ml-2">
                            {' '}
                            Max (
                            <CurrencyFormat
                              value={
                                selected
                                  ? selected.limit < 0
                                    ? 0
                                    : selected.limit
                                  : 0
                              }
                              decimalScale={2}
                              displayType={'text'}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                            />
                            )
                          </label>
                        ) : null}
                      </td>
                    </tr>
                    {AgentUtils.isVendorOrCompanyOrSubOf(
                      sessionStorage.getItem('me')
                    ) ? (
                      <tr>
                        <td className="text-body font-weight-bold">Date</td>
                        <td>
                          <DatePicker
                            selected={reportDate}
                            minDate={searchDate}
                            maxDate={maxDate}
                            onChange={(date) => setReportDate(date)}
                            className="mx-2"
                            dateFormat="d MMM yyyy"
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td className="text-body font-weight-bold ">Remark</td>
                      <td>
                        <textarea
                          name=""
                          id="deposit-remark"
                          cols="30"
                          rows="3"
                        ></textarea>
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td colSpan="2">
                    <div className="d-flex justify-content-end">
                      {stop ? (
                        ''
                      ) : (
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-sm btn-primary mx-3 px-3 py-1"
                          disabled={loading}
                        />
                      )}
                      <button
                        className="btn btn-sm btn-danger px-3 py-1"
                        onClick={() => {
                          setStop(false);
                          setDepositModal(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default DepWd;
