import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Pagination from '@material-ui/lab/Pagination';
import Navbar from '../../components/Navbar';

const useStyles = makeStyles((theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0.3rem 0',
  },
}));

const Message = ({User}) => {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [searchContent, setSearchContent] = useState('');
  const [totalPage, setTotalPage] = useState(null);
  const history = useHistory();
  const handleChange = (event, value) => {
    event && event.preventDefault();
    let params = {
      pageNo: isNaN(value) ? null : value - 1,
    }
    if(searchContent) {
      params['searchContent'] = searchContent;
    }

    axiosInstance
      .get('portal/messages', {
        params: params,
      })
      .then((res) => {
        setMessages(res.data.content);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    handleChange(null, 1);
  }, []);

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form
          action=""
          className="form-inline"
          id="message-form"
          onSubmit={handleChange}
        >
          <label
            htmlFor="content-search"
            className="text-body font-weight-bold mx-2"
          >
            Content{' '}
          </label>
          <input
            onChange={(event) => { setSearchContent(event.target.value); }}
            type="text"
            id="content-search"
            className="form-control form-control-xs mx-2"
            size="50"
          />
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-3 py-1 "
          />
        </form>
      </div>
      {messages !== null ? (
        <div className="table-responsive-sm mx-2">
          <table className="table table-sm table-striped">
            <thead className="text-center thead-dark">
              <tr>
                <th width="50" scope="col">
                  #
                </th>
                <th width="150" scope="col">
                  Date
                </th>
                <th scope="col">Content</th>
              </tr>
            </thead>
            <tbody>
              {messages.map((info, index) => (
                <tr key={index}>
                  <td className="text-dark text-center">{index + 1}</td>
                  <td className="text-dark text-center">
                    {moment(info.startDate).format('D MMM h:mm a')}
                  </td>
                  <td className="pl-3">{info.content}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination
            count={totalPage}
            onChange={handleChange}
            className={classes.pagination}
          />
        </div>
      ) : null}
    </>
  );
};

export default Message;
