import React, { useState } from 'react';
import Navbar from '../../components/Navbar';
import axiosInstance from '../../config';

const ShareRateSearch = ({User}) => {
  const [level, setLevel] = useState(0);
  const [share, setShare] = useState(null);
  const handleChange = (e) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/indiShareRate?loginId='+document.getElementById('member-id').value)
      .then((resp) => {
        setLevel(resp.data.level);
        setShare(resp.data.list.content);
      })
      .catch((err) => {
        setShare([]);
      });
  };
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={handleChange} id="form">
          <label htmlFor="member-id mx-2">Login ID: </label>
          <input type="text" id="member-id" className="mx-2" required/>
          <input
            type="submit"
            value="Search"
            className="btn btn-sm btn-primary px-1 py-1 mx-1"
          />
        </form>
      </div>
      {/* table */}
      <div className="table-responsive-sm mx-2">
        <table className="table table-sm table-striped table-bordered">
          <thead className="text-center thead-dark">
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th>Login ID</th>
              <th>Agent</th>
              {level > 10? ( <th>Master Agent</th> ) : null }
              {level > 20? ( <th>Senior Master</th> ) : null }
              {level > 30? ( <th>Vendor</th> ) : null }
              <th>Company</th>
            </tr>
          </thead>
          {share !== null ? (
            <tbody className="text-center">
              {share.map((info, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{info.name}</td>
                  <td>{info.username}</td>
                  <td>{info.agentStake}</td>
                  {level === 10? (
                    <td>{info.masterStake}</td>  
                  ): level === 20? (
                    <>
                      <td>{info.masterStake}</td>
                      <td>{info.seniorStake}</td>
                    </>
                  ): level === 30? (
                    <>
                      <td>{info.masterStake}</td>
                      <td>{info.seniorStake}</td>
                      <td>{info.vendorStake}</td>
                    </>
                  ): (
                    <>
                      <td>{info.masterStake}</td>
                      <td>{info.seniorStake}</td>
                      <td>{info.vendorStake}</td>
                      <td>{info.companyStake}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          ) : null}
        </table>
      </div>
    </>
  );
};

export default ShareRateSearch;
