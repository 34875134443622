import React from 'react';
import Utils from '../../utils';
import { useHistory } from 'react-router-dom';

const BalNavBar = ({ isBalNavbar, role }) => {
  const history = useHistory();
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light py-0 border-bottom ${
        isBalNavbar !== true ? 'd-none' : 'd-block'
      }`}
      id="bal-navbar"
    >
      <div>
        <div className="navbar-nav position-relative">
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/winlose' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/winlose')}
          >
            Win/Lose
          </button>
          { role === 'COMPANY'  ? (
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/winlose2' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/winlose2')}
            >
              Win/Lose 2
            </button>
          ) : null }
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/grosscomm' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/grosscomm')}
          >
            Gross Comm
          </button>
          { role === 'COMPANY'  ? (
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/grosscomm2' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/grosscomm2')}
            >
              Gross Comm 2
            </button>
          ) : null }
          <button
            className={`btn btn-link nav-link text-dark${
              window.location.pathname === '/security' ? ' active' : ''
            }`}
            onClick={(e) => Utils.handleLinkClick(e, history, '/security')}
          >
            Security
          </button>
          { role === 'COMPANY' || role === 'VENDOR' ? (
            <button
              className={`btn btn-link nav-link text-dark${
                window.location.pathname === '/negative' ? ' active' : ''
              }`}
              onClick={(e) => Utils.handleLinkClick(e, history, '/negative')}
            >
              Negative Members
            </button>
          ): null}
          { role === 'COMPANY' ? (
            <>
              <button
                className={`btn btn-link nav-link text-dark${
                  window.location.pathname === '/maxloss' ? ' active' : ''
                }`}
                onClick={(e) => Utils.handleLinkClick(e, history, '/maxloss')}
              >
                Control
              </button>
            </>
          ): null}
        </div>
      </div>
    </nav>
  );
};

export default BalNavBar;
