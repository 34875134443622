import React, {useState, useEffect} from 'react';
import { Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import axiosInstance from '../../../config';
import { useHistory } from 'react-router-dom';
import BetDetail from './../../../components/BetDetail';

const useStyles = makeStyles((theme) => ({
  Modal: {
    margin: '0 auto',
    width: '800px',
    backgroundColor: '#fff',
    borderRadius: '4px',
  },
  modalStyle: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    overflowY: 'scroll',
    height: '80%',
    display: 'block',
    margin: 'auto',
  },
  tableBody: {
    '& td': {
      padding: '0.25rem 1rem  !important',
    },
  },
}));

const BetInfo = ({ betInfoModal, setBetInfoModal, reference }) => {
  const classes = useStyles();
  const history = useHistory();

  const [errMessage, setErrMessage] = useState(null);
  const [bet, setBet] = useState(null);

  const handleClose = () => {
    setBetInfoModal(false);
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    axiosInstance
      .get('agent/generalReport/betInfo', {
        params: {
          reference: reference? reference: null
        },
      })
      .then((res) => {
        setBet(res.data);
        bet = bet;
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      });
  };

  useEffect(() => {
    if (reference != null && betInfoModal === true) {
      setErrMessage(null);
    }
    if(betInfoModal == true) {
      handleChange(null);
    }
  }, [reference, betInfoModal]);

  return (
    <Modal
      open={betInfoModal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalStyle}
    >
      <div className={classes.Modal}>
      &nbsp;<p/>
        <div className="table-responsive-sm mx-2">
          <table className="table table-sm table-striped table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Username</th>
                <th scope="col">Date</th>
                <th scope="col">Reference</th>
                <th scope="col">Bet Type</th>
                <th scope="col">Bet Details</th>
                <th scope="col">Amount</th>
                <th scope="col">Result</th>
                <th scope="col">Winning</th>
              </tr>
              {bet !== null ? (
                <BetDetail bet={bet} index={0}/>  
                ) : null}
            </thead>
          </table>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-sm btn-primary px-3 py-1"
            onClick={() => {
              setBetInfoModal(false);
              handleClose();
            }}
          >
            Done
          </button>
        </div>
        &nbsp;
        <p/>
      </div>
    </Modal>
  );
};

export default BetInfo;
