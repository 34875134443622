import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import DatePicker from 'react-datepicker';
import axiosInstance from '../config';
import moment from 'moment';

export default function Statistic({ User }) {
  const date = new Date();
  const [startDate, setStartDate] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [stats, setStats] = useState();
  const [totalRegister, setTotalRegister] = useState(0);
  const [totalLogin, setTotalLogin] = useState(0);
  const [totalBet, setTotalBet] = useState(0);
  const [loading, setLoading] = useState(true);

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      getMemberStats(today, today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      getMemberStats(today, today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      getMemberStats(today, today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const handleChange = () => {
    getMemberStats(startDate, endDate);
  };

  useEffect(() => {
    getMemberStats();
  }, []);

  const getMemberStats = (startD, endD) => {
    setLoading(true);
    axiosInstance
      .get('agent/memberStat', {
        params: {
          startDate: startD
            ? moment(startD).format('DD-MM-YYYY')
            : moment(startDate).format('DD-MM-YYYY'),
          endDate: endD
            ? moment(endD).format('DD-MM-YYYY')
            : moment(endDate).format('DD-MM-YYYY'),
        },
      })
      .then((res) => {
        setStats(res.data);
        let register = 0,
          login = 0,
          bet = 0;
        res.data.map((val) => {
          console.log(val);
          register += val.statRegister;
          login += val.statLogin;
          bet += val.statBet;
        });
        setTotalRegister(register);
        setTotalLogin(login);
        setTotalBet(bet);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={(e) => e.preventDefault()} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-1"
            dateFormat="d MMM yyyy"
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-1"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              handleChange();
            }}
          >
            Get Report
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
          {loading ? (
            <>
              <br />
              <img
                src={require('../img/loadingAnimation.gif')}
                height={13}
                width={208}
                alt=""
              />
            </>
          ) : null}
        </form>
      </div>
      <table className="table table-sm table-striped table-bordered">
        <thead className="text-center thead-dark">
          <tr>
            <th scope="col" rowSpan={2} colSpan={2}>
              Date
            </th>
            <th scope="col" rowSpan={2} colSpan={2}>
              Weekday
            </th>
            <th scope="col" colSpan={3}>
              Member
            </th>
          </tr>
          <tr>
            <th>Registered</th>
            <th>Login</th>
            <th>Bet</th>
          </tr>
        </thead>
        <tbody>
          {stats ? (
            <>
              {stats.map((stat, index) => (
                <tr key={index}>
                  <td className="text-center" colSpan={2}>
                    {moment(stat.statDate).local().format('YYYY-MM-DD')}
                  </td>
                  <td className="text-center" colSpan={2}>
                    {moment(stat.statDate).local().format('dddd')}
                  </td>
                  <td className="text-center">{stat.statRegister}</td>
                  <td className="text-center">{stat.statLogin}</td>
                  <td className="text-center">{stat.statBet}</td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td className="text-center" colSpan={2}>
                -
              </td>
              <td className="text-center" colSpan={2}>
                -
              </td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
              <td className="text-center">-</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          {stats ? (
            <tr className="font-weight-bold">
              <td className="text-dark text-center" colSpan={4}>
                Total
              </td>
              <td className="text-dark text-center">{totalRegister}</td>
              <td className="text-dark text-center">{totalLogin}</td>
              <td className="text-dark text-center">{totalBet}</td>
            </tr>
          ) : (
            <tr>
              <td className="text-dark text-center" colSpan={4}>
                Total
              </td>
              <td className="text-dark text-center">-</td>
              <td className="text-dark text-center">-</td>
              <td className="text-dark text-center">-</td>
            </tr>
          )}
        </tfoot>
      </table>
    </>
  );
}
