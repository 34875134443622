import axiosInstance from '../../config';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import '../../styles/styles.css';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Navbar from '../../components/Navbar';
import BetList from '../../components/BetList';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { KeyboardReturnOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: 'pointer',
  },
}));

const Summary = ({ User }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [sumWager, setSumWager] = useState(0);
  const [sumTurnover, setSumTurnover] = useState(0);
  const [sumValid, setSumValid] = useState(0);
  const [sumWlCompany, setSumWlCompany] = useState(0);
  const [sumCommCompany, setSumCommCompany] = useState(0);
  const [sumCommGross, setSumCommGross] = useState(0);
  const [searchDate, setSearchDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [bets, setBets] = useState([]);
  const [detailType, setDetailType] = useState(null);
  const [matchId, setMatchId] = useState(null);
  const [totalPage, setTotalPage] = useState(null);
  const [playerWager, setPlayerWager] = useState(0);
  const [playerWinning, setPlayerWinning] = useState(0);
  const [playerTurnover, setPlayerTurnover] = useState(0);
  const [playerComm, setPlayerComm] = useState(0);
  const history = useHistory();
  const calculateSum = (report) => {
    let wager = 0;
    let turnover = 0;
    let valid = 0;
    let wlCompany = 0,
      commCompany = 0,
      commGross = 0;

    for (var i = 0; i < report.length; i++) {
      wager += report[i].wager;
      turnover += report[i].amount;
      valid += report[i].turnover;
      if (report[i].companyWin) wlCompany += report[i].companyWin;
      if (report[i].companyCom) commCompany += report[i].companyCom;
      if (report[i].grossCom) commGross += report[i].grossCom;
    }
    setSumWager(wager);
    setSumTurnover(turnover);
    setSumValid(valid);
    setSumWlCompany(wlCompany);
    setSumCommCompany(commCompany);
    setSumCommGross(commGross);
  };

  const calculateBet = (report) => {
    let wager = 0;
    let turnover = 0;
    let comm = 0;
    let winning = 0;
    for (var i = 0; i < report.length; i++) {
      wager += report[i].amount;
      if (report[i].winning) winning += report[i].winning;
      turnover += report[i].turnover;
      comm += report[i].commission;
    }
    setPlayerWager(wager);
    setPlayerWinning(winning + comm);
    setPlayerTurnover(turnover);
    setPlayerComm(comm);
  };

  const viewDetail = (e, value) => {
    if (e != null) e.preventDefault();
    if (search === 'type' && matchId == null) {
      let typeUrl = 'agent/companySummaryMatch';
      axiosInstance
        .get(typeUrl, {
          params: {
            username: null,
            startDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            type: detailType,
            wc: true,
            pageNo: isNaN(value) ? null : value - 1,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
            gameCategoryFilter: document.getElementById('gmStsFilter')
              ? document.getElementById('gmStsFilter').value
              : '',
            coOnly: true,
          },
        })
        .then((res) => {
          setReports(res.data.content);
          calculateSum(res.data.content);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    } else {
      axiosInstance
        .get('agent/wlDetails/bets', {
          params: {
            username: matchId != null ? 'COMPANY' : detailType,
            startDate: startDate
              ? moment(startDate).format('DD-MM-YYYY')
              : null,
            endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
            wc: true,
            pageNo: isNaN(value) ? null : value - 1,
            mtc:
              document.getElementById('matchDateCb') &&
              document.getElementById('matchDateCb').checked
                ? 'Y'
                : 'N',
            matchId: matchId,
            game: matchId != null ? detailType : null,
            gameCategoryFilter: document.getElementById('gmStsFilter')
              ? document.getElementById('gmStsFilter').value
              : '',
            searchPlayer: document.getElementById('username')
              ? document.getElementById('username').value
              : null,
            searchWinLose: document.getElementById('resultvalue')
              ? document.getElementById('resultvalue').value
              : null,
          },
        })
        .then((res) => {
          setBets(res.data.content);
          calculateBet(res.data.content);
          setTotalPage(res.data.totalPages);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status === 401) {
            history.push('/');
          }
        });
    }
  };

  const handleChange = (e, value) => {
    if (e != null) e.preventDefault();
    if (matchId != null) {
      viewDetail(e, value);
      return;
    }
    if (detailType != null) {
      viewDetail(e, value);
      return;
    }
    setReports([]);
    if (startDate == null && endDate == null) return;
    setLoading(true);
    let url = 'agent/companySummary';
    if (document.getElementById('searchType').value == 'type') {
      url = 'agent/companySummaryType';
      setSearch('type');
    } else {
      setSearch(null);
    }
    axiosInstance
      .get(url, {
        params: {
          startDate: startDate ? moment(startDate).format('DD-MM-YYYY') : null,
          endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : null,
          mtc: document.getElementById('matchDateCb').checked ? 'Y' : 'N',
          gameCategoryFilter: document.getElementById('gmStsFilter')
            ? document.getElementById('gmStsFilter').value
            : '',
        },
      })
      .then((res) => {
        setReports(res.data.content);
        calculateSum(res.data.content);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.status === 401) {
          history.push('/');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getToday = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(today.getDate() - minus);
      setStartDate(today);
      setEndDate(today);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getWeek = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let diff = today.getDay() - 1;
      if (diff < 0) diff += 7;
      today.setDate(today.getDate() - diff);
      if (minus > 0) {
        today.setTime(today.getTime() - minus * 7 * 24 * 60 * 60 * 1000);
        today2.setTime(today.getTime() + 6 * 24 * 60 * 60 * 1000);
      }
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const getMonth = (minus) => {
    axiosInstance.get('public/today').then((res) => {
      setStartDate(null);
      setEndDate(null);
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      if (minus > 0) {
        today.setMonth(today.getMonth() - minus);
        if (minus > 1) today2.setMonth(today2.getMonth() - minus + 1);
        today2.setDate(minus - 1);
      }
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
  };

  const generateRow = (wl, com) => {
    return (
      <>
        {wl != null ? (
          <td className={`text-right${wl < 0 ? ' text-danger' : ''}`}>
            <CurrencyFormat
              value={wl}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : null}
        {com != null ? (
          <td className={`text-right${com < 0 ? ' text-danger' : ''}`}>
            <CurrencyFormat
              value={com}
              decimalScale={2}
              displayType={'text'}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          </td>
        ) : null}
        <td className={`text-right${wl + com < 0 ? ' text-danger' : ''}`}>
          <CurrencyFormat
            value={wl + com}
            decimalScale={2}
            displayType={'text'}
            thousandSeparator={true}
            fixedDecimalScale={true}
          />
        </td>
      </>
    );
  };
  const backToParent = () => {
    setBets([]);
    setPlayerComm(0);
    setPlayerTurnover(0);
    setPlayerTurnover(0);
    setPlayerWinning(0);
    if (matchId != null) setMatchId(null);
    else setDetailType(null);
  };

  const moneyTd = (amt) => {
    return (
      <td className={`text-right${amt < 0 ? ' text-danger' : ''}`}>
        <CurrencyFormat
          value={amt}
          decimalScale={2}
          displayType={'text'}
          thousandSeparator={true}
          fixedDecimalScale={true}
        />
      </td>
    );
  };
  useEffect(() => {
    if (startDate != null && endDate != null && endDate >= startDate)
      handleChange(null);
  }, [startDate, endDate, detailType, matchId]);

  useEffect(() => {
    axiosInstance.get('public/today').then((res) => {
      let today = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      let today2 = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      today.setDate(1);
      setStartDate(today);
      setEndDate(today2);
      setMaxDate(
        new Date(
          res.data.substring(6),
          parseInt(res.data.substr(3, 2)) - 1,
          res.data.substring(0, 2)
        )
      );
      let minSearchDate = new Date(
        res.data.substring(6),
        parseInt(res.data.substr(3, 2)) - 1,
        res.data.substring(0, 2)
      );
      minSearchDate.setDate(minSearchDate.getDate() - 90);
      setSearchDate(minSearchDate);
    });
    handleChange(null);
  }, [history.location]);
  return (
    <>
      <Navbar User={User} />
      <div className="d-flex justify-content-between my-3 px-3 align-items-center ">
        <form action="" onSubmit={(e) => e.preventDefault()} id="form">
          Start Date:
          <DatePicker
            selected={startDate ? startDate : new Date()}
            minDate={searchDate}
            onChange={(date) => setStartDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            disabled={search === 'type' && detailType != null}
          />
          End Date:
          <DatePicker
            selected={endDate ? endDate : new Date()}
            onChange={(date) => setEndDate(date)}
            className="mx-2"
            dateFormat="d MMM yyyy"
            minDate={startDate}
            maxDate={maxDate}
          />
          <input
            type="checkbox"
            id="matchDateCb"
            onChange={() => {
              handleChange(null);
            }}
          />
          Match Date&nbsp;
          <select
            name="searchType"
            id="searchType"
            onChange={() => handleChange(null)}
          >
            <option value="report">Report</option>
            <option value="type">Type</option>
          </select>
          &nbsp;
          {search === 'type' ? (
            <>
              <select
                name="gmStsFilter"
                id="gmStsFilter"
                onChange={() => handleChange(null)}
              >
                <option value="ALL">All</option>
                <option value="RUNNING">Running</option>
                <option value="NONRUNNINGPARLAY">Non-Running</option>
                <option value="NONRUNNING">Today/Early Single</option>
                <option value="PARLAY">Parlay/All Up</option>
              </select>
              &nbsp;
            </>
          ) : null}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              handleChange(null);
            }}
          >
            Get Report
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(0);
            }}
          >
            Today
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getToday(1);
            }}
          >
            Yesterday
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(0);
            }}
          >
            This Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getWeek(1);
            }}
          >
            Last Week
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(0);
            }}
          >
            This Month
          </button>{' '}
          <button
            className="btn btn-sm btn-primary px-1 py-1"
            onClick={() => {
              getMonth(1);
            }}
          >
            Last Month
          </button>{' '}
          {loading ? (
            <>
              <br />
              <img
                src={require('../../img/loadingAnimation.gif')}
                height={13}
                width={208}
                alt=""
              />
            </>
          ) : null}
        </form>
      </div>
      {detailType != null ? (
        <>
          <div className="table-responsive-sm mx-2">
            <button
              className="btn btn-sm btn-primary px-1 py-1 my-2"
              onClick={() => {
                backToParent();
              }}
            >
              Return
            </button>
          </div>

          <div className="table-responsive-sm mx-2">
            {search !== 'type' || matchId != null ? (
              <BetList
                username={User.username}
                startDate={startDate}
                endDate={endDate}
                matchId={matchId}
                detailType={detailType}
                bets={bets}
                viewDetail={viewDetail}
                handleChange={handleChange}
                playerWager={playerWager}
                playerWinning={playerWinning}
                totalComm={playerComm}
                totalTurnover={playerTurnover}
                totalPage={totalPage}
                agent={'Y'}
                type={''}
              />
            ) : (
              <table className="table table-sm table-striped table-bordered">
                <thead className="text-center thead-dark">
                  <tr>
                    <th scope="col" colSpan={9}>
                      Company Summary Match Report [
                      {moment(endDate).format('DD/MMM/YYYY')}]
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Login ID / Type</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Wager</th>
                    <th scope="col">Turn Over</th>
                    <th scope="col">Valid Turn Over</th>
                    <th scope="col">Gross Comm</th>
                    <th scope="col">Win/Lose</th>
                    <th scope="col">Comm</th>
                    <th scope="col">Total W/L</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.map((info, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <a
                          className={`${classes.pointer} text-primary p-0`}
                          onClick={() => {
                            setMatchId(info.code);
                          }}
                        >
                          {info.name !== '' ? info.name : info.username}
                        </a>
                      </td>
                      <td className="text-center">{info.currency}</td>
                      <td className="text-center">{info.wager}</td>
                      <td
                        className={`text-right${
                          info.amount < 0 ? ' text-danger' : ''
                        }`}
                      >
                        <CurrencyFormat
                          value={info.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      {moneyTd(info.turnover)}
                      {moneyTd(info.grossCom)}
                      {moneyTd(info.companyWin)}
                      {moneyTd(info.companyCom)}
                      {moneyTd(info.companyWin + info.companyCom)}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-weight-bold">
                    <td className="text-center" colSpan="2">
                      Total
                    </td>
                    <td className={`text-center`}>{sumWager}</td>
                    {moneyTd(sumTurnover)}
                    {moneyTd(sumValid)}
                    {moneyTd(sumCommGross)}
                    {generateRow(sumWlCompany, sumCommCompany)}
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </>
      ) : (
        <div className="table-responsive-sm mx-2">
          <table className="table table-sm table-striped table-bordered">
            <thead className="text-center thead-dark">
              <tr>
                <th scope="col" colSpan={9}>
                  Company Summary Report [
                  {moment(startDate).format('DD/MMM/YYYY') +
                    ' - ' +
                    moment(endDate).format('DD/MMM/YYYY')}
                  ]
                </th>
              </tr>
              <tr>
                <th scope="col">Login ID / Type</th>
                <th scope="col">Currency</th>
                <th scope="col">Wager</th>
                <th scope="col">Turn Over</th>
                <th scope="col">Valid Turn Over</th>
                <th scope="col">Gross Comm</th>
                <th scope="col">Win/Lose</th>
                <th scope="col">Comm</th>
                <th scope="col">Total W/L</th>
              </tr>
            </thead>
            {reports.length > 0 ? (
              <>
                <tbody>
                  {reports.map((info, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        {info.code === 'PARLAY' ? (
                          info.name !== '' ? (
                            info.name
                          ) : (
                            info.username
                          )
                        ) : (
                          <a
                            className={`${classes.pointer} text-primary p-0`}
                            onClick={() => {
                              setDetailType(info.code);
                            }}
                          >
                            {info.name !== '' ? info.name : info.username}
                          </a>
                        )}
                      </td>
                      <td className="text-center">{info.currency}</td>
                      <td className="text-center">{info.wager}</td>
                      <td
                        className={`text-right${
                          info.amount < 0 ? ' text-danger' : ''
                        }`}
                      >
                        <CurrencyFormat
                          value={info.amount}
                          decimalScale={2}
                          displayType={'text'}
                          thousandSeparator={true}
                          fixedDecimalScale={true}
                        />
                      </td>
                      {moneyTd(info.turnover)}
                      {moneyTd(info.grossCom)}
                      {moneyTd(info.companyWin)}
                      {moneyTd(info.companyCom)}
                      {moneyTd(info.companyWin + info.companyCom)}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-weight-bold">
                    <td className="text-center" colSpan="2">
                      Total
                    </td>
                    <td className={`text-center`}>{sumWager}</td>
                    {moneyTd(sumTurnover)}
                    {moneyTd(sumValid)}
                    {moneyTd(sumCommGross)}
                    {generateRow(sumWlCompany, sumCommCompany)}
                  </tr>
                </tfoot>
              </>
            ) : null}
          </table>
        </div>
      )}
    </>
  );
};

export default Summary;
